import React, { useEffect, useState } from 'react'

import { storyblokEditable } from '@storyblok/react'
import * as d3 from 'd3'

function padTo2Digits(num) {
	return num.toString().padStart(2, '0')
}

export const formatDate = (date) => {
	return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') + ' ' + [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
}

export const Nasdaq = ({ blok }) => {
	const { disclaimer_text } = blok
	const [nasdaq, setNasdaq] = useState()

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=NASDAQ_SUMMARY`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === 'NASDAQ_SUMMARY')
				const section = widget.sections.find((section) => section.key === 'SUMMARY')
				const { graph } = section

				const series = graph.series.find((series) => series.key === null)

				const isYNumber = series.scaleY === 'CURRENCY'
				const data = series.dataX.map((x, i) => {
					return { x: d3.timeParse('%Y/%m')(x), y: isYNumber ? +series.dataY[i] : series.dataY[i] }
				})

				const margin = { top: 0, right: 0, bottom: 0, left: 0 }
				const yMinValue = d3.min(data, (d) => d.y)
				const yMaxValue = d3.max(data, (d) => d.y)

				const width = 95
				const height = 32

				d3.selectAll('#nasdaq-graph > svg').remove()

				const svg = d3
					.select('#nasdaq-graph')
					.append('svg')
					.attr('width', width + margin.left + margin.right)
					.attr('height', height + margin.top + margin.bottom)
					.append('g')
					.attr('transform', `translate(${margin.left},${margin.top})`)

				const xScale = d3
					.scalePoint()
					.range([0, width])
					.domain(data.map((d) => d.x)) //d3.scaleLinear().domain([xMinValue, xMaxValue]).range([0, width])
				const yScale = d3.scaleLinear().range([height, 0]).domain([yMinValue, yMaxValue])
				const line = d3
					.line()
					.x((d) => xScale(d.x))
					.y((d) => yScale(d.y))

				svg.append('path').datum(data).attr('fill', 'none').attr('stroke', '#fff').attr('stroke-width', series.lineSize).attr('class', 'line').attr('d', line)

				const nasdaq = {}

				section.meta.forEach((o) => {
					nasdaq[o.key] = {
						value: o.value,
						suffix: o.suffix,
						prefix: o.prefix,
					}
				})

				setNasdaq(nasdaq)
			})
		})
	}, [])

	return (
		<>
			<div className='nasdaq-container' {...storyblokEditable(blok)}>
				<div className='nasdaq'>
					<span>{nasdaq?.CloseSek.value}</span> <span className='font-light'>{nasdaq?.CloseSek.suffix}</span>
					<div className='nasdaq-details'>
						<div>NASDAQ STOCKHOLM: CS</div>
						<div className='nasdaq-details-small'>LAST UPDATED {formatDate(new Date(nasdaq?.Date.value))} UTC</div>
					</div>
					<div className='nasdaq-stats'>
						<div className='nasdaq-stat-item'>
							<span>CHANGE</span>
							<span>
								{nasdaq?.Change.value} ({nasdaq?.Change.value < 0 ? '-' : '+'}
								{nasdaq?.ChangePercentage.value.replace('-', '')}%)
							</span>
						</div>
						<div className='nasdaq-stat-item'>
							<span>MARKET CAP</span>
							<span>
								{nasdaq?.MarketCapSek.prefix ? nasdaq?.MarketCapSek.prefix + ' ' : ''}
								{nasdaq?.MarketCapSek.value}
								{nasdaq?.MarketCapSek.suffix ? ' ' + nasdaq?.MarketCapSek.suffix : ''}
							</span>
						</div>
						{/* <img src={nasdaqGraph} alt="graph" /> */}
						<div id='nasdaq-graph' />
					</div>
				</div>
				<p className='nasdaq-disclaimer'>{disclaimer_text}</p>
			</div>
		</>
	)
}

export default Nasdaq
