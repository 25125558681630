import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import { Pattern } from '../ImageLink'

export const Headline = ({ blok, theme, slug, language }) => {
	const { anchor_key, breadcrumb, layout, call_to_action, title, header, icon } = blok
	const { filename } = icon || {}

	let patternTrUrl = 'pattern-hero-black-top.svg'
	let patternTlUrl

	if (theme === 'green' && slug === 'home') {
		patternTrUrl = 'pattern-hero-home-top.svg'
		patternTlUrl = 'pattern-hero-home-top-l.svg'
	} else if (theme) {
		patternTrUrl = `pattern-hero-${theme}-top.svg`
	}

	return (
		<div {...storyblokEditable(blok)} className='headline-container' id={anchor_key}>
			{patternTrUrl && <Pattern img={patternTrUrl} position='top-right' />}
			{patternTlUrl && <Pattern img={patternTlUrl} position='top-left' mobile={true} />}
			<div className='container'>
				<div className='row'>
					<div className='col-lg-12 col-12'>
						<div className='headline'>
							<div className='breadcrumb'>
								{breadcrumb && breadcrumb.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
							</div>
							{(!layout || layout === 'layout_1') && (
								<>
									<div className='row'>
										<div className='col-12'>
											{!filename && <h1>{title}</h1>}
											{filename && (
												<h1 className='headline-icon'>
													<img src={filename} alt='' />
													<span>{title}</span>
												</h1>
											)}
											{header && <span className='text-lightest sub-header'>{header}</span>}

											{call_to_action && call_to_action.length > 0 && (
												<span style={{ display: 'inline-flex', gap: 30, marginTop: 30 }}>
													{call_to_action.map((blok) => (
														<StoryblokComponent blok={blok} key={blok._uid} language={language} />
													))}
												</span>
											)}
										</div>
									</div>
								</>
							)}

							{layout === 'layout_2' && (
								<>
									<div className='row'>
										<div className='col-lg-10 col-12'>
											{!filename && (
												<h1 style={{ display: 'flex', flexDirection: 'column' }}>
													{title}
													<span className='text-lightest h2'>{header}</span>
												</h1>
											)}

											{filename && (
												<div className='headline-icon'>
													<img src={filename} alt='' />
													<h1 style={{ display: 'flex', flexDirection: 'column' }}>
														{title}
														<span className='text-lightest h2'>{header}</span>
													</h1>
												</div>
											)}
										</div>
										<div className='col-12 headline-actions'>
											{call_to_action.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} language={language} />
											))}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Headline
