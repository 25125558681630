import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const ProductAlert = ({ blok }) => {
	const { anchor_key, background = 'transparent', buttons } = blok

	return (
		<div {...storyblokEditable(blok)}  id={anchor_key} className={`product-alert-container ${background.toLowerCase()}`}>
			<div className='container'>
				<>
					<div className='col-lg-7 offset-lg-5'>
						<div className='product-alert'>
							<h5>{blok.title}</h5>

							<div className='product-alert-detail'>
								<p>{blok.description}</p>


								{buttons && buttons.length > 0 && (
									<div className='links'>
										{buttons.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	)
}

export default ProductAlert
