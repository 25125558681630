import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { RichText } from "../RichText";
import languages from "../../constants/languages.json";
import "./ETPRichtextWithVideo.scss";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

function RichtextWithVideo({ blok }) {
    const { description, video_link } = blok;

    const hideVideo =
        !video_link?.cached_url ||
        !!(
            video_link.cached_url.split("/").filter((x) => x).length === 1 &&
            languages.find(
                (o) => o[video_link.cached_url.split("/").filter((x) => x)[0]]
            )
        );

    return (
        <div
            className={`richtext-with-video ${hideVideo ? "no-video" : ""}`}
            {...storyblokEditable(blok)}
        >
            <div>
                {description && (
                    <RichText
                        blok={description}
                        key={description._uid}
                        inline={true}
                        content={true}
                        stickyHeaders={true}
                    />
                )}
            </div>
            {hideVideo || (
                <div className="richtext-with-video__right">
                    <div className="video-container">
                        <LiteYouTubeEmbed
                            id={video_link.cached_url.split('/').pop().replace(/\?.*$/i, '')}
                            poster="maxresdefault"
                            title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                        {/* <iframe
                            src={video_link.cached_url}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ border: "none" }}
                        /> */}
                    </div>
                </div>
            )}
        </div>
    );
}

export default RichtextWithVideo;
