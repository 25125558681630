import { StoryblokComponent } from "@storyblok/react";

function ETPProductOffer({blok}) {
  const {logo, text, button} = blok;

  return (
    <div className={'widget white'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-xl-3 d-flex align-items-center'}>
            <img src={logo.filename} alt={logo.alt}/>
          </div>
          <div className={'col-xl-5 col-md-6 d-flex align-items-center'}>
            <h2 className="h4">{text}</h2>
          </div>
          <div className={'col-xl-4 col-md-6 d-flex align-items-center justify-content-md-end'}>
            {button?.map(btn => <StoryblokComponent blok={btn} id={btn._uid} key={btn._uid}/>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ETPProductOffer;