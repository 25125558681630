import { useQuery } from "@apollo/client";
import { StoryblokComponent } from "@storyblok/react";
import { Link } from "react-router-dom";
import { articlesQuery } from "../../queries";
import { etpLink, formatDate } from "../../utils";

function ETPArticles({ blok, language }) {
  const { articles, button, text } = blok;

  const { data } = useQuery(articlesQuery({ perPage: articles ?? 4 }), {
    variables: { language: language !== "en" ? `${language}/*` : undefined },
  });

  const articleItems = data?.ArticleItems?.items || [];

  return (
    <div className={"widget white"}>
      <div className={"container"}>
        {button.length ? (
          <>
            <div className={"row mb-5"}>
              <div className={"col-lg-7"}>
                <h2>{text}</h2>
              </div>
              <div
                className={
                  "col-lg-5 d-flex justify-content-end align-items-center"
                }
              >
                {button.map((btn) => (
                  <StoryblokComponent blok={btn} id={btn._uid} key={btn._uid} />
                ))}
              </div>
            </div>
            <div className={"row"}>
              {articleItems.map((article) => (
                <Article article={article} />
              ))}
            </div>
          </>
        ) : (
          <div className={"row"}>
            <div className={"col-xl-4"}>
              <h2 className="h3">{text}</h2>
            </div>
            <div className={"col-xl-4"}>
              {articleItems?.[0] && <Article article={articleItems[0]} />}
            </div>
            <div className={"col-xl-4"}>
              {articleItems?.[1] && <Article article={articleItems[1]} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ETPArticles;

export function Article({ article, isFeatured }) {
  const primaryTopicTitle = article.content?.primary_topic?.content?.title;
  const additionalTopicTitles = article.content?.additional_topics?.map(
    ({ content }) => content?.title
  );
  console.log(article);
  return (
    <Link
      to={etpLink(article.full_slug)}
      className={`etp-article ${
        isFeatured ? "etp-article--featured col-lg-6" : "col-lg-6 col-xl-3"
      }`}
    >
      <img
        // TODO: remove hardcoded url
        src={
          (article.content.image?.filename ||
          "https://a.storyblok.com/f/155294/1920x1080/8aca6e10dd/announcements.png") + '/m/700x0'
        }
        loading="lazy"
        alt={article.content.title}
        className="etp-article-img"
      />
      <div className="etp-article-date-wrapper">
        <div className="etp-article-date">
          <span className="etp-article-date-text">
            {formatDate(article.first_published_at)}
          </span>
          <div className="etp-article-date-line" />
        </div>
      </div>
      <h5 className="etp-article-title">{article.content.title}</h5>
      <div className="etp-article-tags">
        {!!primaryTopicTitle && (
          <div className="etp-article-tag">{primaryTopicTitle}</div>
        )}
        {additionalTopicTitles.map((topic) => (
          <div className="etp-article-tag">{topic}</div>
        ))}
      </div>
    </Link>
  );
}
