import React, { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { storyblokEditable } from '@storyblok/react'
import { Card } from '.'
import ForwardChevron from '../../icons/ForwardChevron.svg'

import { useQuery, gql } from '@apollo/client'
import { useEffect } from 'react'
import orderby from 'lodash.orderby'
import logAction from '../../actions'
// import {Pattern} from '../ImageLink'



export const OfferingSummary = ({ blok, language, slug }) => {
    const location = useLocation()
    const query = gql`
	{
		OfferingItems${language !== 'en' && !!language ? `(starts_with: "${language}/*")` : ''} {
			items {
				content {
					cache_key
					component
					currency
					description
					long_title
					link_title
					link_long_title
					statistic_title
					statistic_value
					title
                    icon {
                        filename
                    }
					light_icon {
						filename
					}
					dark_icon {
						filename
					}
					link {
						cachedUrl
					}
					sort_order
				}
				uuid
			}
		}
	}
`

	const { /*loading, error,*/ data } = useQuery(query)

	const { OfferingItems: OfferingItemsData } = data || {}
	const { anchor_key, title, background, layout, description } = blok

	const [offeringAmounts, setOfferingAmounts] = useState([])

	const offeringItems = useMemo(() => {
		if (OfferingItemsData) {
			return orderby(
				OfferingItemsData.items.map((o) => {
					return {
						uuid: o.uuid,
						...o.content,
					}
				}),
				['sort_order'],
				['asc']
			)
		}

		return []
	}, [OfferingItemsData])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ETP_AUM,INDEX_AUM,CAPITAL_MARKETS`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const etpMeta = value.find((v) => v.key === 'ETP_AUM')?.sections.find((v) => v.key === 'ETP_AUM')?.meta
				const indexMeta = value.find((v) => v.key === 'INDEX_AUM')?.sections.find((v) => v.key === 'INDEX_AUM')?.meta
				const capitalMeta = value.find((v) => v.key === 'CAPITAL_MARKETS')?.sections.find((v) => v.key === 'CAPITAL_MARKETS')?.meta

				const etp = etpMeta?.find((o) => o.key === 'MarketCap')
				const index = indexMeta?.find((o) => o.key === 'MarketCap')
				const capital = capitalMeta?.find((o) => o.key === 'notionalUsd')
				setOfferingAmounts([
					{ key: 'ETP_AUM', value: etp?.value, suffix: etp?.suffix },
					{ key: 'INDEX_AUM', value: index?.value, suffix: index?.suffix },
					{ key: 'CAPITAL_MARKETS', value: capital?.value, suffix: capital?.suffix },
				])
			})
		})
	}, [])

	return (
		<div className={layout === 'layout_4' ? background : 'white'} id={anchor_key}>
			<div {...storyblokEditable(blok)} className={` offer-summary ${layout} ${layout !== 'layout_4' ? 'container' : ''}`}>
				<div className='row'>
					{/* investor relations */}
					{layout === 'layout_2' && (
						<>
							<div className='col-lg-1 col-md-12'></div>
							<div className='col-lg-8 col-md-12 paragraph' style={{ paddingBottom: 40 }}>
								<h2>{title}</h2>
								<p>{description}</p>
							</div>
						</>
					)}

					{/* home */}
					{layout === 'layout_1' && (
						<>
							<div className='col-12 col-lg-11 offset-lg-1'>
								<h2 className='title'>{title}</h2>
							</div>
						</>
					)}

					{/* investor relations & home */}
					{(layout === 'layout_1' || layout === 'layout_2') && (
						<>
							<div className='col-12 col-lg-11 offset-lg-1'>
								<div className='offering-items'>
									{offeringItems.map((o) => {
										const amount = offeringAmounts.find((oa) => oa.key === o.cache_key)
										return (
											<Card
												key={o.uuid}
												card={{
													title: o.title,
													header: o.statistic_title,
													amount: o.statistic_value ? o.statistic_value : amount?.value,
													currency: amount?.suffix || o.currency,
													external: o.link_title || o.link_long_title,
													link_title: o.link_title,
													link: o.link.cachedUrl,
													icon: o.link_title || o.link_long_title ? o.light_icon.filename : undefined,
                          iconHover : o.dark_icon.filename
												}}
											/>
										)
									})}
								</div>
							</div>
						</>
					)}

					{/* products and services */}
					{layout === 'layout_3' && (
						<>
							<div className='col-12'>
								<div className='offering-items'>
									{offeringItems.map((o) => {
										const amount = offeringAmounts.find((oa) => oa.key === o.cache_key)
										return (
											<Card
												key={o.uuid}
												card={{
													title: o.long_title,
													description: o.description,
													header: o.statistic_title,
													amount: o.statistic_value ? o.statistic_value : amount?.value,
													currency: amount?.suffix || o.currency,
													icon: o.light_icon.filename,
													link: o.link.cachedUrl,
													external: o.link_title || o.link_long_title,
													link_long_title: o.link_long_title,
												}}
											/>
										)
									})}
								</div>
							</div>
						</>
					)}

					{/* our story */}
					{layout === 'layout_4' && (
						<div className='col-12'>
							<h5>{title}</h5>

							<div className='offering-items'>
								{offeringItems.map((o) => (
									<React.Fragment key={o.uuid}>
										{!(o.link_title || o.link_long_title) && (
											<Link className='offering-item' to={'/' + o.link.cachedUrl} onClick={() => logAction('navigation', { from: location.pathname, to: '/' + o.link.cachedUrl })}>
												<img width={105} src={o.dark_icon.filename} alt={o.long_title} className='offering-icon' />
												<h4 className='article offering-title'>{o.long_title}</h4>
												<img className='offering-forward' src={ForwardChevron} alt='forward' />
											</Link>
										)}

										{(o.link_title || o.link_long_title) && (
											<a className='offering-item' href={o.link.cachedUrl} target="_blank" rel="noreferrer">
												<img width={105} src={o.icon.filename} alt={o.long_title} className='offering-icon' />
												<h4 className='article offering-title'>{o.long_title}</h4>
												<img className='offering-forward' src={ForwardChevron} alt='forward' />
											</a>
										)}
									</React.Fragment>
								))}
							</div>
						</div>
					)}
				</div>
				{slug === "home" && (
					<div className='row'> 
						<div className='col-12 col-lg-11 offset-lg-1'>
							<div className='video-home'>
								<h2>Our vision</h2>
								<iframe
									width="100%"
									src="https://www.youtube.com/embed/cGGbJVCdOg4"
									title="YouTube video player"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									style={{border: "none"}}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default OfferingSummary
