import React, { useEffect, useMemo, useState } from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import KnowledgeByte from './KnowledgeByte'
import { useQuery, gql } from '@apollo/client'
import { useLocation, Link } from 'react-router-dom'
import useDebounce from '../../hooks/useDebounce'
import { extractRichText } from '../RichText'
import { GlossaryHighlighter } from './Highlighter'
import BackChevron from '../../icons/BackChevron.svg'
import orderBy from 'lodash.orderby'
import { Scrollbar } from '../.'
import logAction from '../../actions'
import { getCookie } from '../../utils';


export const KnowledgeBytes = ({ blok, language }) => {
	const { anchor_key, title, header, background, description, topics, item_limit, call_to_action, layout } = blok
	const location = useLocation()

    const query = gql`
	{
		ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_slugs: "knowledge-bytes/*", sort_by: "sort_by_date:desc", filter_query: { is_question_card: { like: "true" } }) {
			items {
				content {
					key
					title
					is_question_card
					additional_topics {
						content
					}
					article_type {
						content
					}
					media_type
				}
				uuid
				full_slug
			}
			total
		}
		QueryItems: ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: 100, by_slugs: "knowledge-bytes/*", sort_by: "sort_by_date:desc") {
			items {
				content {
					key
					title
					body
					is_question_card
					additional_topics {
						content
					}
					media_type
				}
				uuid
				full_slug
			}
			total
		}
	}
`
    const knowledgeQuery = gql`
	{
		ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: ${item_limit}, by_slugs: "knowledge-bytes/*", sort_by: "sort_by_date:desc") {
			items {
				content {
					key
					title
					is_question_card
					additional_topics {
						content
					}
					article_type {
						content
					}
					media_type
				}
				uuid
				full_slug
			}
			total
		}
	}
`
	const { /*loading, error,*/ data } = useQuery(layout !== 'layout_2' ? query : knowledgeQuery)

	const [search, setSearch] = useState('')
	const [searching, setSearching] = useState(false)
	const searchDebounce = useDebounce(search, 250)
	const [viewedBytes, setViewedBytes] = useState([])
	const [popularQuestions, setPopularQuestions] = useState([])

	const { ArticleItems, QueryItems } = data || {}

	const knowledgeBytes = useMemo(() => {
		if (ArticleItems) {
			return orderBy(
				ArticleItems.items
					.flatMap((item) => {
						// const itemTopics = [item.content.primary_topic.uuid, ...(item.content.additional_topics?.map((o) => o.uuid) || [])].filter((o) => o)
						const itemTopicTitles = [item.content.primary_topic?.content?.title, ...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

						let tags = ''
						if (itemTopicTitles.length === 1) tags = itemTopicTitles[0]
						else if (itemTopicTitles.length > 1) tags = `${itemTopicTitles[0]} + ${itemTopicTitles.length - 1} More`

						return {
							uuid: item.uuid,
							full_slug: item.full_slug,
							...item.content,
							tags: tags === '' ? [] : [tags],
							count: layout === 'layout_2' ? 1 : popularQuestions.find((o) => o.key === item.content.key)?.count,
						}
					})
					.filter((o) => o.count),
				['count'],
				['desc']
			)
		}

		return []
	}, [ArticleItems, popularQuestions, layout])

	const queryItems = useMemo(() => {
		if (QueryItems) {
			return QueryItems.items.flatMap((item) => {
				const itemTopicTitles = item.content.additional_topics?.map((o) => o.content.title) || []

				let tags = ''
				if (itemTopicTitles.length === 1) tags = itemTopicTitles[0]
				else if (itemTopicTitles.length > 1) tags = `${itemTopicTitles[0]} + ${itemTopicTitles.length - 1} More`

				return {
					uuid: item.uuid,
					full_slug: item.full_slug,
					...item.content,
					tags: tags === '' ? [] : [tags],
					body: extractRichText({ blok: item.content.body }),
				}
			})
		}

		return []
	}, [QueryItems])

	const searchItems = useMemo(() => {
		const searchTerms = searchDebounce.toLowerCase()
		return queryItems.filter((o) => o.title.toLowerCase().indexOf(searchTerms) >= 0 || o.body.toLowerCase().indexOf(searchTerms) >= 0)
	}, [searchDebounce, queryItems])

	const topicLocation = useMemo(() => {
		if (location.pathname.split('').reverse()[0] !== '/') {
			return `${location.pathname}/topic?id=`
		}

		return `${location.pathname}topic?id=`
	}, [location.pathname])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/KnowledgeBytesViewStats/raw?ApiKey=${process.env.REACT_APP_API_KEY}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				setPopularQuestions(value.stats)
			})
		})

		fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}&key=${getCookie('visitor_id882933') || '1'}&code=VIEWED-KB`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				setViewedBytes(value)
			})
		})

		//     window.addEventListener('click', (ev) => {
		//         if (ev.target.className.indexOf('search') < 0) {
		//             setSearch('')
		//         }
		//     })
	}, [])

	return (
		<div {...storyblokEditable(blok)} className={`knowledge-bytes ${layout === 'layout_2' ? background.toLowerCase() : ''}`} id={anchor_key}>
			{layout !== 'layout_2' && (
				<>
					{!searching && (
						<div className='container'>
							<div className='row'>
								<div className='col-12 col-xl-9'>
									<div className='headline'>
										<h1>{title}</h1>
										<p className='body-intro'>{description}</p>
									</div>
								</div>
							</div>
						</div>
					)}

					{searching && (
						<div className='headline-container'>
							<div className='container'>
								<div className='row'>
									<div className='col-12'>
										<div className='headline' style={{ padding: 0, paddingBottom: 30 }}>
											<Link
												className='breadcrumb'
												to='/knowledge-bytes/'
												onClick={() => {
                                                    logAction('navigation', { from: location.pathname, to: '/knowledge-bytes/' })
													setSearching(false)
													setSearch('')
												}}
												style={{ marginBottom: 0 }}
											>
												<img src={BackChevron} alt='back' />
												<span>All questions and topics</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className={'knowledge-bytes-search' + (search && !searching ? ' open' : '')}>
						<div className='container search'>
							<div className='search-icon'>
								<img src='/images/LargeSearch.svg' alt={'knowledge bytes search'} />
							</div>
							<div className='search-box'>
								<h3>
									<input value={search} onChange={(ev) => setSearch(ev.currentTarget.value)} className='search-input' placeholder='What would you like to know?' />
								</h3>
							</div>
							{search && !searching && (
								<button onClick={() => setSearching(true)} className='button search-button'>
									Search
								</button>
							)}
						</div>
						<div className='search-dropdown'>
							{searchItems.map((o) => (
								<div key={o.uuid} className={'search-item ' + (o.is_question_card ? 'question' : 'answer')}>
									<div className='container'>
										<div className='row'>
											<div className='col'>
												<Link to={'/' + o.full_slug} className='search-link'>
													<GlossaryHighlighter glossaryTerms={search.split(' ')} textToHighlight={o.title} />
													<span className='search-link-button'>
														<span>View {o.is_question_card ? 'Questions' : 'Detail'} Card</span>
														<img src={o.is_question_card ? '/images/ChevronRightSmall.svg' : `/images/detail-${o.media_type}.svg`} alt='' />
													</span>
												</Link>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					{!searching && (
						<div className={background.toLowerCase() + (search ? ' search-open' : '')} style={{ contain: 'paint' }}>
							<div className='container'>
								<div className='knowledge-bytes-container'>
									<div className='bytes-container'>
										<h3>Trending Questions</h3>
										<div className='bytes'>
											{knowledgeBytes.map((byte, i) => (
												<KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex((o) => o === byte.key) >= 0} />
											))}
										</div>
									</div>

									<div className='topics-container'>
										<h3>Topics</h3>
										<div className='topics'>
											{topics &&
												topics.map((topic) => (
													<Link key={topic.uuid} className='topic' to={topicLocation + topic.uuid} alt={''} onClick={() => logAction('navigation', { from: location.pathname, to: topicLocation + topic.uuid })}>
														<img src={topic.content?.icon?.filename} alt={topic.content.title} />
														<h4 className='article'>{topic.content.title}</h4>
														<img src='/images/ChevronRightSmallDark.svg' alt={'forward'} />
													</Link>
												))}
										</div>
									</div>
								</div>

								{/* <div>
							{call_to_action.map((blok) => (
								<StoryblokComponent blok={blok} key={blok._uid} />
							))}
						</div> */}
								{/* </div> */}
							</div>
						</div>
					)}

					{searching && (
						<div className='white widget'>
							<div className='container'>
								<div className='row'>
									<div className='col'>
										<h1 style={{ marginBottom: 40 }}>
											{searchItems.length} result{searchItems.length === 1 ? '' : 's'} for '{search}'
										</h1>

										{searchItems.length === 0 && <h3 className='content'>Why not view some of these trending questions and myths instead.</h3>}

										<div className='knowledge-bytes topics'>
											<div className='bytes'>
												{searchItems.map((byte, i) => (
													<KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex((o) => o === byte.key) >= 0} />
												))}

												{searchItems.length === 0 && knowledgeBytes.map((byte, i) => <KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex((o) => o === byte.key) >= 0} />)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}

			{layout === 'layout_2' && (
				<>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-4 col-12'>
								<h5 className='label'>{title}</h5>
							</div>
							<div className='col-lg-8 col-12'>
								<p className='pull-quote'>{header}</p>
								<p className='body-intro'>{description}</p>
							</div>
							<div className='col-12'>
								<Scrollbar>
									<div className='knowledge-bytes topics'>
										<div className='bytes horizontal'>
											{knowledgeBytes.map((byte) => (
												<KnowledgeByte key={byte.uuid} byte={byte} viewed={viewedBytes.findIndex((o) => o === byte.key) >= 0} />
											))}
										</div>
									</div>
								</Scrollbar>
							</div>

							{call_to_action && call_to_action.length > 0 && (
								<div className='col-12' style={{ paddingTop: 50 }}>
									{call_to_action.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} />
									))}
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
		// </div>
	)
}

export default KnowledgeBytes
