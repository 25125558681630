import { StoryblokComponent } from "@storyblok/react";

function ETPChooseUs({ blok }) {
  const { title, button, featured_statements } = blok;

  return (
    <div className={'widget gray'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-xxl-4'}>
            <h2 className={'mb-5'}>{title}</h2>
            <div className={'mb-5'}>
              {button?.map(btn => <StoryblokComponent blok={btn} id={btn._uid} key={btn._uid}/>)}
            </div>
          </div>
          <div className={'col-xxl-8'}>
            {featured_statements?.map(fs => <StoryblokComponent blok={fs} id={fs._uid} key={fs._uid}/>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ETPChooseUs;