import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { useInView } from 'react-intersection-observer'
import {Pattern} from '../ImageLink'

export const Quote = ({ blok, slug, theme }) => {
	const { anchor_key, background = 'transparent', layout, call_to_action, extra_content, bottom_links } = blok

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    })

	let patternBlUrl = 'pattern-hero-black-bottom.svg';
	let patternBrUrl;

	if(theme === "green" && slug === "home") {
		patternBlUrl = 'pattern-hero-home-bottom.svg'
		patternBrUrl = 'pattern-hero-home-bottom-r.svg'
	} else if (theme) {
		patternBlUrl = `pattern-hero-${theme}-bottom.svg`
	}

	return (
		<div {...storyblokEditable(blok)} id={anchor_key} className={`quote-container ${background.toLowerCase()}`}>
			<div className='container'>
				<div className={`row${inView ? ' animate-ease-up' : ''}`} ref={ref}>
					{layout === 'layout_1' && (
						<>
							<div className='col-lg-6'></div>
							<div className='col-lg-6'>
								<div className='quote'>
									<p>{blok.text}</p>

									{extra_content && extra_content.length > 0 && (
										<div className='quote-extra'>
											{extra_content.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</div>
									)}

									{bottom_links && bottom_links.length > 0 && (
										<div className='links'>
											{bottom_links.map((blok) => (
												<StoryblokComponent blok={blok} key={blok._uid} />
											))}
										</div>
									)}
								</div>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
						</>
					)}

					{layout === 'layout_2' && (
						<>
							<div className='col-lg-2'></div>
							<div className='col-lg-6'>
								<p className='quote'>{blok.text}</p>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-4'></div>
						</>
					)}

					{layout === 'layout_3' && (
						<>
							<div className='col-lg-5'>
								<p className='quote'>{blok.text}</p>
								{call_to_action.length > 0 && (
									<div className='quote-actions'>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-2'></div>
							<div className='col-lg-5 extra-content'>{extra_content && extra_content.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}</div>
						</>
					)}

					{layout === 'layout_4' && (
						<>
							<div className='col-lg-3'></div>
							<div className='col-lg-6'>
								<p className='quote' style={{ margin: 0 }}>
									{blok.text}
								</p>
								{bottom_links && bottom_links.length > 0 && (
									<div className='links'>
										{bottom_links.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
							<div className='col-lg-3' style={{ textAlign: 'right' }}>
								{call_to_action.length > 0 && (
									<div>
										{call_to_action.map((blok) => (
											<StoryblokComponent blok={blok} key={blok._uid} />
										))}
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
			{/* <div className='container pattern-wrapper'><img src='/images/pattern-hero-blue-bottom.svg' className='pattern pattern-bottom-left' alt='' /></div> */}
			{patternBlUrl && <Pattern img={patternBlUrl} position="bottom-left" mobile={true} />}
			{patternBrUrl && <Pattern img={patternBrUrl} position="bottom-right" />}
		</div>
	)
}

export default Quote
