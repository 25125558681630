import { gql, useQuery } from "@apollo/client";
import { StoryblokComponent } from "@storyblok/react";
import { useLocation } from "react-router-dom";
import ETPCompanySquare from "../ETPCompanySquare";
import { useEffect, useState, useRef } from "react";
import { fetchBrokerMapping } from "../../queries";
import { INVESTOR_TYPES, cookieNames, getCookie } from "../../utils";
import { BasicLogoList } from "../LogoList";
import ETPPaginationControls from "../ETPPaginationControls";

const perPage = 9;

function ETPBrokers({ blok }) {
  const [page, setPage] = useState(0);
  const { title, text, button, listed_products_text } = blok;
  const [brokers, setBrokers] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const location = useLocation();
  const [productsUnavailable, setProductsUnavailable] = useState(false);

  useEffect(() => {
    const country = getCookie(cookieNames.LOCATION);
    fetchBrokerMapping({ country }).then((data) => {
      setBrokers([...new Set(data.map((d) => d.broker))]);
    });
  }, []);

  const { data } = useQuery(brokerQuery);
  const { BrokerItems, ExchangeItems, LegalnoticeItems } = data || {};
  const exchanges = ExchangeItems?.items;
  const productContainer = useRef(null);

  const changePage = (newPage) => {
    productContainer.current.scrollIntoView({behavior: 'smooth' });

    setTimeout(() => {
      setPage(newPage);
    }, 600);
  };

  useEffect(() => {
    if (BrokerItems?.items?.length && brokers?.length) {
      let list = [];
      brokers.forEach((broker) => {
        let item = BrokerItems.items.find(
          (item) => item.content.key === broker
        );
        if (item) {
          list.push({ broker, item });
        }
      });
      setBrokerList(list);
    }
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    if (legalNotices && investorType) {
      let countryName = getCookie(cookieNames.LOCATION);
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [brokers, BrokerItems, LegalnoticeItems?.items]);

  const isNotInstitutional =
    getCookie(cookieNames.INVESTOR_TYPE) !== INVESTOR_TYPES.INSTITUTIONAL;
  let showExchanges = true;

  if (location.pathname.includes("/physical/")) {
    showExchanges = false;
  }
  console.log(productsUnavailable, brokerList);
  return (
    <>
      {(showExchanges || isNotInstitutional) && (
        <div className={"widget widget--slim white etp-brokers"} id={"brokers"}>
          <div className={"container"} ref={productContainer}>
            {isNotInstitutional && (
              <div className={"row"}>
                <div className={"col-lg-6"}>
                  <div className={"pe-5"}>
                    <h2>{title}</h2>
                    <p className={"mb-5"}>{text}</p>
                    {!productsUnavailable && button?.map((btn) => (
                      <StoryblokComponent
                        blok={btn}
                        id={btn._uid}
                        key={btn._uid}
                      />
                    ))}
                  </div>
                </div>
                <div className={"col-lg-6 ept-logo-squares"}>
                  {!productsUnavailable &&
                    brokerList
                      ?.slice(page * perPage, page * perPage + perPage)
                      .map((item) => {
                        return (
                          <ETPCompanySquare
                            key={item.broker}
                            title={item.broker}
                            linkUrl={item.item.content.link.cachedUrl}
                            logoUrl={item.item.content.logo.filename}
                          />
                        );
                      })}
                  {(productsUnavailable || brokerList?.length === 0) && (
                    <div className="etp-brokers-empty">
                      Please note that CoinShares' ETPs are unavailable in your
                      selected country.
                    </div>
                  )}
                </div>
                <div className={"etp-broker-search-pagination"}>
                  <ETPPaginationControls
                    page={page}
                    pages={Math.ceil(brokerList?.length / perPage)}
                    onClick={setPage}
                  />
                </div>
              </div>
            )}

            <div
              className={
                isNotInstitutional ? "row etp-exchanges-list" : "etp-logo-list"
              }
            >
              {exchanges && (
                <BasicLogoList
                  text={listed_products_text}
                  logos={exchanges.map(({ content }) => ({
                    src: content.logo.filename,
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ETPBrokers;

const brokerQuery = gql`
  query {
    BrokerItems(per_page: 100, page: 1) {
      items {
        uuid
        content {
          key
          title
          logo {
            filename
          }
          link {
            cachedUrl
          }
        }
      }
    }

    ExchangeItems {
      items {
        uuid
        content {
          title
          logo {
            filename
          }
        }
      }
    }
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;
