import { useQuery } from '@apollo/client';
import { knowledgeCategoryQuery } from '../../queries';
import { Dropdown } from '@fluentui/react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { etpLink } from '../../utils';
import { etpNewSlug } from '../../constants';

function ETPArticleCategorySelector({ blok }) {
  const { title } = blok;
  const { data } = useQuery(knowledgeCategoryQuery);
  const location = useLocation();
  const navigate = useNavigate();

  const categoryOptions = data?.KnowledgecategoryItems?.items?.map(({ content, full_slug }) => ({
    key: full_slug,
    text: content.name
  }));

  const currentPageOption = categoryOptions?.find(({ key }) => location.pathname.includes(key.replace(etpNewSlug, '')));

  function handleChange(_, { key }) {
    navigate(etpLink(key));
  }

  return (
    <div className='etp-article-category-selector'>
      <Link to={blok?.redirect_url?.cached_url} className='etp-article-category-selector-title'> {title} </Link>
      <Dropdown
        options={categoryOptions}
        selectedKey={currentPageOption?.key}
        onChange={handleChange}
        placeholder='-'
      />
    </div>
  );
}

export default ETPArticleCategorySelector;