import { StoryblokComponent } from "@storyblok/react";

function ETPBitcoinEffect({ blok }) {
  const { title, text, sub_text, button, disclaimer } = blok;

  return (
    <div className={'widget white'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-6 etp-bitcoin-effect-text'}>
            <h2>{title}</h2>
            <p>{text}</p>
            {sub_text.split('\n').map(st => <p className={'etp-bitcoin-effect-subtext'}>{st}</p>)}
          </div>
          <div className={'col-lg-6 d-flex justify-content-lg-end align-items-lg-center'}>
            {button?.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid}/>)}
          </div>
        </div>
        <div className={'row'}>
          <PortfolioTable/>
        </div>
        {disclaimer?.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid}/>)}
      </div>
    </div>
  );
}

export default ETPBitcoinEffect;

function PortfolioTable() {
  return (
    <div className={'etp-portfolio'}>
      <PortfolioKeys/>
      <div className={'etp-portfolio-columns'}>
        <PortfolioColumn
          img={'/images/pie-chart-standard.svg'}
          alt={'standard portfolio pie chart'}
          title={'Standard'}
          composition={'60% Stocks + 40% Bonds'}
          returns={'6.9%'}
          drawdown={'21.4%'}
          volatility={'9.3%'}
          correlation={'-'}
        />
        <PortfolioColumn
          img={'/images/pie-chart-gold.svg'}
          alt={'portfolio pie chart with gold'}
          title={'Gold'}
          composition={'57% Stocks + 39% Bonds + 4% Gold'}
          returns={'7.0%'}
          drawdown={'20.9%'}
          volatility={'9.0%'}
          correlation={'99.8%'}
        />
        <PortfolioColumn
          img={'/images/pie-chart-bitcoin.svg'}
          alt={'portfolio pie chart with bitcoin'}
          title={'Bitcoin'}
          composition={'57% Stocks + 39% Bonds + 4% Bitcoin'}
          returns={'14.4%'}
          drawdown={'22.9%'}
          volatility={'10.4%'}
          correlation={'92.5%'}
          emphasis
        />
      </div>
    </div>
  );
}

function PortfolioColumn({img, alt, title, composition, returns, drawdown, volatility, correlation, emphasis}) {
  return (
    <div className={'etp-portfolio-column'}>
      <div className={'etp-portfolio-header'}>
        <img src={img} alt={alt}/>
        <h5>{title}</h5>
        <p>{composition}</p>
      </div>
      <div className={`etp-portfolio-data${emphasis ? ' text-bold' : ''}`}>
        <div className={'etp-portfolio-row'}>{returns}</div>
        <div className={'etp-portfolio-row'}>{drawdown}</div>
        <div className={'etp-portfolio-row'}>{volatility}</div>
        <div className={'etp-portfolio-row'}>{correlation}</div>
      </div>
    </div>
  );
}

function PortfolioKeys() {
  return (
    <div className={'etp-portfolio-column etp-portfolio-column-keys'}>
      <div className={'etp-portfolio-header'}/>
      <div className={`etp-portfolio-data`}>
        <div className={'etp-portfolio-row etp-portfolio-row-key'}>Annualized returns</div>
        <div className={'etp-portfolio-row etp-portfolio-row-key'}>Max drawdown</div>
        <div className={'etp-portfolio-row etp-portfolio-row-key'}>Volatility</div>
        <div className={'etp-portfolio-row etp-portfolio-row-key'}>Correlation to standard portfolio</div>
      </div>
    </div>
  )
}
