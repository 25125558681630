function ETPReservesExplanation({ blok }) {
  const { image, header, title_one, text_one, title_two, text_two } = blok;

  return (
    <div className='widget white'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-4'>
            <div className='etp-reserves-explanation-logo'>
              <img src={image.filename}/>
            </div>
          </div>
          <div className='col-xl-8'>
            <h3 className='etp-reserves-explanation-title'>{header}</h3>
            <div className='row'>
              <div className='col-md-6'>
                <div className='etp-top-border'>
                  <h5>{title_one}</h5>
                  <p className='etp-reserves-explanation-text'>{text_one}</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='etp-top-border'>
                  <h5>{title_two}</h5>
                  <p className='etp-reserves-explanation-text'>{text_two}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPReservesExplanation;