import React, { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../hooks/useD3'
import orderBy from 'lodash.orderby'
import { bitcoin_price } from './utils'
// import {Pattern} from '../ImageLink'

const height = 640
const width = 1120
const bloomberg = 'BLOCK'
const margin = { top: 5, right: 0, bottom: 0, left: 60 }

export default function Graph() {
  const [series, setSeries] = useState(JSON.parse(bitcoin_price))

  const graphContainerRef = useRef()
  const ref = useD3(
    (svg) => {
      if (!graphContainerRef.current || !series) return
      
      // hide tooltip and line
      svg.selectAll('.tooltip-line').attr('opacity', 0)
      d3.select(graphContainerRef.current).selectAll('.tooltip').attr('opacity', 0)


      const baseSeries = series.find((series) => series.key === bloomberg)
      const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
      const prefix = baseSeries?.prefixY || ''
      const suffix = baseSeries?.suffixY || ''

      let data = orderBy(
        series.flatMap((o) =>
          o.dataX.flatMap((c, i) => {
            return {
              x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
              y: Math.abs(isYNumber ? +o.dataY[i] * 10000  : o.dataY[i] * 10000),
              series: o.key,
            }
          })
        ),
        ['series', 'x'],
        ['asc', 'asc']
      )

      let _data = orderBy(
        series.flatMap((o) =>
          o.dataX.flatMap((c, i) => {
            return {
              x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
              y: Math.abs(isYNumber ? +o.dataY[i] * 6000  : o.dataY[i] * 6000),
              series: o.key,
            }
          })
        ),
        ['series', 'x'],
        ['asc', 'asc']
      )
      _data = _data.reverse()

      const minDate = data.find((o) => o.series === bloomberg).x
      data = data.filter((o) => o.x >= minDate)
      var xExtent = d3.extent(
        data.filter((o) => o.series === baseSeries.key),
        (d) => d.x
      )
      const xScale = d3
        .scaleTime()
        .domain(xExtent)
        .range([0, width - margin.left])

      const yMaxValue = d3.max(data, (d) => d.y)
      const yMinValue = d3.min(data, (d) => d.y)

      const yScale = d3
        .scaleLinear()
        .range([height - margin.bottom - margin.top, 0])
        .domain([yMinValue, yMaxValue])
        .nice(5)

      const sumstat = d3.group(data, (d) => d.series)
      const sumstat1 = d3.group(_data, (d) => d.series)
      

      const maxDate = xExtent[1]
      const ticks = []
      const fullLength = Math.floor((maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24))
      const nthTick = Math.ceil(fullLength / 6)

      for (let i = 0; i < fullLength; i += nthTick) {
        var result = new Date(minDate)
        result.setDate(result.getDate() + i)
        ticks.push(result)
      }
      const xAxis = d3
        .axisBottom()
        .scale(xScale)
        .tickValues(ticks)
        .tickSizeInner(0)
        .tickSizeOuter(-1 * height)
        .tickPadding(18)
        .tickFormat(d3.utcFormat("%Y"))

      svg.select('.x-axis')
        .attr('transform', `translate(${margin.left}, ${height - margin.bottom + margin.top})`)
        .call(xAxis)

      d3.select('.x-axis .tick:first-of-type').remove()

      // y axis
      const yAxis = d3
        .axisLeft()
        .scale(yScale)
        .ticks(5)
        .tickSizeInner(0)
        .tickSizeOuter(-1 * (width))
        .tickPadding(18)
        .tickFormat((d) => `$${d3.format(',.0f')(d)}`)

      svg.select('.y-axis').attr('transform', `translate(${margin.left}, ${margin.top})`).call(yAxis)
      svg.select('.y-axis .tick:first-of-type line').remove()

      svg.selectAll('.y-axis .tick line').attr('x2', width).attr('stroke-dasharray', 2)

      // draw tooltip hover
      const focus = svg.selectAll('.tooltip-line').attr('opacity', 0)
      const focusText = d3.select(graphContainerRef.current).selectAll('.tooltip').attr('opacity', 0)
      
      // draw series lines
      const redZone = svg
        .select('.red-zone')
        .attr("transform", `translate(${margin.left}, ${yScale(5000)})`)
        .append("line")
        .attr("x2", width - margin.left)
        .style("stroke", "#d3f5e7")
        .style("opacity", ".5")
        .style("stroke-width", "10px")
      const w = yScale(20000) - yScale(22000)
      const greenZone = svg
        .select(".green-zone")
        .attr("transform", `translate(${margin.left}, ${yScale(21000)})`)
        .append("line")
        .attr("x2", width - margin.left)
        .style("stroke", "#ffcfd1")
        .style("opacity", ".5")
        .style("stroke-width", w)
        
        const lines1 = svg
        .select('.plot-area-t')
        .attr('transform', `translate(${margin.left}, 0)`)
        .selectAll('path')
        .data(sumstat1)
        .join('path')
        .attr('fill', 'none')
        .attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
        .attr('stroke', 'white')
        .attr('d', (d) => {
          return d3
            .line()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))(d[1])
        })
        .raise()
      const lines = svg
        .select('.plot-area')
        .attr('transform', `translate(${margin.left}, 0)`)
        .selectAll('path')
        .data(sumstat)
        .join('path')
        .attr('fill', 'none')
        .attr('stroke-width', (d) => series.find((o) => o.key === d[0])?.lineSize)
        .attr('stroke', 'rgba(247, 147, 26, 1)')
        .attr('d', (d) => {
          return d3
            .line()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))(d[1])
        })
        .raise()
      // lines
      // animate series
      const nodes = [...lines.nodes(), ...redZone.nodes(), ...greenZone.nodes()]

      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index]

        const totalLength = element.getTotalLength()
        d3.select(element)
          .attr('stroke-dasharray', totalLength + ' ' + totalLength)
          .attr('stroke-dashoffset', totalLength)
          .transition()
          .duration(1500)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0)
      }

      // mouse tooltip positioning
      svg.selectAll('.hover-container').remove()

      svg.append('rect')
        .classed('hover-container', true)
        .style('fill', 'none')
        .attr('transform', `translate(${margin.left}, 0)`)
        .style('pointer-events', 'all')
        .attr('width', width - margin.left)
        .attr('height', height)
        .on('mousemove', mousemove)
        .on('mouseout', mouseout)

      function mousemove(ev) {
        // recover coordinate we need
        const pointerPosition = d3.pointer(ev)
        var x0 = xScale.invert(pointerPosition[0])
        var selectedData =
          data[
          d3.bisectLeft(
            data.map((o) => o.x),
            x0
          )
          ]

        const tooltipX = xScale(selectedData.x) + margin.left
        const tooltipY = yScale(selectedData.y)
        focus.attr('transform', `translate(${tooltipX}, 0)`)

        const xPosition = tooltipX / (width - margin.left)
        const boundingBox = svg.node().getBoundingClientRect()
        const ratio = boundingBox.width / width
        const leftMargin = margin.left * ratio
        const focusBoundingBox = focusText.node().getBoundingClientRect()

        const tooltipPosition = {
          x: (boundingBox.width - leftMargin) * xPosition,
          y: tooltipY * ratio,
        }

        focus.style('opacity', 1)
        focusText.style('opacity', 1)
        focusText.style('left', (tooltipPosition.x > boundingBox.width - focusBoundingBox.width ? tooltipPosition.x - focusBoundingBox.width : tooltipPosition.x) + 'px').style('top', tooltipPosition.y + 'px')

        const yValue = parseFloat(parseFloat(selectedData.y).toFixed(2)).toLocaleString('en-US')
        focusText.select('.tooltip-x').html(`${d3.utcFormat('%Y-%m-%d')(selectedData.x)}`)
        focusText.select('.tooltip-y').html(`Price: ${prefix}${yValue}${suffix}`)
        focusText.select('.tooltip-z').html(`MVRV: ${(selectedData.y / 1000).toFixed(2)}`)
      }
      function mouseout() {
        focus.style('opacity', 0)
        focusText.style('opacity', 0)
      }

    },
    [series]
  )

  return (
    // <div className={background.toLowerCase()}>
    <div>
      <div ref={graphContainerRef} className='line-graph-container series'>
        <svg ref={ref} className='line-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
          <g className='x-axis'>
            <line x1={0} x2={width - margin.left} strokeDasharray={2} strokeWidth={1} stroke={'white'} />
          </g>
          <g className='y-axis' />

          <g className='red-zone' />
          <g className='green-zone' />

          <g className='plot-area' />
          <g className='plot-area-t' />
          <g className='tooltip-line'>
            <line x1={0} x2={0} strokeWidth={1} y1={margin.top} y2={height + margin.top - margin.bottom} stroke={'#fff'} />
          </g>
        </svg>
        <div className='tooltip'>
          <div className='tooltip-x' />
          <div className='tooltip-y' />
          <div className='tooltip-z' />
        </div>
      </div>
    </div>
  )
}

