import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { Scrollbar } from '../Scrollbar'
import BoxSet from './BoxSet'

const boxSets = [
	{
		key: '1',
		title: 'Crypto and the environment',
		background: '/images/boxset 1.png',
	},
	{
		key: '2',
		title: 'Defi',
		background: '/images/boxset 2.png',
	},
	{
		key: '3',
		title: 'Mining',
		background: '/images/boxset 3.png',
	},
	{
		key: '4',
		title: 'Crypto Regulations',
		background: '/images/boxset 4.png',
	},
]

export const BoxSets = ({ blok }) => {
    const { anchor_key, title, background, description, call_to_action } = blok

	return (
		<div className={background.toLowerCase()} id={anchor_key} >
			<div {...storyblokEditable(blok)} className='container'>
				<div className={`row box-sets`}>
					<div className='col-lg-8 col-md-12'>
						<h2>{title}</h2>
						<p className='body-intro'>{description}</p>
					</div>
					<div className='col-12'>
						<div className='boxsets-contaienr row'>
							<div className='col'>
								<Scrollbar>
									<div className='boxsets-list'>
										{boxSets.map((set) => (
											<BoxSet key={set.key} set={set} />
										))}
									</div>
								</Scrollbar>
							</div>
						</div>
					</div>

					<div className='col-12'>
						{call_to_action.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default BoxSets
