import React from 'react'

import { storyblokEditable } from '@storyblok/react'

const RelatedNews = ({ blok }) => {
	console.log('RelatedNews', blok)

	return (
		<div {...storyblokEditable(blok)}>
			<span>RelatedNews</span>
		</div>
	)
}

export default RelatedNews
