function ETPSecurity({ blok }) {
  const { title, text_one, text_two, logo } = blok;

  return (
    <div className='widget gray etp-security'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='etp-security-logo'><img src={logo.filename} alt={logo.title}/></div>
          </div>
          <div className='col-md-8 etp-security-text'>
            <h3>{title}</h3>
            <p>{text_one}</p>
            <p>{text_two}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPSecurity;
