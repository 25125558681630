import { copyBloomberg, copyIsin } from '../../utils';

function ETPKeyInformation({ blok, isin, bloomberg, title }) {
  const { fact_sheet, kid } = blok;

  return (
    <div className={'row etp-key-information'}>
      <div className={'col-lg-4'}><h2 className='h3'>Key Information</h2></div>
      <div className={'col-lg-8 etp-key-information-buttons'}>
        <button className={'button button--tag is-active'} onClick={() => copyBloomberg({ bloomberg, title })}>
          <span className={'text-bold'}>TICKER</span>
          <span>{bloomberg}</span>
          <img src='/images/copy-thin.svg' alt='download icon'/>
        </button>
        <button className={'button button--tag is-active'} onClick={() => copyIsin({ isin, title })}>
          <span className={'text-bold'}>ISIN</span>
          <span>{isin}</span>
          <img src='/images/copy-thin.svg' alt='download icon'/>
        </button>
        <a className={'button button--tag is-active'} href={fact_sheet.filename} target='_blank'>
          <span>Fact Sheet</span>
          <img src='/images/arrow-down-to-line.svg' alt='download icon'/>
        </a>
        <a className={'button button--tag is-active'} href={kid.filename} target='_blank'>
          <span>KID</span>
          <img src='/images/arrow-down-to-line.svg' alt='download icon'/>
        </a>
      </div>
    </div>
  );
}

export default ETPKeyInformation;
