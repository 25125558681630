import React from 'react'
import { storyblokEditable } from '@storyblok/react'

export const IFrame = ({ blok }) => {
	const { background = 'transparent', anchor_key, height, source } = blok

	return (
		<div className={`${background.toLowerCase()} widget`}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<iframe style={{border: "none"}} title={anchor_key} src={source.cached_url} id={anchor_key} height={height} width={'100%'}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IFrame
