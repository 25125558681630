import { Dropdown, TextField, DirectionalHint } from "@fluentui/react";
import React, { useState } from "react";
import { RichText } from "../RichText";
import useForm from "../../hooks/useForm";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import ETPButton from "../ETPButton";
import { INVESTOR_TYPES, cookieNames, getCookie } from "../../utils";
// import { gql, useQuery } from '@apollo/client';

const countries = [
  {
    name: "Andorra",
    code: "AD",
  },
  {
    name: "Albania",
    code: "AL",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Åland Islands",
    code: "AX",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "Cyprus",
    code: "CY",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "Faroe Islands",
    code: "FO",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Isle of Man",
    code: "IM",
  },
  {
    name: "Iceland",
    code: "IC",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
  },
];

const defaultFormValue = {
  firstname: "",
  lastname: "",
  company_name: "",
  email: "",
  phone_number: "",
  country: "",
  message: "",
};

export const BrokerContactForm = ({ blok }) => {
  const location = useLocation();
  const {
    title,
    description,
    form_type,

    background,
    confirmation_title,
    confirmation_description,
    pardot_endpoint,
    first_name_text,
    first_name_error_text,
    last_name_text,
    last_name_error_text,
    company_name_text,
    company_error_text,
    message_text,
    message_error_text,
    email_text,
    email_error_text,
    phone_number_text,
    phone_number_error_text,
    country_text,
    country_error_text,
    country_placeholder,
    terms_and_condition,
    confirm_button_text,
  } = blok;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formSrc, setFormSrc] = useState("");
  // const { data } = useQuery(query, { variables: { language: language !== 'en' ? `${language}/*` : undefined } });

  const handleFormSave = useCallback(
    (formValues) => {
      const fullValues = {
        ...formValues,
        website_form_location: location.pathname.substring(1),
        website_form_type: form_type,
      };
      const pardotValues = Object.entries(fullValues)
        .map((o) => `${o[0]}=${encodeURIComponent(o[1])}`)
        .join("&");
      setFormSrc(`${pardot_endpoint}?${pardotValues}`);
      setShowConfirmation(true);
    },
    [pardot_endpoint, form_type, location.pathname]
  );

  const contactValidator = {
    firstname: {
      required: true,
      length: 250,
      errorMessage: first_name_error_text,
    },
    lastname: {
      required: true,
      length: 250,
      errorMessage: last_name_error_text,
    },
    company_name: {
      required: true,
      length: 250,
      errorMessage: company_error_text,
    },
    message: {
      required: true,
      length: 10,
      errorMessage: message_error_text,
    },
    email: {
      required: true,
      length: 250,
      validator: {
        regEx: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,15})$/,
        error: email_error_text,
      },
      errorMessage: email_error_text,
    },
    phone_number: {
      required: true,
      validator: {
        error: phone_number_error_text,
        regEx: /^\+[1-9]\d{1,14}$/,
      },
      errorMessage: phone_number_error_text,
    },
    country: {
      required: true,
      errorMessage: country_error_text,
    },
  };

  const { formState, handleOnChange, handleOnSubmit } = useForm(
    defaultFormValue,
    contactValidator,
    handleFormSave
  );

  const isNotVisible =
    getCookie(cookieNames.INVESTOR_TYPE) !== INVESTOR_TYPES.INSTITUTIONAL;

  if (isNotVisible) return <></>;

  return (
    <>
      {!showConfirmation && (
        <div className={background}>
          <div className="container form-space">
            <div className="row">
              <div className="col-md-4 form-title">
                {title && <h5>{title}</h5>}

                {description && (
                  <div className="body-intro">
                    <RichText inline={true} blok={description} />
                  </div>
                )}
              </div>
              <div className="col-md-8">
                <form className="broker-contact-form">
                  <div className="row form">
                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <TextField
                          name="firstname"
                          className={formState.firstname.error ? "error" : ""}
                          onChange={(ev, value) => {
                            if (handleOnChange)
                              handleOnChange("firstname", value);
                          }}
                          defaultValue={formState.firstname.value}
                          label={`${first_name_text}*`}
                          errorMessage={formState.firstname.error}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <TextField
                          name="lastname"
                          className={formState.lastname.error ? "error" : ""}
                          onChange={(ev, value) => {
                            if (handleOnChange)
                              handleOnChange("lastname", value);
                          }}
                          defaultValue={formState.lastname.value}
                          label={`${last_name_text}*`}
                          errorMessage={formState.lastname.error}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <TextField
                          name="company_name"
                          className={
                            formState.company_name.error ? "error" : ""
                          }
                          onChange={(ev, value) => {
                            if (handleOnChange)
                              handleOnChange("company_name", value);
                          }}
                          defaultValue={formState.company_name.value}
                          label={`${company_name_text}*`}
                          errorMessage={formState.company_name.error}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <Dropdown
                          calloutProps={{
                            directionalHint: DirectionalHint.bottomLeftEdge,
                            directionalHintFixed: true,
                          }}
                          options={countries.map((o) => {
                            return {
                              key: o.code,
                              text: o.name,
                            };
                          })}
                          onChange={(ev, option) => {
                            if (handleOnChange)
                              handleOnChange("country", option.key);
                          }}
                          type="text"
                          name="country"
                          label={`${country_text}*`}
                          placeholder={country_placeholder}
                          styles={{ title: { minHeight: 41 } }}
                          errorMessage={formState.country.error}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <TextField
                          name="email"
                          className={formState.email.error ? "error" : ""}
                          onChange={(ev, value) => {
                            if (handleOnChange) handleOnChange("email", value);
                          }}
                          defaultValue={formState.email.value}
                          label={`${email_text}*`}
                          errorMessage={formState.email.error}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-input">
                        <TextField
                          name="phone_number"
                          className={
                            formState.phone_number.error ? "error" : ""
                          }
                          onChange={(ev, value) => {
                            if (handleOnChange)
                              handleOnChange("phone_number", value);
                          }}
                          defaultValue={formState.phone_number.value}
                          label={`${phone_number_text}*`}
                          errorMessage={formState.phone_number.error}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-input">
                        <TextField
                          name="message"
                          onChange={(ev, value) => {
                            if (handleOnChange)
                              handleOnChange("message", value);
                          }}
                          defaultValue={formState.message.value}
                          label={`${message_text}*`}
                          errorMessage={formState.message.error}
                          multiline
                          rows={6}
                        />
                      </div>
                    </div>
                    <div className="col-12 form-footer-box form-footer-text">
                      <RichText inline={true} blok={terms_and_condition} />
                      <ETPButton
                        blok={{
                          onClick: handleOnSubmit,
                          text: confirm_button_text,
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && formSrc && (
        <div className={background}>
          <form className="container broker-contact-form-confirmation">
            {confirmation_title && (
              <h3 className="content">{confirmation_title}</h3>
            )}

            {confirmation_description && (
              <RichText inline={true} blok={confirmation_description} />
            )}

            <iframe
              frameBorder={0}
              opacity={0}
              src={formSrc}
              title={formSrc}
              width="1"
              height="1"
            ></iframe>
          </form>
        </div>
      )}
    </>
  );
};

export default BrokerContactForm;
