import React, { useMemo, useCallback } from 'react'
import './Button.scss'

import { storyblokEditable } from '@storyblok/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import forwardWhite from '../../icons/forwardWhite.svg'
import forwardBlack from '../../icons/forwardBlack.svg'
import { Link, useLocation } from 'react-router-dom'
import { logAction } from '../../actions'


export const Button = ({ blok, theme }) => {
	const { link, style, type, icon, copy_text } = blok
	const { anchor, cached_url, linktype } = link
    const location = useLocation()


	let defaultIcon = style === "" && (type === "" || ! type ) && (forwardBlack);
	// if (defaultIcon && (theme === "blue" || theme === "transparent")) {
	// 	defaultIcon = forwardBlack;
	// }

	const className = useMemo(() => {
		const classNames = ['button']

		switch (style) {
			case '2':
				classNames.push('secondary')
				break
			case '3':
				classNames.push('secondary')
				break
			default:
				break
		}

		if(!defaultIcon && !icon) {
			classNames.push('no-icon')
		}

		switch (type) {
			case 'link_button':
				classNames.push('link-button')
				break

			default:
				break
		}

		return classNames.join(' ')
	}, [type, style, defaultIcon, icon])

    const handleCopy = useCallback(() => {
        if (copy_text) {
            navigator.clipboard.writeText(copy_text);
        }
    }, [copy_text])

	if (type === 'copy_button') {
		return (
			<button className={className} onClick={handleCopy}>
				<span>{blok.text}</span>
				{icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />}
			</button>
		)
	} else if (linktype === 'story' && !anchor) {
		return (
			<Link {...storyblokEditable(blok)} to={cached_url || '/'} className={className} onClick={() => logAction('navigation', { from: location.pathname, to: cached_url || '/'})}>
				<span>{blok.text}</span>
				{defaultIcon ? (
					<img src={defaultIcon} alt='' className='icon-foward' />
				) : (
					icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />
				)}
			</Link>
		)
	} else if (linktype === 'story' && anchor) {
		return (
			<a {...storyblokEditable(blok)} href={`#${anchor}`} className={className}>
				<span>{blok.text}</span>
				<img src='/images/ChevronDown.svg' alt='download' className='button-icon-dark' />
				<img src='/images/ChevronDownLight.svg' alt='download' className='button-icon-light' />
			</a>
		)
	} else if (linktype === 'url') {
		return (
			<a {...storyblokEditable(blok)} href={cached_url} target='_blank' rel='noreferrer' className={className}>
				<span>{blok.text}</span>
				{icon && icon.icon && <FontAwesomeIcon fontSize={20} icon={`${icon.type} ${icon.icon}`} />}
			</a>
		)
	} else if (linktype === 'asset') {
		return (
			<a {...storyblokEditable(blok)} href={cached_url} target='_blank' rel='noreferrer' className={className} onClick={() => logAction('download', { link: cached_url })}>
				<span>{blok.text}</span>
				<img src='/images/DownloadIcon.svg' alt='download' className='button-icon-dark' />
				<img src='/images/DownloadIconLight.svg' className='button-icon-light' alt='download' />
			</a>
		)
	}
}

export default Button
