import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ETPLink from "../ETPLink";

function ETPFAQ({blok}) {
  const {question, answer, link} = blok;
  const [open, setOpen] = useState(false);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <div className={`etp-faq${open ? ' etp-faq-open' : ''}`} onClick={toggleOpen}>
      <div className={'etp-faq-question'}>
        <div className={'etp-faq-chevron'}>
          <FontAwesomeIcon icon={'chevron-right'} />
        </div>
        <h5>{question}</h5>
      </div>
      <div className={'etp-answer'}>
        <p>{answer}</p>
        {/http(s)?:\/\//.test(link.cached_url) ? <a className="etp-link" href={link.cached_url} target="_blank">
          Read more<img className="etp-link-arrow-white" src="/images/arrow-right-white.svg" alt="arrow right" height="14" width="14"/>
            <img className="etp-link-arrow-black" src="/images/arrow-right-black.svg" alt="arrow right" height="14" width="14" />
        </a> : <ETPLink to={link.cached_url}>Read more</ETPLink>}
      </div>
    </div>
  )
}

export default ETPFAQ;