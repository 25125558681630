import logAction from "../../actions";
import "./UniversalDocument.scss";

function UniversalDocument({ document, image }) {
  const { name, static_link, assets_link, etp, type, icon, _uid } = document;
  return (
    <div key={_uid} className="etp-document">
      <img
        src={image?.filename}
        className="document-icon"
        alt={icon || "document"}
      />
      <span className="document-name">{name || "No title specified"}</span>
      <span className="document-type">{type}</span>
      <a
        href={static_link || assets_link?.filename || ""}
        className="link download"
        target="_blank"
        alt={name || assets_link?.alt || ""}
        rel="noreferrer"
        onClick={() =>
          logAction("download", {
            link: static_link || assets_link?.filename || "",
          })
        }
      >
        <span>Direct Download</span>
        <img
          src="/images/DownloadIconLight.svg"
          alt="download"
          className="download-icon"
          style={{ height: 18 }}
        />
      </a>
    </div>
  );
}

export default UniversalDocument;
