import ETPLink from '../ETPLink';

function ETPProofOfReserves({ blok }) {
  const { title, subtitle, text, logo, link_text, link } = blok;

  return (
    <div className={'widget widget--slim white'}>
      <div className={'container'}>
        <div className={'row etp-proof-of-reserves-title'}>
          <div className={'col-lg-8'}>
            <p className={'etp-label font-size-14'}>{title}</p>
            <h2>{subtitle}</h2>
          </div>
          <div className={'col-lg-4'}/>
        </div>
        <div className={'row'}>
          <div className={'col-lg-6'}>
            <div className='etp-proof-of-reserves-logo'>
              <img src={logo.filename} alt={logo.alt}/>
            </div>
          </div>
          <div className={'col-lg-6'}>
            <div className={'etp-top-border'}>
              <p>{text}</p>
              <ETPLink to={link.cached_url}>{link_text}</ETPLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPProofOfReserves;