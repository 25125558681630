import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { cookieNames, getCookie } from '../utils';

const Page = ({ blok, language, slug }) => {
  const { isin, name, title: pageTitle, metatags = {}, drop_suffix, theme, logo, bloomberg: preBloomberg } = blok;
  const { title, description, og_title, og_description, og_image } = metatags;
  const [bloomberg, setBloomberg] = useState();
  const location = useLocation();
  const [userLocation, setUserLocation] = useState();

  useEffect(() => {
    if (isin) {
      fetchETP([isin]).then(([data]) => {
        setBloomberg(data.sections[0].meta.find(m => m.key === 'bloomberg').value);
      });
    }
  }, [isin]);

  useEffect(() => {
    const storedLocation = getCookie(cookieNames.LOCATION);
    setUserLocation(
      storedLocation && storedLocation.split('-').map(w => w[0].toUpperCase() + w.substring(1)).join(' ')
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{og_title || title || pageTitle || name || 'Home'}{drop_suffix ? '' : ' - CoinShares'}</title>
        <meta name='description' content={og_description || description || title || pageTitle || name} />
        <meta property='og:title' content={og_title || title || name || pageTitle || 'Home'} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={window.location.origin + location.pathname} />
        <meta property='og:description' content={og_description || description || title || pageTitle || name} />
        <meta property='og:image' content={(og_image + '/m/') || '/images/announcements.png'} />
        {/* {isETPSubdomain() && <meta name="robots" content="noindex" />} */}
        <link rel='canonical' href={window.location.origin + `${location.pathname !== '/' ? location.pathname : ''}`} />
        <link rel="alternate" href={`${window.location.origin}${resolvePath(location.pathname)}`} hreflang={'en-gb'} />
        <link rel="alternate" href={`${window.location.origin}/de${resolvePath(location.pathname)}`} hreflang={'de-DE'} />
        <link rel="alternate" href={`${window.location.origin}/fr${resolvePath(location.pathname)}`} hreflang={'fr-FR'} />
        <link rel="alternate" href={`${window.location.origin}/sv${resolvePath(location.pathname)}`} hreflang={'sv-SE'} />
        <link rel="alternate" href={`${window.location.origin}${resolvePath(location.pathname)}`} hreflang={'x-default'} />
        <meta name="facebook-domain-verification" content="y6pqh4649hm5f085zapovuun7uc7t0" />
      </Helmet>

      <div {...storyblokEditable(blok)}>
        {blok.body ? blok.body.map((blok) => (
          <StoryblokComponent
            blok={blok}
            key={blok._uid}
            isin={isin}
            name={name || title}
            slug={slug}
            bloomberg={bloomberg || preBloomberg}
            language={language}
            location={userLocation}
            logo={logo}
            theme={theme}
          />
        )) : null}
      </div>
    </>
  );
};

export default Page;

function fetchETP(isin) {
  const baseUrl = `${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}`;
  return fetch(`${baseUrl}&names=ETP_LISTINFO_${isin}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(data => data.json());
}

function resolvePath(location) {
  let path = '';
  if (location === '/en/' || location === '/en' || location === '/fr/' || location === '/fr'
    || location === '/de/' || location === '/de' || location === '/sv/' || location === '/sv') {
    path = '';
  } else if (location.startsWith('/en/') || location.startsWith('/fr/') || location.startsWith('/de/') || location.startsWith('/sv/')) {
    path = `/${location.slice(4, location.length)}`;
  } else {
    path = location;
  }
  return path;
}
