import { StoryblokComponent } from "@storyblok/react";
import * as d3 from "d3";
import { useEffect, useState } from "react";
import fetchGraphData from "../MarketPerformanceGraph/fetchGraphData";
import KeyStatistics from "../MarketPerformanceGraph/KeyStatistics";
import PerformanceGraph from "../MarketPerformanceGraph/PerformanceGraph";
import { Helmet } from "react-helmet";
import { gql, useQuery } from "@apollo/client";
import { INVESTOR_TYPES, cookieNames, getCookie } from "../../utils";

function ETPProduct({ blok, isin, bloomberg, name, language, logo }) {
  const {
    button,
    series_suffix = "",
    footer,
    anchor_key,
    entitlement_historical_data,
    entitlement_historical_link,
    entitlement_historical_link_icon,
    entitlement_historical_link_text,
  } = blok;
  const identifier = isin || bloomberg;
  const [seriesData, setSeriesData] = useState([]);
  const [overviewData, setOverviewData] = useState([]);
  const [productSD, setProductSD] = useState({});
  const [productsUnavailable, setProductsUnavailable] = useState(false);
  const [isIndividualType, setIndividualType] = useState(false);

  const { data } = useQuery(brokerQuery);
  const { LegalnoticeItems } = data || {};

  useEffect(() => {
    handleFetchData();
  }, [isin, bloomberg, series_suffix]);

  useEffect(() => {
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    if (legalNotices && investorType) {
      let countryName = getCookie(cookieNames.LOCATION);
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [LegalnoticeItems?.items]);

  useEffect(() => {
    setIndividualType(
      getCookie(cookieNames.INVESTOR_TYPE) === INVESTOR_TYPES.INDIVIDUAL
    );
  }, []);

  async function handleFetchData() {
    const { seriesData, overviewData } = await fetchGraphData({
      identifier,
      isin,
      bloomberg,
      series_suffix,
    });
    setSeriesData(seriesData);
    setOverviewData(overviewData);
    setProductSD({
      "@context": "https://schema.org/",
      "@type": "Product",
      name: name,
      description: name,
      sku: isin,
      brand: {
        "@type": "Brand",
        name: "Coinshares",
      },
      image: [logo?.filename],
      offers: {
        "@type": "Offer",
        priceCurrency: "EUR",
        price:
          Number(
            overviewData
              .find((item) => item.key === "aUM")
              ?.value.replace(/,/gi, "")
          ) || 0,
        availability: "https://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: "Coinshares",
        },
      },
    });
  }

  const sourceText =
    seriesData.length &&
    `Source: ${seriesData[0].source}. Data available as of ${d3.utcFormat(
      "%Y-%m-%d %H:%M:%S"
    )(new Date(seriesData[0].updated))}`;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(productSD)}</script>
      </Helmet>
      <div className={"widget black"} id={anchor_key}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-lg-8"}>
              <h2>{name}</h2>
              <p className={"etp-product-sub-text"}>{sourceText}</p>
            </div>
            <div
              className={
                "col-lg-4 my-4 d-flex justify-content-lg-end align-items-start"
              }
            >
              {(!productsUnavailable && isIndividualType || !isIndividualType) && button?.map((blok) => (
                <StoryblokComponent
                  blok={blok}
                  id={blok._uid}
                  key={blok._uid}
                />
              ))}
            </div>
          </div>
          <div className={"row etp-product-graph"}>
            <div className={"col-lg-8"}>
              <PerformanceGraph
                title={"Performance since inception"}
                data={seriesData}
                identifier={identifier}
                selectedIdentifiers={[identifier]}
                label={
                  <div className={"etp-product-label"}>
                    <div
                      className={"etp-product-label-square"}
                      style={{ backgroundColor: seriesData?.[0]?.colour }}
                    ></div>
                    <div>{name}</div>
                  </div>
                }
              />
            </div>
            <div className={"col-lg-4"}>
              <KeyStatistics
                data={overviewData}
                historical_data={entitlement_historical_data}
                historical_link={entitlement_historical_link}
                historical_link_icon={entitlement_historical_link_icon}
                historical_link_text={entitlement_historical_link_text}
              />
            </div>
          </div>
          {footer?.map((blok) => (
            <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid} />
          ))}
        </div>
      </div>
    </>
  );
}

export default ETPProduct;

const brokerQuery = gql`
  query {
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;
