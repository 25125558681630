import React, { useCallback, useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { TabStrip } from '../TabStrip';
import orderBy from 'lodash.orderby';
import { useMemo } from 'react';
import uniqBy from 'lodash.uniqby';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logAction from '../../actions';

// const etpTypes = [
// 	{
// 		title: 'CS PHYSICAL',
// 	},
// ]

export const ETPDocuments = ({ blok }) => {
  const { anchor_key, folder_path, background = 'gray' } = blok;
  const [folders, setFolders] = useState();
  const [icons, setIcons] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedETP, setSelectedETP] = useState(+searchParams.get('filter') || '');

  useEffect(() => {
    if (folder_path) {
      const path = folder_path.slice(1);
      fetch(`${process.env.REACT_APP_API_URI}/storybloks/folders/assets?ApiKey=${process.env.REACT_APP_API_KEY}&path=${encodeURIComponent(path)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((value) => {
        value.json().then((value) => {
          setFolders(value.folders.assets[0].folders);
        });
      });

      fetch(
        `${process.env.REACT_APP_API_URI}/storybloks/folders/assets?ApiKey=${process.env.REACT_APP_API_KEY}&path=cryptos`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((value) => {
        value.json().then((value) => {
          const cryptos = value.folders.assets[0].assets;

          setIcons(
            cryptos.map((c) => {
              return {
                key: c.filename.split('/').reverse()[0].replace('icon_', '').split('.')[0],
                filename: c.filename,
              };
            })
          );
        });
      });
    }
  }, [folder_path]);

  const etpTypes = useMemo(() => {
    if (!folders) return [];

    return uniqBy(
      folders.flatMap((f) =>
        f.folders !== null
          ? f.folders
            .flatMap((fc) => fc.assets.flatMap((asset) => asset.tagList))
            .filter((type) => type.name.indexOf('etp:') === 0)
            .map((etpType) => {
              return {
                id: etpType.id,
                name: etpType.name.split(':')[1],
              };
            })
          : []
      ),
      'id'
    );
  }, [folders]);

  const onQueryParamsChange = useCallback((selectedETP) => {
    const params = new URLSearchParams();

    if (selectedETP && selectedETP !== '') {
      params.append('filter', selectedETP);
    }

    setSelectedETP(selectedETP);
    navigate({ search: params.toString() });
  }, [navigate]);

  return (
    <div className={background.toLowerCase()} id={anchor_key}>
      <div {...storyblokEditable(blok)} className='widget'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className='etp-document-filters'>
                <button
                  className={`button button--tag ${selectedETP === '' ? 'is-active' : ''}`}
                  onClick={() => onQueryParamsChange('')}
                >
                  All ETPs
                </button>
                {etpTypes.map((etpType) => (
                  <button
                    className={`button button--tag ${selectedETP === etpType.id ? 'is-active' : ''}`}
                    key={etpType.title}
                    onClick={() => onQueryParamsChange(etpType.id)}
                  >
                    {etpType.name.replace('-', ' ')}
                  </button>
                ))}
              </div>
              {folders && icons && (
                <div className='tab-strip horizontal'>
                  <TabStrip blok={{ tabs: createTabs(folders, selectedETP, icons) }}/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ETPDocuments;

function createTabs(folders, selectedETP, icons) {
  return orderBy(
    folders.map((o) => ({
          _uid: o.key.toString(),
          component: 'tab',
          title: o.name,
          content: (
            o.folders?.map((folder) => {
              const filteredAssets = folder.assets
                .filter((asset) => !selectedETP || asset.tagList.find((tag) => tag.id === selectedETP));
              return (
                <div key={folder.key} className='etp-document-list-container'>
                  <h5>{folder.name}</h5>
                  <div className='etp-document-list'>
                    {filteredAssets.map(({ filename, id, title, alt, tagList }) => {
                      const iconTag = tagList.find((tag) => tag.name.indexOf('icon:') === 0)?.name.split(':')[1];
                      const image = icons.find((i) => i.key === iconTag);

                      return (
                        <div key={id} className='etp-document'>
                          <img src={image?.filename} className='document-icon' alt={title}/>
                          <span className='document-name'>{title || 'No title specified'}</span>
                          <span className='document-type'>
                            {tagList
                              .find((tag) => tag.name.indexOf('type:') === 0)
                              ?.name.split(':')[1]
                              .replace('-', ' ')}
                          </span>
                          <a
                            href={filename}
                            className='link download'
                            target='_blank'
                            alt={alt}
                            rel='noreferrer'
                            onClick={() => logAction('download', { link: filename })}
                          >
                            <span>Direct Download</span>
                            <img
                              src='/images/DownloadIconLight.svg'
                              alt='download'
                              className='download-icon'
                              style={{ height: 18 }}
                            />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })
          ),
        }
      )
    ),
    ['title'],
    ['desc']
  );
}