import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import React, { useMemo, useState, useEffect } from 'react';
import { gql, useQuery } from "@apollo/client";
import { etpLink } from '../../utils';
import { Helmet } from 'react-helmet';
import { cookieNames, getCookie } from "../../utils";
import { RichText } from '../RichText';
import { render } from 'storyblok-rich-text-react-renderer';

function ETPHero({ blok, theme, name, ...rest }) {
  const { breadcrumb, editable_title, title, headline, quote, button, type, footer } = blok;

  const [productsUnavailable, setProductsUnavailable] = useState(false);

  const formattedName = type === 'physical' ? name.replace(csPhysical, '') : name;

  const { data } = useQuery(brokerQuery);
  const { LegalnoticeItems } = data || {};

  useEffect(() => {
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    if (legalNotices && investorType) {
      let countryName = getCookie(cookieNames.LOCATION);
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [LegalnoticeItems?.items]);

  const breadcrumbSD = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumb.map((item, idx) => {
        return {
          "@type": "ListItem",
          "position": idx + 1,
          "name": item.title,
          "item": item.link?.id ? window?.location?.origin + etpLink(item.link.cached_url) : window?.location.href
        }
      })
    }
  }, [breadcrumb])
  return (
    <>
      <Helmet>
        {breadcrumb?.length && <script type="application/ld+json">{JSON.stringify(breadcrumbSD)}</script>}
      </Helmet>
      <div {...storyblokEditable(blok)} className={`etp-hero ${theme}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-12'>
              <div>
                {title && <p className={'etp-title'}>{title}</p>}
                <div className="breadcrumb">
                  {breadcrumb?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
                </div>
                <div className={'etp-headlines'}>
                  {editable_title && <div className={'richtext-title'}>{render(editable_title)}</div>}
                  {!editable_title && 
                    <h1 className={'etp-headline'}>
                      {type === 'xbt' && <p>XBT provider</p>}
                      {type === 'physical' && <p>{csPhysical}</p>}
                      <p>{(headline || formattedName).split('\n').map((s, i, ar)=> {
                        return ar.length - 1 !== i ? <span>{s} <br /></span>: s
                      })}</p>
                    </h1>
                  }
                </div>
                {!productsUnavailable && button?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
              </div>
            </div>
          </div>
          <div className={'row etp-quote-wrapper'}>
            <div className={'col-lg-6'}></div>
            <div className={'col-lg-6'}>
              {quote?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} {...rest} />)}
            </div>
          </div>
          {footer?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} {...rest} />)}
        </div>
      </div>
    </>
  );
}

export default ETPHero;

const csPhysical = 'CoinShares Physical';

const brokerQuery = gql`
  query {
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;