import { StoryblokComponent } from "@storyblok/react";
import Graph from "./Graph";
import ETPLink from "../ETPLink";

export default function ETPMvrvGraph({ blok }) {
  const {
    title,
    text,
    disclaimer,
    discover_button_link,
    discover_button_text,
    discover_text,
  } = blok;
  return (
    <div className={"widget black etp-mvrv-graph"}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-xxl-8"}>
            <h2 className={"mb-5"}>{title}</h2>
            <p>{text}</p>
          </div>
          <div className={"col-xxl-24"}>
            <Graph />
          </div>

          <div className={"col-xxl-24 etp-mvrv-graph__discover"}>
            <p>{discover_text}</p>
            <ETPLink
              to={discover_button_link.cached_url}
              className={"etp-mvrv-graph__discover--link"}
            >
              {discover_button_text}
            </ETPLink>
          </div>
          <div className={"col-xxl-24"}>
            {disclaimer.map((it) => (
              <StoryblokComponent key={it._uid} blok={it} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
