import React from 'react'
import { storyblokEditable } from '@storyblok/react'

export const DailyHedgingPositionGraph = ({ blok }) => {
    const { anchor_key, background = 'gray', title, header, link, description } = blok

    return (
        <div className={background.toLowerCase()} id={anchor_key}>
            <div id='daily_hedging_position' {...storyblokEditable(blok)} className='widget'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 offset-lg-2 col-sm-12'>
                            <h5 className='label'>{title}</h5>
                            <h3 className="content">{header}</h3>
                        </div>
                        <div className='col-lg-8 offset-lg-4  col-sm-12'>
                            <div className="paragraph" style={{ paddingRight: 0 }}>
                                <p style={{ margin: 0 }}>{description}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={
                            { display: 'flex', justifyContent: 'center', marginTop: "50px" }
                        }>
                            <iframe
                                src={link.url}
                                width={"500px"}
                                height={"616px"}
                                style={{ border: "none", borderRadius: '10px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyHedgingPositionGraph
