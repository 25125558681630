import * as d3 from 'd3';
import orderBy from 'lodash.orderby';
import React, { useEffect, useState } from 'react';
import { apiWidgetUrl, getJsonRequest } from '../../constants';
import { useD3 } from '../../hooks/useD3';
import { formatDate } from '../Quote';
import { StoryblokComponent } from '@storyblok/react';

function ETPAssetBreakdownGraph({ blok, isin }) {
  const { title, featured_statements } = blok;
  const [seriesData, setSeriesData] = useState([]);
  const [updated, setUpdated] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    if (isin) {
      fetchData(isin)
        .then(({ rows, updated }) => {
          setSeriesData(orderBy(rows, ['weight'], ['desc']));
          setUpdated(formatDate(new Date(updated)));
        });
    }
  }, [isin]);

  const selectedData = seriesData?.find(d => d.key === selectedKey);

  return (
    <div className='widget black'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7'>
            <h4 className='etp-asset-graph-data-title'>{title}</h4>
            <Table data={seriesData} onSelectKey={setSelectedKey} selectedKey={selectedKey}/>
          </div>
          <div className='col-lg-5'>
            <div className='etp-asset-graph-donut'>
              <Donut
                data={seriesData}
                selectedData={selectedData}
                selectedKey={selectedKey}
                setSelectedKey={setSelectedKey}
                updated={updated}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='etp-asset-graph-statements'>
            {featured_statements?.map(blok => <StoryblokComponent blok={blok} id={blok._uid}/>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPAssetBreakdownGraph;

function Donut({ data, selectedData, selectedKey, setSelectedKey, updated }) {
  const ref = useD3(
    (svg) => {
      if (svg && data) {
        renderSvg(svg, data, selectedKey, setSelectedKey);
      }
    },
    [data, selectedKey, setSelectedKey]
  );

  const formattedWeight = `${selectedData?.weight?.charAt(0)?.replace('0', '')}${selectedData?.weight?.slice(1)}%`;

  return (
    <div className='etp-donut-container'>
      {selectedData ? (
        <div
          className='etp-donut-details'
          style={{
            width: `${width - radius - (donutThickness * 2)}px`,
            height: `${height - radius - (donutThickness * 2)}px`
          }}
        >
          <div className='etp-donut-details-header'>
            <img
              src={`/images/crypto-icons/CryptoName=${selectedData.bloombergCode.toLowerCase()}.svg`}
              alt={`${selectedData.displayName} icon`}
              width='24px'
            />
            <h3>{selectedData.displayName}</h3>
            <h3>{selectedData.bloombergCode}</h3>
          </div>
          <div className='etp-donut-details-row'>
            <span>Weight</span><span>{formattedWeight}</span>
          </div>
          <div className='etp-donut-details-row'>
            <span>Coin entitlement</span><span>{selectedData.coinEntitlement}</span>
          </div>
          <div className='etp-donut-details-row'>
            <span>Reference price</span>
            <span>{selectedData.price}</span>
          </div>
          <div className='etp-donut-details-row'>
            <span>Date added</span>
            <span>{selectedData.dateAdded}</span>
          </div>
        </div>
      ) : (
        <div className='etp-donut-updated'>
          <span>Last updated</span>
          <span>{updated}</span>
        </div>
      )}
      <svg ref={ref} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
        <g className='plot-area'/>
      </svg>
    </div>
  );
}

function Table({ data, onSelectKey, selectedKey }) {
  return (
    <div className='etp-asset-graph-data' onMouseOut={() => onSelectKey(null)}>
      {data.map(d => (
        <div
          className={`etp-asset-graph-data-row${(selectedKey && selectedKey !== d.key) ? ' fade-out' : ''}`}
          onMouseOver={() => onSelectKey(d.key)}
        >
          <div className='etp-asset-graph-data-name'>
            <img
              src={`/images/crypto-icons/CryptoName=${d.bloombergCode.toLowerCase()}.svg`}
              alt={`${d.displayName} icon`}
              width='16px'
            />
            <span>{d.displayName}</span>
          </div>
          <div className='etp-asset-graph-data-weight'>
            <span>{formatWeight(d.weight)}%</span>
            <span>
              {d.indicator === '+' ? (
                <img src='/images/arrow-up-right.svg' alt='arrow up' width='11px'/>
              ) : (
                <img src='/images/arrow-down-right.svg' alt='arrow down' width='11px'/>
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

function formatWeight(weight) {
  if (weight.at(0) === '0') {
    return weight.slice(1, weight.length);
  }
  return weight;
}

const chartColors = [
  '#1D14CF',
  '#3B82F8',
  '#59C3F7',
  '#429698',
  '#58BD91',
  '#81E176',
  '#EB582D',
  '#F49738',
  '#F0D347',
  '#7D177D'
];

async function fetchData(isin) {
  const data = await (await fetch(`${apiWidgetUrl}&names=ISIN_WEIGHTED_${isin}`, getJsonRequest)).json();
  const { sections, updated } = data.find(({ key }) => key === `ISIN_WEIGHTED_${isin}`);
  const rows = sections.map(({ meta, key: metaKey }) =>
    meta.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      },
      { key: metaKey }
    ));
  return { rows, updated };
}

const height = 500;
const width = 500;

const margin = { top: 40, right: 40, bottom: 40, left: 40 };

const donutThickness = 20;

const radius = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) / 2;


function renderSvg(svg, seriesData, selectedSeries, setSelectedKey) {

  const pie = d3.pie().value((d) => +d.weight);

  svg.select('.plot-area')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
    .selectAll('path')
    .data(pie(seriesData))
    .join('path')
    .attr('d', d3.arc().innerRadius(radius - donutThickness).outerRadius(radius))
    .style('stroke-width', '1px')
    .on('mouseenter', (_, d) => setSelectedKey(d.data.key))
    .on('mouseout', () => setSelectedKey(null))
    .transition()
    .attr('fill', (d) => {
      if (selectedSeries === null || d.data.key === selectedSeries) return chartColors[d.index];
      return '#979797';
    })
    .style('opacity', (d) => {
      if (selectedSeries === null || d.data.key === selectedSeries) return 1;
      return 0.1;
    });
}