import React from 'react'
import { useInView } from 'react-intersection-observer'

export const FeatureStatement = ({ feature }) => {
	const { index, title, description } = feature

	const { ref, inView } = useInView({
		threshold: 0,
        delay: (index * 0.6) * 1000,
		triggerOnce: true,
	})

	return (
		<div ref={ref} className={`feature-statement${inView ? ' animate-ease-up' : ''}`}>
			<div className='count'>{(index + 1).toString().padStart(2, '0')}</div>

			<div className='title'><h3 className="content">{title}</h3></div>
			<div className='description'>{description}</div>
		</div>
	)
}

export default FeatureStatement
