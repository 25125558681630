import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { INVESTOR_TYPES, cookieNames, getCookie } from "../../utils";

function ETPQuickNavigation({ blok }) {
  const [isNotInstitutional, setIsNotInstitutional] = useState(false);

  useEffect(() => {
    setIsNotInstitutional(
      getCookie(cookieNames.INVESTOR_TYPE) !== INVESTOR_TYPES.INSTITUTIONAL
    );
  }, []);

  return (
    <div className={"etp-quick-navigation"}>
      <div className={"etp-quick-navigation-title"}>{blok?.title}</div>
      {(blok?.navigation1 === "Brokers" &&
        isNotInstitutional ||
        blok?.navigation1 !== "Brokers") && (
          <a href={makeHash(blok?.navigation1)}>{blok?.navigation1}</a>
      )}
      {(blok?.navigation2 === "Brokers" &&
        isNotInstitutional ||
        blok?.navigation2 !== "Brokers") && (
          <a href={makeHash(blok?.navigation2)}>{blok?.navigation2}</a>
      )}
      {(blok?.navigation3 === "Brokers" &&
        isNotInstitutional ||
        blok?.navigation3 !== "Brokers") && (
          <a href={makeHash(blok?.navigation3)}>{blok?.navigation3}</a>
      )}
      {(blok?.navigation4 === "Brokers" &&
        isNotInstitutional ||
        blok?.navigation4 !== "Brokers") && (
          <a href={makeHash(blok?.navigation4)}>{blok?.navigation4}</a>
      )}
    </div>
  );
}

export default ETPQuickNavigation;

const makeHash = (text) => {
  return "#" + text.toLowerCase().split(" ").join("_");
};
