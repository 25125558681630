import React, { useState, useMemo } from 'react'
import { Modal, Icon } from '@fluentui/react'
import { gql, useQuery } from '@apollo/client'
import { storyblokEditable } from '@storyblok/react'
import logAction from '../../actions'

export const BrokerSelect = ({ blok, language }) => {
    const { title, brokers } = blok

	const [showBrokers, setShowBrokers] = useState(false)

	const query = gql`
	{
		BrokerItems (${language !== 'en' && language !== undefined ? `starts_with: "${language}/*",` : ''} by_uuids: "${brokers.join(',')}"){
			items {
                uuid
				content {
					title
					logo {
						filename
						alt
						title
					}
					link {
						cachedUrl
						linktype
					}
					country
				}
			}
		}
	}
`
	const { /*loading, error,*/ data } = useQuery(query)
	const { BrokerItems } = data || {}

	const allBrokers = useMemo(() => {
		if (BrokerItems) {
			return BrokerItems.items.flatMap((o) => {
				return { uuid: o.uuid, ...o.content }
			})
		}

		return []
	}, [BrokerItems])

	return (
		<>
			<button {...storyblokEditable(blok)} className='button no-icon' onClick={() => setShowBrokers(true)}>
				{title}
			</button>

			<Modal isOpen={showBrokers} onDismiss={() => setShowBrokers(false)} isBlocking={false} containerClassName={'broker-modal'}>
				<div className={'modal-header'}>
					<h2>Select a Broker</h2>
					<button className='icon-button'><Icon className='modal-close' styles={{root: { color: 'white' }, iconHovered: { backgroundColor: 'transparent', opacity: 0.8}}} iconName="Cancel" ariaLabel='Close popup modal' onClick={() => setShowBrokers(false)} /></button>
				</div>
				<div>
					<div className='broker-list'>
						{allBrokers &&
							allBrokers.map((o) => (
								<a key={o.uuid} className='broker' target={"_blank"} title={o.title} href={o.link.cachedUrl} rel="noreferrer" onClick={() => logAction('broker', { link: o.link.cachedUrl})}>
									<img src={o.logo.filename} alt={o.logo.alt} title={o.logo.title} />
								</a>
							))}
					</div>
				</div>
			</Modal>
		</>
	)
}

export default BrokerSelect
