import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const PartnerAndLeader = ({ blok, }) => {
    const { title, partners, background } = blok;
    const { data } = useQuery(query({ partners }));
    const { PartnerwithleadersItems } = data || {};
    const [logoList, setLogiList] = useState([])

    useEffect(() => {
        if (PartnerwithleadersItems?.items?.length) {
            let list = [];
            list = partners.map(partner => {
                return PartnerwithleadersItems.items.find(item => item.uuid === partner)?.content

            })
            setLogiList(list)
        }
    }, [PartnerwithleadersItems, partners])

    return (
        <section className={`partner-and-leader ${background}`}>
            <div className='container'>
                <div className={'row leader-list align-items-center'}>
                    <div className={'col-lg-3'}>
                        <div className={'leader-list-text'}>{title}</div>
                    </div>
                    <div className={'col-lg-9'}>
                        <div className='partner-logo'>
                            {logoList?.map((item,idx) => <img src={item.logo.filename} alt={item.title} key={`${item.title}${idx}`} />)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnerAndLeader;

const query = ({ partners = '*' }) => gql`
    query {
    PartnerwithleadersItems {
        items {
        uuid
        content{
            name
            logo{
                filename
            }
        }
      }
    }
}`;
