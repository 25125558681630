import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { knowledgeCategoryQuery } from "../../queries";
import { etpLink, startsWith } from "../../utils";

function ETPArticleCategories({ blok, language }) {
  const knowledgeResponse = useQuery(knowledgeCategoryQuery, {
    variables: { startsWith: startsWith(language) },
  });
  const categories = knowledgeResponse?.data?.KnowledgecategoryItems?.items;
  const idToSlug = categories?.map(({ content, full_slug, slug }) => [
    queryKey(content.name),
    full_slug,
    slug,
  ]);

  const articlesResponse = useQuery(articlesQuery(idToSlug), {
    variables: { startsWith: startsWith(language), idToSlug },
  });

  const nameToCount = articlesResponse.data
    ? Object.entries(articlesResponse.data)?.map(([key, content]) => [
        key,
        content.total,
      ])
    : [];

  return (
    <div className="widget gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="h3">{blok.text}</h2>
          </div>
          <div className="col-lg-8 etp-article-categories">
            {categories?.map((c) => (
              <Link
                className="etp-article-categories-category"
                to={etpLink(c.full_slug.replace(/\/$/, ""))}
                key={c.full_slug}
              >
                <h3 dangerouslySetInnerHTML={{__html: c.content.name}}></h3>
                <div>
                  {
                    nameToCount.find(
                      ([key]) => key === c.slug.replace("-", "")
                    )?.[1]
                  }
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPArticleCategories;

function queryKey(name) {
  return name
    .split(" ")
    .map((x) => x.toLowerCase())
    .join("");
}

const articleQuery = (id, name, slug) => `
  ${slug.replace("-", "")}: ArticleItems(starts_with: "${name}") {
    total
  }
`;

const articlesQuery = (ids) => {
  return gql`
    query {
      ${ids?.map(([id, name, slug]) => articleQuery(id, name, slug)).join("\n")}
    }
  `;
};
