import { StoryblokComponent } from '@storyblok/react';
import React, { useState } from 'react';

function ETPLeadership({ blok }) {
  const { title, text, profile_cards } = blok;
  const [selectedUid, setSelectedUid] = useState('');

  function handleBioExpand(id) {
    setSelectedUid(selectedUid === id ? '' : id);
  }

  return (
    <div className='widget white'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-4'><h3 className='label c-gray-700'>{title}</h3></div>
          <div className='col-sm-8'><h4>{text}</h4></div>
        </div>
        <div className='row'>
          <div className='col-12'>
            {profile_cards.map((blok) => (
              <StoryblokComponent
                blok={blok}
                key={blok._uid}
                dimProfile={!!selectedUid}
                bioExpanded={selectedUid === blok._uid}
                onExpandBio={handleBioExpand}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPLeadership;