import React, { useMemo, useEffect, useState } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { useQuery, gql } from '@apollo/client'
import Index from './Indice'

export const IndexList = ({ blok, language }) => {
	const query = gql`
{
    IndexItems${language !== 'en' ? `(starts_with: "${language}/*")` : ''} {
        items {
            id
            name
            full_slug
            content {
                bloomberg
                index_key
                description
                download
                performance_percentage_header
                performance_percentage
                title
                type
                component
                card_series_suffix
            }
        }
    }
}
`
	const { /*loading, error,*/ data } = useQuery(query)
	const { IndexItems } = data || {}
	const { anchor_key, title } = blok
	const [indices, setIndices] = useState([])

	const baseIndices = useMemo(() => {
		if (IndexItems) {
			return IndexItems.items.map((o) => {
				return {
					...o,
					...o.content,
				}
			})
		}

		return []
	}, [IndexItems])

	useEffect(() => {
		if (baseIndices.length > 0) {
			fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=${baseIndices.map((o) => `INDEX_KEYSTATS_${o.index_key}`).join(',')}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((value) => {
				value.json().then((value) => {
					const values = [...baseIndices]
					values.forEach((o) => {
						const widget = value.find((val) => val.key === `INDEX_KEYSTATS_${o.index_key.toUpperCase()}`)
						const section = widget.sections.find((section) => section.key === `${o.index_key.toUpperCase()}`)
						const meta = section.meta.find((meta) => meta.key === 'returnSinceInception')

						o.performance_percentage = meta.value
					})

					setIndices(values)
				})
			})
		}
	}, [baseIndices])

	return (
		<div className='white' id={anchor_key}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<h5 className='label c-gray-700'>{title}</h5>
						</div>
					</div>

					<div className='row indices'>
						{indices.map((indice) => (
							<div key={indice.id} className={'col-lg-4 col-sm-6 col-12 d-flex'}>
								<Index indice={indice} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexList
