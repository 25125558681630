import React, { useMemo, useCallback, useRef } from 'react'
import { RichText } from '../RichText'
import BackChevron from '../../icons/BackChevron.svg'
import { useQuery, gql } from '@apollo/client'
import KnowledgeByte from './KnowledgeByte'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import TwitterShare from '../Share/TwitterShare'
import LinkedinShare from '../Share/LinkedInShare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Callout } from '@fluentui/react'
import Vimeo from '@vimeo/player'
import logAction from '../../actions'
import { getCookie } from '../../utils';

export const KnowledgeByteContent = ({ blok, language }) => {
	const { title, body, related_cards = [], video_url, recommended_cards = [], is_question_card, primary_topic, additional_topics } = blok
	const topicIds = [primary_topic, ...additional_topics]

	const query = gql`
    {
        RecommendedCards: ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${recommended_cards.join(',') || 1}", sort_by: "sort_by_date:desc") {
            items {
                content {
                    key
                    title
                    is_question_card
                    primary_topic {
                        content
                    }
                    additional_topics {
                        content
                    }
                    article_type {
                        content
                    }
                    media_type
                }
                uuid
                full_slug
            }
            total
        }
        RelatedCards: ArticleItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${related_cards.join(',') || 1}", sort_by: "sort_by_date:desc") {
            items {
                content {
                    key
                    title
                    is_question_card
                    primary_topic {
                        content
                    }
                    additional_topics {
                        content
                    }
                    article_type {
                        content
                    }
                    media_type
                }
                uuid
                full_slug
            }
            total
        }
        ContenttopicItems(${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${topicIds.filter((o) => o).join(',') || 1}") {
            items {
                uuid
                content {
                    title
                    icon {
                        filename
                    }
                    light_icon {
                        filename
                    }
                }
            }
        }
    }
`

	const { /*loading, error,*/ data } = useQuery(query)
	const { RecommendedCards, RelatedCards, ContenttopicItems } = data || {}
	const [expandCards, setExpandCards] = useState(false)
	const location = useLocation()
	const [showShare, setShowShare] = useState(false)
	const videoRef = useRef()
	const [removeVideoCover, setRemoveVideoCover] = useState(false)
    const [viewedBytes, setViewedBytes] = useState([])

	const mapCards = useCallback((cards) => {
		if (cards) {
			return cards.items.map((item) => {
				// const itemTopics = [item.content.primary_topic.uuid, ...(item.content.additional_topics?.map((o) => o.uuid) || [])].filter((o) => o)
				const itemTopicTitles = [...(item.content.additional_topics?.map((o) => o.content.title) || [])].filter((o) => o)

				let tags = ''
				if (itemTopicTitles.length === 1) tags = itemTopicTitles[0]
				else if (itemTopicTitles.length > 1) tags = `${itemTopicTitles[0]} + ${itemTopicTitles.length - 1} More`

				return {
					uuid: item.uuid,
					full_slug: item.full_slug,
					...item.content,
					// header: item.content.article_type?.content?.title,
					// topics: itemTopics,
					tagIcon: item.content.primary_topic?.content?.icon,
					tagIconHover: item.content.is_question_card ? item.content.primary_topic?.content?.icon : item.content.primary_topic?.content?.light_icon,
					tags: tags === '' ? [] : [tags],
				}
			})
		}
		return []
	}, [])

	const [recommendedCards, relatedCards] = useMemo(() => {
		return [mapCards(RecommendedCards), mapCards(RelatedCards)]
	}, [RecommendedCards, RelatedCards, mapCards])

	const topics = useMemo(() => {
		if (ContenttopicItems) {
			return ContenttopicItems.items.map((item) => {
				return {
					uuid: item.uuid,
					...item.content,
				}
			})
		}

		return []
	}, [ContenttopicItems])

	useEffect(() => {
		setExpandCards(false)
	}, [blok])

	const handlePlayVimeo = useCallback(() => {
		if (videoRef && videoRef.current) {
			const player = new Vimeo(videoRef.current)
			player.play()
			setRemoveVideoCover(true)
		}
	}, [videoRef])

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}&key=${getCookie('visitor_id882933') || '1'}&code=VIEWED-KB`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				setViewedBytes(value)
			})
		})
	}, [])

	return (
		<>
			<div className='headline-container'>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<div className='headline'>
								<Link className='breadcrumb' to='/knowledge-bytes/' style={{ marginBottom: 0 }} onClick={() => logAction('navigation', { from: location.pathname, to: '/knowledge-bytes/' })}>
									<img src={BackChevron} alt='back' />
									<span>All questions and topics</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<div className={'knowledge-byte-detail-container' + (expandCards ? ' open' : '')}>
								<div className={`knowledge-byte-detail ${is_question_card ? 'question' : 'answer'}`}>
									<div className='kb-header'>
										<button className={`share-button ${showShare ? 'close' : ''} ${is_question_card ? '' : 'alternate'}`} id={'share-button'} onClick={() => setShowShare((show) => !show)}>
											{is_question_card && <img src='/images/ShareLight.svg' alt='share' className='share' />}
											{!is_question_card && <img src='/images/ShareDark.svg' alt='share' className='share' />}
											<div className='close-icon'></div>
										</button>
										{showShare && (
											<Callout className={`share-dropdown-container ${is_question_card ? '' : 'alternate'}`} target='#share-button' gapSpace={12} isBeakVisible={false} onDismiss={() => setShowShare(false)}>
												<div className='share-dropdown'>
													<TwitterShare url={window.location.origin + location.pathname} title='Share by Twitter' />
													<LinkedinShare url={window.location.origin + location.pathname} title='Share by Linkedin' />
													<a href={`mailto:?body=${encodeURI(window.location.origin + location.pathname)}%0D%0A%0D%0A&subject=${encodeURIComponent(document.title)}`} title='Share by Email'>
														<FontAwesomeIcon icon='fas fa-envelope' />
													</a>
												</div>
											</Callout>
										)}
									</div>
									<div className='kb-content'>
										<h1>{title}</h1>
										{video_url && video_url.cached_url && (
											<>
												<div className='video-container'>
													{!removeVideoCover && (
														<div className='video-cover' onClick={handlePlayVimeo}>
															<button className='button play-button' />
														</div>
													)}
													<iframe ref={videoRef} className='vimeo-video' src={video_url.cached_url} allow='autoplay' allowFullScreen webkitallowfullscreen='true' mozallowfullscreen='true' title={title} />
												</div>
											</>
										)}

										<RichText inline={true} blok={body} />
									</div>
									<div className='kb-sidebar'>
										<button className='button next' onClick={() => setExpandCards(true)} />

										<span className='description-text'>Read More</span>

										{recommendedCards.length > 0 && (
											<div className='kb-cards'>
												{recommendedCards.map((c) => (
													<div key={c.uuid} className={'kb-card' + (!c.is_question_card ? ' answer' : '')} />
												))}
											</div>
										)}
									</div>
								</div>

								<div className='knowledge-byte-detail-cards'>
									{recommendedCards.map((byte, i) => (
										<KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex(o => o === byte.key) >= 0}/>
									))}

									<div className='sidebar-button'>
										<button className='button back' onClick={() => setExpandCards(false)} />
										<span className='description-text'>Close</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='white'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className='byte-header'>
								<h3 className='content'>Want to go deeper? Try These.</h3>

								<div className='byte-filters'>
									{topics.map((o) => (
										<button key={o.uuid} className='button'>
											<img height={25} src={o.icon.filename} alt={o.title} className='icon' />
											<img height={25} src={o.light_icon.filename} alt={o.title} className='icon-hover' />
											<span>{o.title}</span>
										</button>
									))}
								</div>
							</div>

							<div className='knowledge-bytes topics'>
								<div className='bytes'>
									{relatedCards.map((byte, i) => (
										<KnowledgeByte key={byte.uuid} byte={byte} backgroundImage={i} viewed={viewedBytes.findIndex(o => o === byte.key) >= 0}/>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default KnowledgeByteContent
