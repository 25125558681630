import ETPLink from '../ETPLink';

function NewOfferingLarge({title, text, link, linkTitle}) {

  return (
    <div className='row new-offering-large'>
      <div className='col-md-5'>
        <h2>{title}</h2>
      </div>
      <div className='col-md-7'>
        <p>{text}</p>
        <ETPLink to={link}>{linkTitle}</ETPLink>
      </div>
    </div>
  );
}

export default NewOfferingLarge;