import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Dropdown, DirectionalHint } from "@fluentui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import uniqBy from "lodash.uniqby";
import UniversalDocument from "./UniversalDocument";
import "./ETPDocumentsTable.scss";

function ETPDocumentsTable({ blok }) {
  const { folders, background = "gray" } = blok;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(
    folders.length > 0 ? folders[0] : undefined
  );
  const [selectedETP, setSelectedETP] = useState(
    searchParams.get("filter") || ""
  );
  const [icons, setIcons] = useState();

  useEffect(() => {
    console.log('[pppp', `${process.env.REACT_APP_API_URI}/storybloks/folders/assets?ApiKey=${process.env.REACT_APP_API_KEY}&path=cryptos`);
    fetch(
      `${process.env.REACT_APP_API_URI}/storybloks/folders/assets?ApiKey=${process.env.REACT_APP_API_KEY}&path=cryptos`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((value) => {
      value.json().then((value) => {
        const cryptos = value.folders.assets[0].assets;

        setIcons(
          cryptos.map((c) => {
            return {
              key: c.filename
                .split("/")
                .reverse()[0]
                .replace("icon_", "")
                .split(".")[0],
              filename: c.filename,
            };
          })
        );
      });
    });
  }, []);

  useEffect(() => {
    setSelectedTab(
      (selected) => folders.find((t) => t._uid === selected._uid) || folders[0]
    );
  }, [folders]);

  const etpTypes = useMemo(() => {
    if (!folders) return [];

    return uniqBy(
      folders.flatMap((f) =>
        f.content !== null
          ? f.content
              .flatMap((fc) => fc.documents.flatMap((document) => document.etp))
              .filter((el) => el !== "")
              .map((etpType) => {
                return {
                  name: etpType,
                };
              })
          : []
      ),
      "name"
    );
  }, [folders]);

  const onQueryParamsChange = useCallback(
    (selectedETP) => {
      const params = new URLSearchParams();

      if (selectedETP && selectedETP !== "") {
        params.append("filter", selectedETP);
      }

      setSelectedETP(selectedETP);
      navigate({ search: params.toString() });
    },
    [navigate]
  );

  const filteredDocuments = (documents) => {
    let filter = documents?.filter(
      (el) => !selectedETP || el.etp === selectedETP
    );
    return filter;
  };

  const findIcon = () => {};

  return (
    <div className={background.toLowerCase()}>
      <div className="widget">
        <div className="container">
          <div className="col">
            <div className="documents-table-filters">
              <button
                className={`button button--tag ${
                  selectedETP === "" ? "is-active" : ""
                }`}
                onClick={() => onQueryParamsChange("")}
              >
                All ETPs
              </button>
              {etpTypes.map((etpType) => (
                <button
                  className={`button button--tag ${
                    selectedETP === etpType.name ? "is-active" : ""
                  }`}
                  key={etpType.title}
                  onClick={() => onQueryParamsChange(etpType.name)}
                >
                  {etpType.name.replace("-", " ")}
                </button>
              ))}
            </div>
            <div className="documents-table horizontal">
              <div className={"tabs"}>
                <div className={"tab-headers"}>
                  {folders.filter((o) => o.title).length > 0 && (
                    <Dropdown
                      calloutProps={{
                        directionalHint: DirectionalHint.bottomLeftEdge,
                        directionalHintFixed: true,
                      }}
                      className="tab-dropdown"
                      options={folders.map((o) => {
                        return {
                          key: o._uid,
                          text: o.title,
                        };
                      })}
                      selectedKey={selectedTab?._uid}
                      onChange={(ev, option) =>
                        setSelectedTab(
                          folders.find((o) => o._uid === option.key)
                        )
                      }
                    />
                  )}
                  {folders?.map((el) => (
                    <div
                      className={`tab ${selectedTab === el ? "selected" : ""}`}
                      onClick={() => setSelectedTab(el)}
                    >
                      <h5>{el.title}</h5>
                    </div>
                  ))}
                </div>
                <div className="tab-content">
                  {selectedTab?.content?.map((content) => (
                    <div className="etp-document-list-container">
                      <h5>{content?.name}</h5>
                      <div className="etp-document-list">
                        {filteredDocuments(content?.documents)?.map((el) => {
                          let img = icons?.find(
                            (i) => i.key === (el.icon ? el.icon : "document")
                          );
                          return (
                            <UniversalDocument
                              document={el}
                              image={img || {}}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPDocumentsTable;
