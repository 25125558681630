import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function KeyStatistics({
  data,
  language,
  coin_entitlement_calculator,
  historical_link_text,
  historical_link_icon,
  historical_link,
  historical_data,
}) {
  const { data: translationData } = useQuery(keyTranslationsQuery(language));
  const datasourceEntries = translationData?.DatasourceEntries?.items;

  return (
    <div>
      <h5 className="graph-header label">Key statistics</h5>
      <div className="statistics">
        {data &&
          data.map((o) => {
            const fieldName = datasourceEntries?.find(
              (de) => de.name === o.key
            );
            return o.key === "coinEntitlementAtLaunch" ? (
              <div key={o.key} className="statistic">
                <span>
                  {fieldName?.dimensionValue || fieldName?.value || o.key}
                </span>
                <span className="statistic-detail">
                  <span>{o.value} BTC at launch</span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {coin_entitlement_calculator?.filename && (
                    <a
                      href={coin_entitlement_calculator?.filename}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download CE Calculator
                    </a>
                  )}
                </span>
              </div>
            ) : o.value ? (
              <div key={o.key} className="statistic">
                <span>
                  {fieldName?.dimensionValue || fieldName?.value || o.key}
                </span>
                <span className="statistic-detail">
                  <span>
                    {o.prefix && <span>{o.prefix} </span>}
                    {o.value}
                    {o.suffix && <span> {o.suffix}</span>}
                  </span>
                </span>
              </div>
            ) : (
              ""
            );
          })}
        {historical_link?.cached_url && (
          <div className="entitlement_historical_text">
            <p>{historical_data}</p>
            <div>
              <a
                href={historical_link?.cached_url}
                target="_blank"
                rel="noreferrer"
              >
               {historical_link_text} <FontAwesomeIcon icon={historical_link_icon?.icon} />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KeyStatistics;

const keyTranslationsQuery = (language) => gql`
    {
        DatasourceEntries(datasource: "key-translations", dimension: "${language}", per_page: 1000) {
            items {
                name
                dimensionValue
                value
            }
        }
    }
`;
