import React from 'react'
import { StoryblokComponent } from '@storyblok/react'

export const ButtonStrip = ({ blok }) => {
	const { buttons } = blok
    
	return (
		<div className='button-strip'>
			{buttons.map((blok) => (
				<StoryblokComponent blok={blok} key={blok._uid} />
			))}
		</div>
	)
}

export default ButtonStrip
