import * as d3 from "d3";
import orderBy from "lodash.orderby";
import React, { useRef } from "react";
import { useD3 } from "../../hooks/useD3";

const height = 320
const width = 500

const margin = { top: 5, right: 0, bottom: 35, left: 75 }

function PerformanceGraph({ title, data, selectedIdentifiers = [], identifier, label }) {
  const graphContainerRef = useRef()

  const ref = useD3(
    (svg) => {
      if (graphContainerRef) {
        // hide tooltip and line
        svg.selectAll('.tooltip-line').attr('opacity', 0)
        d3.select(graphContainerRef.current).selectAll('.tooltip-container').attr('opacity', 0)
      }

      if (data?.length && graphContainerRef) {
        const baseSeries = data.find((series) => series.key === identifier)
        const isYNumber = baseSeries?.scaleY === 'CURRENCY' || 'PERCENT'
        const prefix = baseSeries?.prefixY || ''
        const suffix = baseSeries?.suffixY || ''

        let orderedData = orderBy(
          data.flatMap((o) =>
            o.dataX.flatMap((c, i) => {
              return {
                x: d3.timeParse('%Y/%m/%d')(c) || new Date(c),
                y: isYNumber ? +o.dataY[i] : o.dataY[i],
                series: o.key,
              }
            })
          ),
          ['series', 'x'],
          ['asc', 'asc']
        )

        const minDate = orderedData.find((o) => o.series === identifier).x
        orderedData = orderedData.filter((o) => o.x >= minDate)

        var xExtent = d3.extent(
          orderedData.filter((o) => o.series === baseSeries.key),
          (d) => d.x
        )

        const xScale = d3
          .scaleTime()
          .domain(xExtent)
          .range([0, width - margin.left])

        const yMaxValue = d3.max(orderedData, (d) => d.y)
        const yMinValue = d3.min(orderedData, (d) => d.y)

        const yScale = d3
          .scaleLinear()
          .range([height - margin.bottom, 0])
          .domain([yMinValue, yMaxValue])
          .nice(5)

        const sumstat = d3.group(orderedData, (d) => d.series)

        // x axis
        const maxDate = xExtent[1]
        const ticks = []
        const fullLength = Math.floor((maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24))
        const nthTick = Math.ceil(fullLength / 6)

        for (let i = 0; i < fullLength; i += nthTick) {
          var result = new Date(minDate)
          result.setDate(result.getDate() + i)
          ticks.push(result)
        }

        const xAxis = d3
          .axisBottom()
          .scale(xScale)
          .tickValues(ticks)
          .tickSizeInner(0)
          .tickSizeOuter(-1 * (height - margin.top))
          .tickPadding(18)
          .tickFormat(d3.utcFormat("%b '%y"))

        svg.select('.x-axis')
          .attr('transform', `translate(${margin.left}, ${height - margin.bottom + margin.top})`)
          .call(xAxis)

        d3.select('.x-axis .tick:first-of-type').remove()

        d3.selectAll('.x-axis .tick line').attr('y2', -(height - margin.bottom))

        // y axis
        const yAxis = d3
          .axisLeft()
          .scale(yScale)
          .ticks(5)
          .tickSizeInner(0)
          .tickSizeOuter(-1 * (width - margin.left))
          .tickPadding(18)
          .tickFormat((d) => `${prefix ? prefix + ' ' : ''}${d3.format(',.0f')(d)}${suffix ? ' ' + suffix : ''}`)

        d3.select('.y-axis').attr('transform', `translate(${margin.left}, ${margin.top})`).call(yAxis)
        d3.select('.y-axis .tick:last-child line').remove()

        d3.selectAll('.y-axis .tick line').attr('x2', width)

        // draw tooltip hover
        const focus = svg.selectAll('.tooltip-line').style('opacity', 0)
        const focusText = d3.select(graphContainerRef.current).selectAll('.tooltip-container').style('opacity', 0)

        // draw series lines
        const lines = svg
          .select('.plot-area')
          .attr('transform', `translate(${margin.left}, ${margin.top})`)
          // .attr('width', width - margin.left)
          .selectAll('path')
          .data(sumstat)
          .join('path')
          .classed('disabled', (d) => selectedIdentifiers.findIndex((o) => o.toUpperCase() === d[0]) < 0)
          .attr('fill', 'none')
          .attr('stroke-width', (d) => data.find((o) => o.key === d[0])?.lineSize)
          .attr('stroke', (d) => data.find((o) => o.key === d[0])?.colour || 'white')
          // .attr('stroke', 'white')
          .attr('d', (d) => {
            return d3
              .line()
              .x((d) => xScale(d.x))
              .y((d) => yScale(d.y))(d[1])
          })

        // animate series
        const nodes = lines.nodes()
        for (let index = 0; index < nodes.length; index++) {
          const element = nodes[index]

          const totalLength = element.getTotalLength()
          d3.select(element)
            .attr('stroke-dasharray', totalLength + ' ' + totalLength)
            .attr('stroke-dashoffset', totalLength)
            .transition()
            .duration(1500)
            .ease(d3.easeLinear)
            .attr('stroke-dashoffset', 0)
        }

        // mouse tooltip positioning
        svg.selectAll('.hover-container').remove()

        svg.append('rect')
          .classed('hover-container', true)
          .style('fill', 'none')
          .attr('transform', `translate(${margin.left}, 0)`)
          .style('pointer-events', 'all')
          .attr('width', width - margin.left)
          .attr('height', height)
          .on('mousemove', mousemove)
          .on('mouseout', mouseout)

        function mousemove(ev) {
          // recover coordinate we need
          const pointerPosition = d3.pointer(ev)
          var x0 = xScale.invert(pointerPosition[0])
          const dataPointIndex = d3.bisectLeft(
            orderedData.filter((o) => o.series === baseSeries.key).map((o) => o.x),
            x0
          )

          var selectedData =
            orderedData[dataPointIndex]

          const tooltipX = xScale(selectedData.x) + margin.left
          const tooltipY = yScale(selectedData.y) + margin.top
          focus.attr('transform', `translate(${tooltipX}, 0)`) //.attr('cy', yScale(selectedData.y))

          const xPosition = tooltipX / (width - margin.left)
          const boundingBox = svg.node().getBoundingClientRect()
          const ratio = boundingBox.width / width
          const leftMargin = margin.left * ratio
          const focusBoundingBox = focusText.node().getBoundingClientRect()

          const tooltipPosition = {
            x: (boundingBox.width - leftMargin) * xPosition,
            y: tooltipY * ratio,
          }

          focus.style('opacity', 1)
          focusText.style('opacity', 1)
          focusText.style('left', (tooltipPosition.x > boundingBox.width - focusBoundingBox.width ? tooltipPosition.x - focusBoundingBox.width : tooltipPosition.x) + 'px').style('top', tooltipPosition.y + 'px')

          for (let index = 0; index < data.length; index++) {
            const serie = data[index];
            const yValue = parseFloat(parseFloat(serie.dataY[dataPointIndex]).toFixed(2)).toLocaleString('en-US')
            // focusText.select(`.tooltip-${serie.key}`).style('background', serie.colour)
            focusText.select(`.tooltip-${serie.key} .tooltip-x`).html(d3.utcFormat('%Y-%m-%d')(selectedData.x))
            focusText.select(`.tooltip-${serie.key} .tooltip-y`).html(`${prefix}${yValue}${suffix}`)
          }

        }

        function mouseout() {
          focus.style('opacity', 0)
          focusText.style('opacity', 0)
        }
      }
    },
    [data, selectedIdentifiers]
  )

  return (
    <div>
      <h5 className='graph-header label'>{title}</h5>
      {label}
      <div ref={graphContainerRef} className='line-graph-container series'>
        <svg ref={ref} className='line-graph' viewBox={`0 0 ${width} ${height}`}
             preserveAspectRatio='xMinYMin meet'>
          <g>
            <rect width={width - margin.left} height={height - margin.bottom} x={margin.left}
                  fillOpacity={0} y={margin.top} stroke={'white'} opacity={0.5}/>
          </g>
          <g className='x-axis'>
            <line x1={0} x2={width - margin.left} y1={0} y2={0} strokeWidth={1} stroke={'white'}/>
          </g>
          <g className='y-axis'/>
          <g className='plot-area'/>
          <g className='tooltip-line'>
            <line x1={0} x2={0} strokeWidth={1} y1={margin.top} y2={height + margin.top - margin.bottom}
                  stroke={'#fff'}/>
          </g>
        </svg>
        <div className='tooltip-container'>
          {data && data.length > 0 && data.filter(o => selectedIdentifiers.indexOf(o.key) >= 0).map((o) => (
            <div key={o.key} className={`tooltip tooltip-${o.key}`}>
              <div className='tooltip-x'/>
              <div className='tooltip-y'/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PerformanceGraph;