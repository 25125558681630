import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiWidgetUrl } from '../../constants';
import { format } from 'd3';

function ETPFeaturedStatement({ blok, number }) {
  const { title, text, key } = blok;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (key) {
      axios(`${apiWidgetUrl}&names=${key}`)
        .then(data => {
          const num = Number(data.data[0].sections[0].meta[1].value);
          setValue(`$${format('.3s')(num).replace('G', 'bn')}`)
        });
    }
  }, [key]);

  return (
    <div className={'etp-featured-statement'}>
      <div className={'etp-featured-statement-number'}>{number.toString().padStart(2, '0')}</div>
      <h3 className='h4'>{title}</h3>
      <p className={'etp-featured-statement-title'}>
        {text.replace('{}', value)}
      </p>
    </div>
  );
}

export default ETPFeaturedStatement;