import { gql, useQuery } from '@apollo/client';
import { startsWith } from '../../utils';
import NewOfferingCard from './NewOfferingCard';
import NewOfferingLarge from './NewOfferingLarge';
import NewOfferingSection from './NewOfferingSection';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiWidgetUrl } from '../../constants';
import { format } from 'd3';

const XBT_AUM = 'XBT_AUM';
const PHYSICAL_AUM = 'PHYSICAL_AUM';
const INDEX_AUM = 'INDEX_AUM';
const CAPITAL_MARKETS = 'CAPITAL_MARKETS';

function NewOfferingSummary({ blok, language }) {
  const { sections, indent, bottom_border } = blok;
  const [offeringAmounts, setOfferingAmounts] = useState({});
  // const { data: CardsContent } = useQuery(query, { variables: { uuid: (Cards || []).join(',') } });

  useEffect(() => {
    axios(`${apiWidgetUrl}&names=${XBT_AUM},${PHYSICAL_AUM},${INDEX_AUM},${CAPITAL_MARKETS}`)
      .then(({ data }) => {
        const xbtMeta = data.find(({ key }) => key === XBT_AUM)?.sections.find(({ key }) => key === XBT_AUM)?.meta;
        const physicalMeta = data.find(({ key }) => key === PHYSICAL_AUM)?.sections.find(({ key }) => key === PHYSICAL_AUM)?.meta;
        const indexMeta = data.find(({ key }) => key === INDEX_AUM)?.sections.find(({ key }) => key === INDEX_AUM)?.meta;
        const capitalMeta = data.find(({ key }) => key === CAPITAL_MARKETS)?.sections.find(({ key }) => key === CAPITAL_MARKETS)?.meta;

        const xbt = xbtMeta?.find((o) => o.key === 'MarketCap');
        const physical = physicalMeta?.find((o) => o.key === 'MarketCap');
        const index = indexMeta?.find((o) => o.key === 'MarketCap');
        const capital = capitalMeta?.find((o) => o.key === 'notionalUsd');

        setOfferingAmounts({
          [XBT_AUM]: { value: format(',.0f')(xbt?.value), suffix: xbt?.suffix },
          [PHYSICAL_AUM]: { value: format(',.0f')(physical?.value), suffix: physical?.suffix },
          [INDEX_AUM]: { value: index?.value, suffix: index?.suffix },
          [CAPITAL_MARKETS]: { value: capital?.value, suffix: capital?.suffix },
        });
      });
  }, []);

  return (
    <div className={`widget white new-offering-summary ${bottom_border ? 'bottom-border' : ''}`}>
      <div className={`container ${indent ? `new-offering-summary-${indent}-indent` : ''}`}>
        {(
          sections?.map(section => {
            if (section.component === 'new_offering_large') {
              return <NewOfferingLarge
                key={section._uid}
                title={section.title}
                text={section.text}
                linkTitle={section.link_title}
                link={section.link.cached_url}
              />
            }

            return <NewOfferingSection
              key={section._uid}
              offeringAmounts={offeringAmounts}
              title={section.title}
              cards={section.cards}
              isEtp={section.etp_cards}
              show_descriptions={section.show_descriptions}
              full_page_card={section.full_page_card}
              smaller_bottom_margin={section.smaller_bottom_margin}
              hide_bottom_text={section.hide_bottom_text}
            />
          })
        )}
      </div>
    </div>
  );
}

export default NewOfferingSummary;