import { gql, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DirectionalHint, Dropdown, Label, Modal } from '@fluentui/react';
import { Radio, RadioGroup } from '@fluentui/react-radio';
import { cookieNames, getCookie, otherOption, setCookie, setLanguage, getLanguage } from '../../utils';
//import { cookieNames, getCookie, otherOption, setCookie, setLanguage, getLanguage, isETPSubdomain } from '../../utils';
import ETPButton from '../ETPButton';
import { useLocation, useNavigate } from 'react-router-dom';
import useForm from '../../hooks/useForm'

const defaultFormValue = {
  investorType: 'individual',
  country: '',
}

export function PopupNotice({ blok, language }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useQuery(query, { variables: { language: language !== 'en' ? `${language}/*` : undefined } });
  const { CountryItems, InvestortypeItems, LegalnoticeItems, LanguageItems } = data || {};
  const [showPopup, setShowPopup] = useState(false);

  const [countries, investorTypes, legalNotices] = useMemo(() => {
    return [CountryItems?.items || [], InvestortypeItems?.items || [], LegalnoticeItems?.items || []];
  }, [CountryItems, InvestortypeItems, LegalnoticeItems]);

  const handleSubmit = useCallback((formValues) => {
    const searchParams = new URLSearchParams(location.search);
    setCookie(cookieNames.INVESTOR_TYPE, formValues.investorType, blok.cookie_expiration_days);
    setCookie(cookieNames.LOCATION, formValues.country, blok.cookie_expiration_days);
    const allLanguageCodes = LanguageItems.items.map(({ content }) => content.code);
    const firstSubdirectory = location.pathname.split('/')[1];
    const pathnameHasLanguage = allLanguageCodes.includes(firstSubdirectory);
    setShowPopup(false);
    window.dataLayer.push({
      'event': 'investor_characteristics_completed',
      'investor_type': formValues.investorType,
      'location': formValues.country,
    });
    if (formValues.country !== otherOption.key) {
      const selectedCountryLanguageSlug = CountryItems.items.find(c => c.slug === formValues.country).content.language.slug;
      const languageCode = LanguageItems.items.find(item => item.slug === selectedCountryLanguageSlug)?.content?.code;
      setLanguage(languageCode);
    } else {
      setLanguage('en');
    }

//    if (isETPSubdomain()) {
//      setLanguage('en');
//    }
//
    let language=getLanguage();
    if (pathnameHasLanguage) {
      const pathWithoutLanguage = location.pathname.split('/').slice(2).join('/');
      if (language === 'en') {
//      if (language === 'en' || isETPSubdomain()) {
        navigate(`${pathWithoutLanguage}/?${searchParams.toString()}`);
      } else {
        navigate(`${language}/${pathWithoutLanguage}?${searchParams.toString()}`.replace('/?', '?'));
      }
    } else {
      navigate(`${language}${location.pathname}?${searchParams.toString()}`.replace('/?', '?'));
    }
    window.location.reload();
  }, [LanguageItems])

  const contactValidator = {
    investorType: {
      required: true,
      errorMessage: blok.investor_error,

    },
    country: {
      required: true,
      errorMessage: blok.country_error,
    },
  }

  const { formState, handleOnChange, handleOnSubmit } = useForm(defaultFormValue, contactValidator, handleSubmit)

  useEffect(() => {
    if (!getCookie(cookieNames.LOCATION) || !getCookie(cookieNames.INVESTOR_TYPE)) {
      setShowPopup(true);
    }
  }, []);

  const legalNotice = useMemo(() => {
    if (!formState.country.value || !formState.investorType.value) return undefined;

    return legalNotices.find((o) => o.slug === `${formState.investorType.value}-${formState.country.value}`);
  }, [formState.country.value, formState.investorType.value, legalNotices]);

  return (
    <Modal
      isOpen={showPopup}
      onDismiss={(ev) => {
        if (ev.target.classList.indexOf('ms-Overlay') >= 0) {
          ev.preventDefault();
          setShowPopup(false);
        }
      }}
      containerClassName='popup-notice black'
    >
      <div className='modal-header'>
        <h2 className='modal-header'>{blok.title}</h2>
      </div>
      <div className='col-12'>
        <p className='modal-description'>{blok.description}</p>
      </div>
      <div className='column'>
        <Label className='modal-radio-label' id='investor-type' required>{blok.investor_type_header}</Label>
        <RadioGroup
          defaultValue="individual"
          onChange={(ev, option) => {
            if (handleOnChange) handleOnChange('investorType', option.value)
          }}
          className='modal-radio-group'
          layout='horizontal'
          aria-labelledby='investor-type'
          name="investorType"
        >
          {investorTypes.map((c, i) => <Radio key={i} className='modal-radio' value={c.slug} label={c.name} />)}
        </RadioGroup>
        {formState.investorType.error && <span className='error'>{formState.investorType.error}</span>}
      </div>
      <div className='column'>
        <Dropdown
          calloutProps={{
            directionalHint: DirectionalHint.bottomLeftEdge, directionalHintFixed: true,
          }}
          label={blok.location_header}
          placeholder={blok?.country_placeholder}
          options={[...countries.map((c) => ({ key: c.slug, text: c.name, })), otherOption]}
          size='small'
          onChange={(ev, option) => {
            if (handleOnChange) handleOnChange('country', option.key)
          }}
          name="country"
          type='text'
        />
        {formState.country.error && <span className='error'>{formState.country.error}</span>}
      </div>
      <div>
        <p className='notice-disclaimer'>
          <span>{blok.confirmation_text}&nbsp;</span>
          <span>
            <a href={`/${!!legalNotice ? legalNotice.full_slug : ''}`} target='_blank' rel='noreferrer'>
              {blok.confirmation_link_name}
            </a>
          </span>
        </p>
      </div>
      <div className='d-flex justify-space-between align-items-center'>
        <div className='me-3'>
          {!legalNotice && formState.country.value && formState.investorType.value && (
            <div className='missing-notice'>
              <span>{blok.missing_notice_text}</span>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-end'>
          <ETPButton
            blok={{ onClick: handleOnSubmit, text: blok.confirm_button_title }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default PopupNotice;

const query = gql`
  query {
    CountryItems(sort_by: "name:asc") {
      items {
        uuid
        name
        slug
        content {
          language {
            slug
          }
        }
      }
      total
    }

    LanguageItems {
      items {
        uuid
        slug
        content {
          code
        }
      }
    }

    InvestortypeItems {
      items {
        uuid
        name
        slug
      }
      total
    }

    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;