import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ETPCompanySquare({ title, linkUrl, logoUrl }) {

  return (
    <a
      onClick={() => window?.dataLayer.push({ event: 'select_broker', broker_name: title })}
      href={linkUrl}
      className={'etp-company-square'}
      target='_blank'
    >
      <FontAwesomeIcon icon={'arrow-up-right-from-square'} className={'etp-company-square-link-icon'}/>
      <img src={logoUrl} alt={title}/>
    </a>
  );
}

export default ETPCompanySquare;

