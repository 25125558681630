import { useQuery } from '@apollo/client';
import { Dropdown } from '@fluentui/react';
import { useMemo, useState, useRef } from 'react';
import { articlesQuery, knowledgeCategoryQuery } from '../../queries';
import { startsWith } from '../../utils';
import { Article } from '../ETPArticles';
import ETPPaginationControls from '../ETPPaginationControls';
import { useLocation } from 'react-router-dom';

const perPage = 11;

const allOption = { key: 'all', text: 'All' };

function ETPArticlesSearch({ blok, language }) {
  const { title, categories_label, categories_placeholder, search_label } = blok;
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubCategory] = useState();
  const [search, setSearch] = useState('');
  const knowledgeResponse = useQuery(knowledgeCategoryQuery, { variables: { startsWith: startsWith(language) } });
  const categories = knowledgeResponse?.data?.KnowledgecategoryItems?.items;
  const filterQuery = createFilterQuery(search, selectedSubcategory);
  const pathnameCategory = categories?.find(c => location.pathname.split('/').includes(c.full_slug.split('/').filter(x => x).reverse()[0]));
  const isCategoryPage = !!pathnameCategory;

  const subCategoryDropdownOptions = useMemo(() => {
    const options = pathnameCategory?.content?.subcategories
      ?.map(({ uuid, name }) => ({ key: uuid, text: name }))
      ?.sort((x, y) => x.text > y.text ? 1 : -1);
    options?.unshift(allOption);
    return options;
  }, [knowledgeResponse]);

  const categoryDropdownOptions = useMemo(() => {
    const options = categories?.map(({ full_slug, content }) => ({ key: full_slug, text: content.name }));
    options?.unshift(allOption);
    return options;
  }, [knowledgeResponse]);

  const articlesResponse = useQuery(
    articlesQuery({
      language,
      page: page + 1,
      perPage,
      filterQuery,
      selectedCategory: isCategoryPage ?
        pathnameCategory?.full_slug :
        (selectedCategory !== allOption.key ? selectedCategory : undefined)
    }),
    { variables: { filterQuery, selectedCategory } }
  );
  const articlesTotal = articlesResponse.data?.ArticleItems?.total ?? 0;

  function handleDropdownChanged(_, { key }) {
    setPage(0);
    if (isCategoryPage) {
      setSelectedSubCategory(key);
    } else {
      setSelectedCategory(key);
    }
  }

  const productContainer = useRef(null);

  const changePage = (newPage) => {
    productContainer.current.scrollIntoView({behavior: 'smooth' });

    setTimeout(() => {
      setPage(newPage);
    }, 600);
  };

  function handleSearchChanged(e) {
    setPage(0);
    setSearch(e.target.value);
  }

  return (
    <div className='widget white' ref={productContainer}>
      <div className='container'>
        <div className='row'><h4 className='etp-articles-search-title'>{title} ({articlesTotal})</h4></div>
        <div className='row'>
          <div className='etp-form etp-articles-search-filters'>
            <div className='etp-input-group'>
              <label htmlFor='categories'>{categories_label}</label>
              <Dropdown
                id='categories'
                options={isCategoryPage ? subCategoryDropdownOptions : categoryDropdownOptions}
                placeholder={categories_placeholder}
                selectedKey={isCategoryPage ? selectedSubcategory : selectedSubcategory}
                defaultSelectedKey={allOption.key}
                onChange={handleDropdownChanged}
              />
            </div>
            <div className='etp-input-group'>
              <label htmlFor='search'>{search_label}</label>
              <input id='search' value={search} onChange={handleSearchChanged}/>
            </div>
          </div>
        </div>
        <div className='row etp-articles'>
          {articlesResponse.data?.ArticleItems?.items?.map?.((article, index) => (
            <Article article={article} isFeatured={index === 0} key={article.uuid}/>
          ))}
        </div>
        <div className='row'>
          {!!articlesTotal && articlesTotal > perPage &&
            <ETPPaginationControls onClick={changePage} pages={Math.ceil(articlesTotal / perPage)} page={page}/>}
        </div>
      </div>
    </div>
  );
}

export default ETPArticlesSearch;

function createFilterQuery(search, selectedSubcategory) {
  const searchFilter = search && `title: { like: "*${search}*" }`;
  const subcategoryFilter = selectedSubcategory &&
    selectedSubcategory !== allOption.key &&
    `etp_subcategory: { in: "${selectedSubcategory}" }`;
  const filters = [searchFilter, subcategoryFilter].filter(x => x).join(',');

  return `{${filters}}`;
}
