import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const CardSet = ({ blok }) => {
	const { anchor_key, background, title, cards } = blok

	return (
		<div className={background} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container card-set carousel layout_5'>
				<div className='row'>
					<div className='col-lg-5 col-12'>
						<h2 className='content title'>{title}</h2>
					</div>

					<div className='col-12'>
						<div className='row'>
							<div className='carousel-cards horizontal'>
								{cards.map((blok, index) => (
									<StoryblokComponent blok={blok} key={blok._uid} index={index} count={cards.length} layout='layout_5' />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CardSet
