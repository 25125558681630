import { gql, useQuery } from '@apollo/client';
import { cookieNames, getCookie } from '../../utils';

function LogoList({ blok, language }) {
  const { text, type } = blok;

  if (type === 'media') {
    return <MediaList text={text} language={language} />;
  }
  if (type === 'exchanges') {
    return <ExchangesList text={text} language={language} />;
  }

  console.warn(`No recognised type was supplied to LogoList: ${type}`);
  return null;
}

function ExchangesList({ text, language }) {
  const location = getCookie(cookieNames.LOCATION);
  const { data } = useQuery(
    exchangesQuery({ country: location.charAt(0).toUpperCase() + location.slice(1) }),
    { variables: { language: language !== 'en' ? `${language}/*` : undefined } }
  );
  const { ExchangeItems } = data ?? {};
  const exchanges = ExchangeItems?.items?.map(({ content }) => ({
    title: content.title,
    src: content.logo.filename
  })) ?? [];

  return <BasicLogoList text={text} logos={exchanges}/>;
}

function MediaList({ text, language }) {
  const location = getCookie(cookieNames.LOCATION);
  const { data } = useQuery(
    mediaQuery,
    { variables: { language: language !== 'en' ? `${language}/*` : undefined } }
  );

  const logos = (data?.MediaItems?.items ?? [])
    .filter(({content}) => content.global || content.countries.map(({ slug }) => slug).includes(location))
    .map(({ content }) => ({ title: content.title, src: content.logo.filename }));

  return <BasicLogoList text={text} logos={logos}/>;
}

export function BasicLogoList({ text, logos }) {
  return (
    <div className={'row logo-list'}>
      <div className={'col-lg-3'}>
        <div className={'logo-list-text'}>{text}</div>
      </div>
      <div className={'col-lg-9 logo-list-logos'}>
        {logos?.map(logo => <img src={logo.src} alt={logo.title} height={'30px'} key={logo.title}/>)}
      </div>
    </div>
  );
}


export default LogoList;

const exchangesQuery = ({ country }) => gql`
  query ($language: String) {
    ExchangeItems(starts_with: $language, filter_query: {country: {in: "${country},Global"} }) {
      items {
        uuid
        content {
          title
          logo {
            filename
          }
          country
        }
      }
    }
  }
`;

const mediaQuery = gql`
  query ($language: String) {
    MediaItems(starts_with: $language) {
      items {
        uuid
        content {
          global
          countries {
            slug
            name
          }
          title
          logo {
            filename
          }
        }
      }
    }
  }
`;
