import { StoryblokComponent } from '@storyblok/react';
import languages from '../../constants/languages.json';
import ETPLink from "../ETPLink";

function ETPVideo({ blok }) {
  const { title, text, video, image, featured_statements, link, link_text} = blok;

  const hideVideo =
    !video?.cached_url ||
    !!(video.cached_url.split('/').filter(x => x).length === 1 &&
      languages.find(o => o[video.cached_url.split('/').filter(x => x)[0]]));

  return (
    <div className={'widget white etp-video'}>
      <div className={'container'}>
        <div className={'row etp-video-row'}>
          <div className={hideVideo && !image?.filename ? undefined : 'col-lg-6'}>
            <div className={'pe-5'}>
              <h2 className={'mb-4'}>{title}</h2>
              <p className='h5'>{text}</p>
              {link?.cached_url && <ETPLink className="etp-video-link" to={link.cached_url}>{link_text}</ETPLink>}
            </div>
          </div>
          {hideVideo || (
            <div className={'col-lg-6'}>
              <div className={'etp-video-wrapper'}>
                <iframe
                  src={video.cached_url}
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  style={{ border: 'none' }}
                />
              </div>
            </div>
          )}
          {
            image?.filename && (
              <div className={'col-lg-6'}>
                <div className={'etp-image-wrapper'}>
                  <img className='w-100' src={image.filename} alt={image.alt} />
                </div>
              </div>
            )
          }
        </div>
        {featured_statements?.map(fs => (
          <div className={'row'} key={fs._uid}><StoryblokComponent blok={fs} id={fs._uid}/></div>
        ))}
      </div>
    </div>
  );
}

export default ETPVideo;