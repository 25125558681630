import React, { useEffect, useState } from 'react'
import { TabStrip } from '../TabStrip'
import orderBy from 'lodash.orderby'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Button } from '../Button'
import logAction from '../../actions'

export const DocumentList = ({ blok }) => {
	const { documents, folder_path, number_columns, title, annual_reports, custom_quarters = [] } = blok

	const [folders, setFolders] = useState()
	const [assets, setAssets] = useState()

	useEffect(() => {
		if (folder_path) {
			const path = folder_path.slice(1)
			fetch(`${process.env.REACT_APP_API_URI}/storybloks/folders/assets?ApiKey=${process.env.REACT_APP_API_KEY}&path=${encodeURIComponent(path)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((value) => {
				value.json().then((value) => {
					if (value && value !== null && value.folders.assets[0] !== null) {
						setFolders(orderBy(value.folders.assets[0].folders, ['name'], ['desc']))
						setAssets(
							value.folders.assets[0].assets.map((o) => {
								return {
									...o,
									order: o.tagList.find((t) => t.name.indexOf('sort') >= 0)?.name.split(':')[1] || 9999,
								}
							})
						)
					}
				})
			})
		}
	}, [folder_path])

	return (
		<div className='document-list' {...storyblokEditable(blok)}>
			{title && <h5>{title}</h5>}

			{folders && folders.length > 0 && (
				<TabStrip
					blok={{
						tabs: folders.map((o) => {
							return {
								_uid: o.key.toString(),
								component: 'tab',
								title: o.name,
								content: (
									<>
										{o.assets && (
											<div className='row' style={{ paddingBottom: 30 }}>
												<div className='col-lg-4 col-12'>
													{orderBy(
														o.assets.map((o) => {
															return {
																...o,
																order: o.tagList.find((t) => t.name.indexOf('sort') >= 0)?.name.split(':')[1] || 9999,
															}
														}),
														['order', 'title'],
														['asc', 'asc']
													).map(({ filename, id, title, alt }) => (
														<a key={id} href={filename} className='document bottom' target='_blank' alt={alt} rel='noreferrer'  onClick={() => logAction('download', { link: filename })}>
															<span>{title || 'No title specified'}</span>
															<img src='/images/ChevronRightBranded.svg' alt='download' />
														</a>
													))}
												</div>
											</div>
										)}
										<div className='row'>
											<div className='document-content'>
												{annual_reports && annual_reports.find((ar) => ar.year === o.name) && (
													<div className='document-content-additional'>
														{annual_reports
															.find((ar) => ar.year === o.name)
															.annual_report.map((ar) => (
																<StoryblokComponent key={ar._uid} blok={ar} />
															))}

														{
															<Button blok={{ text: 'Annual Report Summary', link: annual_reports.find((ar) => ar.year === o.name).annual_report_summary, style: '', type: '' }}>
																Annual Report Summary
															</Button>
														}
													</div>
												)}
												<div className='document-content-main row'>
													{o.folders !== null &&
														orderBy(o.folders, ['name'], ['asc']).map((folder) => {
															const customQuarter = custom_quarters.find((cq) => cq.year === o.name && cq.title === folder.name)

															return (
																<div key={folder.key} className='col-lg-3 col-12 documents'>
																	<h5 className='py-0'>{folder.name}</h5>
																	{folder.assets.length === 0 && customQuarter && <StoryblokComponent blok={customQuarter} />}

																	{orderBy(
																		folder.assets.map((o) => {
																			return {
																				...o,
																				order: o.tagList.find((t) => t.name.indexOf('sort') >= 0)?.name.split(':')[1] || 9999,
																			}
																		}),
																		['order', 'title'],
																		['asc', 'asc']
																	).map(({ filename, id, title, alt }) => (
																		<a key={id} href={filename} className='document bottom' target='_blank' alt={alt} rel='noreferrer'  onClick={() => logAction('download', { link: filename })}>
																			<span>{title || 'No title specified'}</span>
																			<img src='/images/ChevronRightBranded.svg' alt='download' />
																		</a>
																	))}
																</div>
															)
														})}
												</div>
											</div>
										</div>
									</>
								),
							}
						}),
					}}
				/>
			)}

			{folder_path && assets && (
				<div className='row'>
					{orderBy(assets, ['title'], ['asc']).map(({ filename, id, title, alt }) => (
						<div key={id} className={number_columns ? 'col-12 col-lg-' + 12 / number_columns : 'col-12'}>
							<a href={filename} className='document' target='_blank' alt={alt} rel='noreferrer' onClick={() => logAction('download', { link: filename })}>
								<span>{title || 'No title specified'}</span> <img src='/images/DownloadIconLight.svg' alt='download' />
							</a>
						</div>
					))}
				</div>
			)}

			{!folder_path && (
				<div className='row'>
					{documents.map(({ filename, id, title, alt }) => (
						<div key={id} className={number_columns ? 'col-12 col-lg-' + 12 / number_columns : 'col-12'}>
							<a href={filename} className='document' target='_blank' alt={alt} rel='noreferrer' onClick={() => logAction('download', { link: filename })}>
								<span>{title || 'No title specified'}</span> <img src='/images/DownloadIconLight.svg' alt='download' />
							</a>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default DocumentList
