import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logAction } from '../../../actions';
import { formatDate, getLanguage } from '../../../utils';

const opt = '/m/';

export const Article = ({ article, list_view, restrainHeight, etpExclude }) => {
  const { img, datePublished, author, title, content, tags, full_slug, articleTypeName } = article;
  const location = useLocation();

  const date = formatDate(datePublished) ?? datePublished;
  const language = getLanguage() || 'en'

  return (
    <Link
      className={'article' + (restrainHeight ? ' restrain' : '')}
      to={etpExclude ? language !== 'en' ?  `${language}/${full_slug}` : full_slug : full_slug}
      onClick={() => logAction('navigation', { from: location.pathname, to: full_slug || '/' })}
    >
      {img && (
        <div className='article-img'>
          <img src={`${img}${opt}`} alt='article' />
        </div>
      )}
      {(date || author) && (
        <div className='article-meta'>
          <span className='article-date label uppercase'>{date}</span>
          {author && !list_view && <span className='article-author'>{author}</span>}
        </div>
      )}

      <h5 className='title mb-2'>
        <div className='article-title-header'>Related article</div>
        <span>{title}</span>
        {content && <div className='article-content'><span>{content}</span></div>}
      </h5>
      <div className='article-tags'>
        {tags &&
          tags.map((tag, index) => (
            <span className='tag tag--white' key={`${tag}${index}`}>
              {tag}
            </span>
          ))}
      </div>
      {list_view && <div className='article-type'>{articleTypeName}</div>}
    </Link>
  );
};

export default Article;
