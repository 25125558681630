import { gql, useQuery } from "@apollo/client";
import { Callout } from "@fluentui/react";
import { useLocation } from 'react-router-dom';
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React, { useState } from "react";
import { etpNewSlug, etpSubdomain } from "../../constants";
import gear from "../../icons/gear.svg";
import "./Navigation.scss";
import {
  clearCookies,
  cookieNames,
  getCookie,
  setLanguage,
} from "../../utils";

function Navigation({
  blok,
  slug,
  language,
  logo,
  onCloseMenu,
  nav,
  className,
}) {
  const location = useLocation();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [selectedNavigation, setSelectedNavigation] = useState("");

  const isETPDomain = window?.location.host.split(".").includes(etpSubdomain);

  function handleSelectorOpen() {
    setIsLanguageOpen((isOpen) => !isOpen);
  }

  function handleSelectLanguage(option) {
    setLanguage(option);
    let temp = `${slug}/`;
    let route = temp.replace(`${etpNewSlug}/`, "").replace("home", "");
    window.location =
      "/" +
      [option, route]
        .filter((f) => f !== "" && f !== "en" && f !== "/")
        .join("/")
        .replace(/\/$/, "") +
      location.search;
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={`navigation ${className || ""}`}
    >
      {logo && onCloseMenu && (
        <div className="navigation-header">
          <div className="logo">
            {logo.map((blok) => (
              <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
          </div>
          <div
            className="navigation-menu-close"
            onClick={() => {
              if (onCloseMenu) {
                setSelectedNavigation("");
                onCloseMenu();
              }
            }}
          />
        </div>
      )}
      {blok.items.map((blok) => (
        <StoryblokComponent
          blok={blok}
          key={blok._uid}
          nav={nav}
          onSelectSubNavigation={(selectedNav) =>
            setSelectedNavigation(selectedNav)
          }
          selectedNavigation={selectedNavigation}
        />
      ))}
      <div className="etp-navigation-end">
        <LanguageSelector
          language={language}
          isLanguageOpen={isLanguageOpen}
          handleSelectLanguage={handleSelectLanguage}
          handleSelectorOpen={handleSelectorOpen}
          isETPDomain={isETPDomain}
          language_and_investor_modal={
            blok?.language_and_investor_modal.length &&
            blok?.language_and_investor_modal[0]
          }
        />
        <div>
          {isETPDomain &&
            blok?.actionBtn?.map((blok) => (
              <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Navigation;

function LanguageSelector({
  handleSelectorOpen,
  isLanguageOpen,
  handleSelectLanguage,
  isETPDomain,
  language,
  language_and_investor_modal,
}) {
  const {site_language, type, location: locationText, investor_info}=language_and_investor_modal;
  const { data } = useQuery(query);
  const location = data?.CountryItems?.items?.find(
    (country) => country.slug === getCookie(cookieNames.LOCATION)
  );
  const selectedLanguage = data?.LanguageItems?.items?.find(
    (value) => value.content.code === language
  )?.content;

  function clearSettings(e) {
    e.preventDefault();
    clearCookies();
    window.location.reload();
  }

  return (
    <div className="language-selector-container">
      <div
        id="language-button"
        className="language-selector"
        onClick={handleSelectorOpen}
      >
        {selectedLanguage && (
          <img
            src={selectedLanguage?.icon?.filename}
            className="language-icon"
            alt="language"
            height="20px"
          />
        )}
        <span>{selectedLanguage?.language_short}</span>
      </div>
      {isLanguageOpen && (
        <Callout
          target="#language-button"
          style={{ borderRadius: "0.5rem" }}
          gapSpace={12}
          isBeakVisible={false}
          onDismiss={handleSelectorOpen}
        >
          <div
            className="lang-selector-container d-flex flex-column"
            style={{ gap: "1.25rem" }}
          >
            <span className="section-title">{site_language}</span>
            <div className="d-flex flex-column align-items-start">
              {data?.LanguageItems?.items
                ?.map(({ content }) => content)
                //                ?.filter(content => !isETPDomain || content.code === 'en')
                ?.sort((x, y) =>
                  x.code === "en" ? -1 : x.code > y.code ? 1 : -1
                )
                ?.map((content) => (
                  <div
                    className={`${
                      content.code === language ? "lang--active" : ""
                    } lang-item`}
                    key={content.code}
                    onClick={() => handleSelectLanguage(content.code)}
                  >
                    <img
                      src={content.icon.filename}
                      alt={content.language_short}
                    />
                    <span>{content.language}</span>
                  </div>
                ))}
            </div>
            {isETPDomain && (
              <div
                className={`d-flex flex-column etp-settings-container`}
                style={{ gap: "1px" }}
              >
                <div
                  className={`etp-settings-item etp-settings-sub-item border-top-item d-flex justify-space-between`}
                >
                  <span className={"etp-settings-header"}>{investor_info}</span>
                  <img
                    onClick={clearSettings}
                    src={gear}
                    alt="gear"
                    className={`cursor-pointer`}
                  />
                </div>
                <div
                  className={`etp-settings-item border-bottom-item d-flex flex-column justify-space-between`}
                >
                  <div
                    className={`etp-settings-sub-item d-flex justify-space-between`}
                  >
                    <span className={`etp-settings-key`}>{type}</span>
                    <span className={`etp-settings-value`}>
                      {getCookie(cookieNames.INVESTOR_TYPE)}
                    </span>
                  </div>
                  <div
                    className={`etp-settings-sub-item d-flex justify-space-between`}
                  >
                    <span className={`etp-settings-key`}>{locationText}</span>
                    <span
                      className={`d-flex align-items-center`}
                      style={{ gap: `0.25rem` }}
                    >
                      {!location ? (
                        <span className={`etp-settings-value`}>Other</span>
                      ) : (
                        <>
                          <img
                            width="12px"
                            height="12px"
                            src={location?.content?.icon?.filename}
                            alt={location?.content?.icon?.alt}
                          />
                          <span className={`etp-settings-value`}>
                            {location?.name}
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Callout>
      )}
    </div>
  );
}

const query = gql`
  {
    CountryItems {
      items {
        uuid
        name
        slug
        content {
          title
          icon {
            filename
            alt
          }
        }
      }
    }
    LanguageItems {
      items {
        content {
          language
          language_short
          code
          icon {
            filename
          }
        }
        uuid
        full_slug
      }
    }
  }
`;
