import React from 'react'
import { StoryblokComponent } from '@storyblok/react'

export const Container = ({ blok }) => {
	const { anchor_key,  background = 'white', rows } = blok

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div className='widget'>
				<div className='container'>
					{rows.map((o) => (
						<StoryblokComponent key={o._uid} blok={o} />
					))}
				</div>
			</div>
		</div>
	)
}

export default Container
