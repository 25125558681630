import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const Paragraph = ({ blok, content, headerSize, sticky }) => {
	const { anchor_key, header, text, call_to_action } = blok

	return (
		<div {...storyblokEditable(blok)} className={`paragraph ${sticky && 'sticky'}`} id={anchor_key}>
		    {!headerSize && header && <h2 className={content ? 'content' : ''}>{header}</h2>}
            {headerSize === 'h5' && header && <h5>{header}</h5>}
			<p>{text}</p>

			{call_to_action.map((blok) => (
				<StoryblokComponent blok={blok} key={blok._uid} />
			))}
		</div>
	)
}

export default Paragraph
