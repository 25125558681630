import "./ETPBanner.scss"
import world from '../../icons/world.svg';
import {storyblokEditable} from "@storyblok/react";

function ETPBanner({blok}) {
    const {text, count = 3} = blok;
    const countArray = [];

    for (let i = 0; i < count; i++) {
        countArray.push(i);
    }

    const link = window.location.origin.replace('etp.', '');

    return (<div {...storyblokEditable(blok)} className="etp-banner d-flex align-items-center">
        {countArray.map((i) => (
            <a href={link} className="d-flex etp-banner-text slide"
               style={{gap: '0.5rem'}} key={i}>
                <img src={world}></img>
                <p>{text}</p>
            </a>))}
        {countArray.map((i) => (
            <a href={link} className="d-flex etp-banner-text slide-reverse"
               style={{gap: '0.5rem'}} key={count + i}>
                <img src={world}></img>
                <p>{text}</p>
            </a>))}
    </div>);
}

export default ETPBanner;