import React, { useEffect, useState, useRef } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { useD3 } from '../../hooks/useD3'
import orderBy from 'lodash.orderby'
import * as d3 from 'd3'
import { formatDate } from '../Quote'

const height = 500
const width = 500

const margin = { top: 40, right: 40, bottom: 40, left: 40 }

const chartColors = ['#525E75', '#122444', '#FFD964', '#EEAC56', '#9747FF', '#979797', '#0E63DB', '#FF0000', '#7DC7F5', '#D9D9D9']

export const AssetBreakdownGraph = ({ blok, isin, bloomberg, name, language }) => {
	const { anchor_key, background = 'transparent', title } = blok

	const [series, setSeries] = useState()
	const [selectedSeries, setSelectedSeries] = useState()
	const [updated, setUpdated] = useState()

	const graphContainerRef = useRef()
	const ref = useD3(
		(svg) => {
			if (series && graphContainerRef) {
				const data = series

				const radius = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) / 2

				const pie = d3.pie().value((d) => +d.weight)
				const data_ready = pie(data)

				svg.select('.plot-area')
					.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
					.selectAll('path')
					.data(data_ready)
					.join('path')
					.attr(
						'd',
						d3
							.arc()
							.innerRadius(width / 5) // This is the size of the donut hole
							.outerRadius(radius)
					)
					.attr('stroke', 'white')
					.style('stroke-width', '1px')
					.on('mouseenter', (event, d) => setSelectedSeries(d.index))
					.on('mouseout', (event, d) => setSelectedSeries(undefined))
					.transition()
					.attr('fill', (d) => {
						if (selectedSeries === undefined || d.index === selectedSeries) return chartColors[d.index]
						return '#979797'
					})
					.style('opacity', (d) => {
						if (selectedSeries === undefined || d.index === selectedSeries) return 1
						return 0.1
					})
			}
		},
		[series, selectedSeries]
	)

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=ISIN_WEIGHTED_${isin}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === `ISIN_WEIGHTED_${isin}`)
				const sections = widget.sections

				const data = []

				for (let i = 0; i < sections.length; i++) {
					const element = sections[i]
					const item = { key: element.key }

					for (let c = 0; c < element.meta.length; c++) {
						const metaElement = element.meta[c]
						item[metaElement.key] = metaElement.value
					}

					data.push(item)
				}


				setSeries(orderBy(data, ['weight'], ['desc']))
				setUpdated(formatDate(new Date(widget.updated)))
			})
		})
	}, [isin])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container widget'>
				<div className='row'>
					<div className='col'>
						<div className='row'>
							<div className='col-12'>
								<h5 className='label'>{title}</h5>
							</div>
							<div className='col-lg-6 col-12 d-none d-lg-block'>
								<div ref={graphContainerRef} className='donut-graph-container'>
									<svg ref={ref} className='donut-graph' viewBox={`0 0 ${width} ${height}`} preserveAspectRatio='xMinYMin meet'>
										<g className='plot-area' />
										<g className='segment' />
									</svg>
									<div className='donut-graph-header'>
										{selectedSeries === undefined ? (
											<h5 className='display'>Hover segments for asset breakdown</h5>
										) : (
											<>
												<span className='title'>{series[selectedSeries].displayName}</span>
												<span>
													<span>Weight: </span>
													<span className='weight-indicator'>
														<span>{+series[selectedSeries].weight}%&nbsp;</span>
														<span className={`indicator-${series[selectedSeries].indicator === '-' ? 'negative' : series[selectedSeries].indicator === '+' ? 'positive' : 'neutral'}`} />
													</span>
												</span>
												<span>Entitlement: {series[selectedSeries].coinEntitlement}</span>
												<span>Date added: {series[selectedSeries].dateAdded}</span>
											</>
										)}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<table className='asset-breakdown-table'>
									<thead>
										<tr>
											<th>
												<h5 className='label'>Underlying Asset</h5>
											</th>
											<th>
												<h5 className='label'>Weight</h5>
											</th>
											<th  className='d-none d-lg-table-cell'>
												<h5 className='label'>Coin Entitlement</h5>
											</th>
											<th className='d-none d-lg-table-cell'>
												<h5 className='label'>Reference Price</h5>
											</th>
										</tr>
									</thead>
									<tbody>
										{series &&
											series.map((o, i) => (
												<tr onMouseEnter={() => setSelectedSeries(i)} onMouseLeave={() => setSelectedSeries(undefined)} key={o.key} className={i === selectedSeries ? 'selected' : ''}>
													<td>
														<div className='d-flex align-items-center' style={{ columnGap: 15 }}>
															<span className='circle-legend' style={{ backgroundColor: chartColors[i] }}></span>
															<span className='asset'>{o.displayName}</span>
														</div>
													</td>
													<td>
														<span className='weight-indicator'>
															<span>{+o.weight}%</span>
															<span className={`indicator-${o.indicator === '-' ? 'negative' : o.indicator === '+' ? 'positive' : 'neutral'}`} />
														</span>
													</td>
													<td  className='d-none d-lg-table-cell'>{o.coinEntitlement}</td>
													<td  className='d-none d-lg-table-cell'>{o.price}</td>
												</tr>
											))}
									</tbody>
								</table>

								{updated ? (
									<h5 className='label' style={{ marginTop: 40 }}>
										Last Updated {updated}
									</h5>
								) : undefined}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AssetBreakdownGraph
