import { Link } from 'react-router-dom';
import { etpLink } from '../../utils';

function ETPLink({ children, to, imgSize = 14, onClick, className, isNotEtp, ...rest }) {

  if (onClick) {
    return (
      <button onClick={onClick} className={`etp-link ${className}`} {...rest}>
        {children}
        <img
          className={'etp-link-arrow-white'}
          src={`/images/arrow-right-white.svg`}
          alt={'arrow right'}
          height={imgSize}
          width={imgSize}
        />
        <img
          className={'etp-link-arrow-black'}
          src={`/images/arrow-right-black.svg`}
          alt={'arrow right'}
          height={imgSize}
          width={imgSize}
        />
      </button>
    );
  }

  if (!to) {
    return null;
  }
  
  if (isNotEtp) {
    return (<a href={process.env.REACT_APP_ETP_BASE_URL + etpLink(to)} className={`etp-link ${className}`} {...rest}>
      {children}
      <img
        className={'etp-link-arrow-white'}
        src={`/images/arrow-right-white.svg`}
        alt={'arrow right'}
        height={imgSize}
        width={imgSize}
      />
      <img
        className={'etp-link-arrow-black'}
        src={`/images/arrow-right-black.svg`}
        alt={'arrow right'}
        height={imgSize}
        width={imgSize}
      />
    </a>)
  }

  return (
    <Link to={etpLink(to)} className={`etp-link ${className}`} {...rest}>
      {children}
      <img
        className={'etp-link-arrow-white'}
        src={`/images/arrow-right-white.svg`}
        alt={'arrow right'}
        height={imgSize}
        width={imgSize}
      />
      <img
        className={'etp-link-arrow-black'}
        src={`/images/arrow-right-black.svg`}
        alt={'arrow right'}
        height={imgSize}
        width={imgSize}
      />
    </Link>
  );
}

export default ETPLink;