import { StoryblokComponent } from "@storyblok/react";

function ETPOffers({blok}) {
  const {title, text, offers, footer_text} = blok;

  return (
    <div className={'white widget widget--slim'}>
      <div className={'container'}>
        <div>
          <p className={'label etp-offers-title'}>{title}</p>
          <h2 className={'h4 etp-offers-text'}>{text}</h2>
          <div className={'etp-offers-list'}>
            {offers.map(blok => <StoryblokComponent blok={blok} id={blok._uid}/>)}
          </div>
          <div className={'etp-offers-footer'}>{footer_text}</div>
        </div>
      </div>
    </div>
  )
}

export default ETPOffers;