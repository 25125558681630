import React, { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Article, Scrollbar } from '../.'
import * as d3 from 'd3'

export const Announcements = ({ blok, language }) => {
	const { anchor_key, background, title, call_to_action, layout, primary_topic, item_limit = '6', additional_topics, categories, article_types, crypto_types, product_types } = blok

	const filterQuery = useMemo(() => {
		const currentDate = d3.utcFormat('%Y-%m-%d %H:%M')(new Date())
		const filters = [`release_date: { lt_date: "${currentDate}" }`, `expiry_date: { gt_date: "${currentDate}" }`]

		if (primary_topic) {
			filters.push(`primary_topic: { in: "${primary_topic}" }`)
		}

		if (additional_topics && additional_topics.length > 0) {
			filters.push(`additional_topics: { in_array: "${additional_topics.join(',')}" }`)
		}

		if (article_types && article_types.length > 0) {
			filters.push(`article_type: { in: "${article_types.join(',')}" }`)
		}

		if (categories && categories.length > 0) {
			filters.push(`category: { in: "${categories.join(',')}" }`)
		}
		if (crypto_types && crypto_types.length > 0) {
			filters.push(`crypto_types: { in_array: "${crypto_types.join(',')}" }`)
		}
		if (product_types && product_types.length > 0) {
			filters.push(`product_types: { in_array: "${product_types.join(',')}" }`)
		}

		return `, filter_query: {${filters.join(',')}}`
	}, [additional_topics, article_types, categories, crypto_types, primary_topic, product_types])

	const query = gql`
		{
			ArticleItems (${language !== 'en' ? `starts_with: "${language}/*",` : ''} per_page: ${item_limit}, sort_by: "sort_by_date:desc"${filterQuery}) {
				items {
                    content {
                      description
                      title
                    }
                    uuid
                    full_slug
                    first_published_at
                }
			}
		}
	`
	const { /*loading, error,*/ data } = useQuery(query)

	const { ArticleItems } = data || {}

	const articleItems = useMemo(() => {
		if (ArticleItems) {
			return ArticleItems.items.flatMap((item) => {
				return {
					uuid: item.uuid,
					full_slug: item.full_slug,
					datePublished: item.first_published_at,
					content: layout === 'layout_2' ? '' : item.content.description,
					title: item.content.title,
				}
			})
		}

		return []
	}, [ArticleItems, layout])

	return (
		<div className={background} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='announcements articles layout_1'>
				<div className='container'>
					{articleItems.length > 0 ? (
						<>
							<h5 className='label'>{title}</h5>
							<div style={{ paddingTop: 20 }}>
								{call_to_action &&
									call_to_action.map((blok) => {
										const newBlok = {
												...blok,
												link: {
														...blok.link,
														cached_url: `${blok.link.cached_url}?article_type=${article_types.join(',')}`
												}
										}
										return <StoryblokComponent blok={newBlok} key={newBlok._uid} />
									})}
							</div>
							<div className='articles row'>
								<div className='col'>
									<Scrollbar>
										<div className='announcements-row'>
											{articleItems.map((article) => (
												<Article key={article.uuid} article={article} restrainHeight={true} />
											))}
										</div>
									</Scrollbar>
								</div>
							</div>
						</>
					) : (<h5 className='label ft-center'>No articles</h5>)}
					
				</div>
			</div>
		</div>
	)
}

export default Announcements
