import React from 'react'

import { storyblokEditable } from '@storyblok/react'
import ChevronLarge from '../../icons/ChevronLarge.svg'

export const BoxSet = ({ blok, set }) => {
	const { title, background } = set

	return (
		<a href='/' style={{ backgroundImage: `url('${background}')` }} {...storyblokEditable(blok)} className={'boxset'}>
			<h4 className='boxset-title'>{title}</h4>

			{/* <div className='boxset'>{tags.map(tag => <span key={tag} className='tag'>{tag}</span>)}</div> */}

            <div className="boxset-forward">
                <img src={ChevronLarge} alt="forward" />
            </div>
		</a>
	)
}

export default BoxSet
