import React, { useCallback, useState } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

const Team = ({ blok }) => {
    const { anchor_key } = blok
    const [selectedUid, setSelectedUid] = useState('')

    const handleBioExpand = useCallback((_uid) => {
        if (selectedUid === _uid) setSelectedUid('') 
        else setSelectedUid(_uid)
    }, [selectedUid])

	return (
		<div className='light team' id={anchor_key}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-12'>
							<h5 className='label c-gray-900'>{blok.title}</h5>
						</div>
						<div className='col-lg-8 col-12'>
							<p className='pull-quote'>{blok.description}</p>
                            <p>{blok.subtext}</p>
						</div>
					</div>
					<div className='row' style={{ marginTop: 20, justifyContent: 'center' }}>
						{blok.profile_cards.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} dimProfile={selectedUid ? true : false} bioExpanded={selectedUid === blok._uid} onExpandBio={handleBioExpand} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Team
