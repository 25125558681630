import React from 'react'
import { storyblokEditable } from '@storyblok/react'

export const StatisticsList = ({ blok }) => {
	const { names_values } = blok
	return (
		<div {...storyblokEditable(blok)}>
			<h5>Staking Rewards</h5>

			<div className='statistics'>
				{names_values.tbody.map(({ _uid, body }) => (
					<div className='statistic' key={_uid}>
						{body.map((col, i) => (
							<span key={col._uid} className={i > 0 ? 'statistic-detail' : ''}>
								<span>{col.value}</span>
							</span>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export default StatisticsList
