import { apiWidgetUrl } from '../../constants';

async function fetchGraphData({ series = [], identifier, isin, bloomberg, series_suffix }) {
  const etpSeries = series.filter((o) => o.type === 'etp');
  const indexSeries = series.filter((o) => o.type === 'index');

  const seriesStrings = [];

  if (etpSeries.length > 0) {
    seriesStrings.push(`&etpseries=${etpSeries.map((o) => o.identifier).join(',')}`)
  }

  if (indexSeries.length > 0) {
    seriesStrings.push(`&indexseries=${indexSeries.map((o) => o.identifier).join(',')}`)
  }


  const seriesPrefix = isin ? 'ISIN' : 'INDEX';
  const names = `&names=${seriesPrefix}_GRAPH_${identifier}${series_suffix},${seriesPrefix}_KEYSTATS_${identifier}`

  const response = await (await fetch(apiWidgetUrl + names + seriesStrings.join(''), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })).json();

  const widget = response.find((val) => val.key === `GRAPH_WIDGET_${identifier}`)
  const section = widget.sections.find((section) => section.key === `GRAPH_SECTION_${identifier}`)
  const seriesData = section.graph.series
  const overviewWidget = response.find((v) => v.key === `${seriesPrefix}_KEYSTATS_${identifier}`)
  const overviewData = overviewWidget.sections.find((v) => v.key === (isin ? `${isin}_OVERVIEW` : bloomberg))?.meta;

  return { seriesData, overviewData };
}

export default fetchGraphData;