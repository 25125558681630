import { getCookie } from '../components';

export const etpNewSlug = 'etps-new';
export const etpNewSubdirectory = 'etps-new'

export const etpSlug = 'etps';
export const etpSubdirectory = 'etps';
export const etpSubdomain = 'etp';

export const apiWidgetUrl = `${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}`;

export const getJsonRequest = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};
