import "./App.css";
import {StoryblokComponent, useStoryblok} from "@storyblok/react";
import React, {useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import languages from "./constants/languages.json";
import {Helmet} from "react-helmet";
import {StateContextProvider} from "./store";
import {etpNewSubdirectory, etpSubdomain} from "./constants";
import {isETPSubdomain} from "./utils";

function App() {
  const location = useLocation();

  useEffect(() => handleEtpUrl(location), [location]);

  const language = useMemo(() => {
    const firstSubdirectory = location.pathname.substring(1).split("/")[0];
    return languages.find((l) => l[firstSubdirectory]) && firstSubdirectory;
  }, [location.pathname]);

  const story = useStoryblok(
    isETPSubdomain() ? "ui-kit-pages/etp-layout" : "ui-kit-pages/layout",
    {version: "published", fallback_lang: "en", language}
  );

  if (!story || !story.content) {
    return <div></div>;
  }

  // If story is there, use StoryblokComponent to render it (dynamically)
  return (
    <>
      <Helmet>
        <title>CoinShares</title>
      </Helmet>
      <StateContextProvider>
        <StoryblokComponent blok={story.content} />
      </StateContextProvider>
    </>
  );
}

export default App;

function handleEtpUrl(location) {
  const splitPathname = location.pathname.split("/");
  const isETPSubdirectory = splitPathname.includes(etpNewSubdirectory);
  const isETPDomain = window?.location.host.split(".").includes(etpSubdomain);

  if (isETPSubdirectory) {
    if (isETPDomain) {
      window.location.replace(
        window.location.href.replace(`/${etpNewSubdirectory}`, "")
      );
    } else {
      const newPathname = splitPathname
        .filter((p) => p !== etpNewSubdirectory)
        .join("/");
      const {host, protocol} = window.location;
      const splitHost = host.split(".");
      const domain = splitHost
        .slice(splitHost.length - 2, splitHost.length)
        .join(".");
      const newHost = host.replace(domain, `${etpSubdomain}.${domain}`);
      window.location.replace(protocol + "//" + newHost + newPathname);
    }
  }

  const searchParams = new URLSearchParams(location.search);
  if (location.pathname !== "/" && location.pathname.endsWith("/")) {
    window.location =
      location.pathname.slice(0, location.pathname.length - 1) +
      (searchParams.toString() ? "?" + searchParams.toString() : "");
  }
}
