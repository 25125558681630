import React from 'react';
import { Helmet } from "react-helmet";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

function NewBranding({ blok }) {
  const { title, video_link, indent } = blok;

  const videoFAQ = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": title,
    "description": title,
    "thumbnailUrl": [
      video_link
    ],
    "uploadDate": "2022-12-16T09:00:00+00:00",
    "duration": "PT2M0S",
    "publisher": {
      "@type": "Organization",
      "name": "CoinShares"
    },
    "contentUrl": video_link
  }
  
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(videoFAQ)}</script>
      </Helmet>
      <div className='widget white'>
        <div className={`container new-branding ${indent ? `new-branding-${indent}-indent` : ''}`}>
          <div className='row'><h2 className='new-branding-title'>{title}</h2></div>
          <div className='row'>
            <div className='new-branding-video-row'>
              <div className='new-branding-video-wrapper'>
                <LiteYouTubeEmbed 
                  id={video_link.cached_url.split('/').pop().replace(/\?.*$/i, '')}
                  poster="maxresdefault"
                  title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                />
                {/* <iframe
                  width='100%'
                  src={video_link.cached_url}
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  style={{ border: 'none' }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewBranding;
