import { useState, useEffect } from 'react'
import { registerStoryblokBridge, getStoryblokApi } from '@storyblok/react'
import { isETPSubdomain } from '../utils';

export const useStoryblok = (slug, apiOptions = {}, bridgeOptions = {}) => {
  let [story, setStory] = useState();

  useEffect(() => {
    const storyblokApi = getStoryblokApi()

    async function fetchData() {
      try {
        let story;
        if ((slug === 'knowledge/market-activity/fund-flows' || slug === 'knowledge/market-activity/fund-flows/' || slug === 'etps-new/knowledge/market-activity/fund-flows/' || slug === 'etps-new/knowledge/market-activity/fund-flows') || (slug === 'research/digital-asset-fund-flows' || slug === 'research/digital-asset-fund-flows/')) {
          let response = await storyblokApi.get(`cdn/stories`, {
            starts_with: `etps-new/knowledge/market-activity/fund-flows`,
            sort_by: 'published_at:desc',
            per_page: 1
          })
          if (response.data.stories.length === 0) throw new Error("{code : 'ERR_BAD_REQUEST'}");
          const full_slug = response.data.stories[0].full_slug.replace("etps-new/", '');
          window.location.href = process.env.REACT_APP_ETP_BASE_URL + full_slug;
        } else {
          story = await storyblokApi.get(`cdn/stories/${slug}`, apiOptions)
          setStory(story.data.story)
        }
      } catch (er) {
        if (er.code === 'ERR_BAD_REQUEST') {
          const notFoundURL = isETPSubdomain() ? 'cdn/stories/etps-new/not-found' : 'cdn/stories/not-found'
          const { data } = await storyblokApi.get(notFoundURL, apiOptions)

          setStory(data.story)
        }
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  if (story && story.id) registerStoryblokBridge(story.id, (story) => {
    setStory(story)
  }, bridgeOptions)

  return story
}


