import { storyblokEditable } from '@storyblok/react'
import React, { useCallback, useEffect, useState } from 'react'
import fetchGraphData from "./fetchGraphData";
import KeyStatistics from "./KeyStatistics";
import PerformanceGraph from "./PerformanceGraph";

export function MarketPerformanceGraph({ blok, isin, bloomberg, name, language }) {
  const {
    anchor_key,
    background = 'transparent',
    coin_entitlement_calculator,
    disclaimer,
    series,
    series_suffix = ''
  } = blok
  const identifier = isin || bloomberg;
  const [seriesData, setSeriesData] = useState()
  const [overviewData, setOverviewData] = useState()
  const allSeries = [...(series?.map((o) => o.identifier?.toUpperCase()) || ''), identifier]
  const [selectedSeriesIdentifiers, setSelectedSeriesIdentifiers] = useState(allSeries);

  const toggleSeries = useCallback((selectSeries) => {
    setSelectedSeriesIdentifiers((identifiers) => {
      const newSelection = [...identifiers]

      const index = newSelection.indexOf(selectSeries)
      if (index >= 0) {
        newSelection.splice(index, 1)
      } else {
        newSelection.push(selectSeries)
      }

      return newSelection
    })
  }, [])

  useEffect(() => {
    handleFetchData();
  }, [series, bloomberg, isin, series_suffix])

  async function handleFetchData() {
    console.log(series)
    const { seriesData, overviewData } = await fetchGraphData({
      series,
      identifier,
      isin,
      bloomberg,
      series_suffix
    });
    setSeriesData(seriesData);
    setOverviewData(overviewData);
    setSelectedSeriesIdentifiers(allSeries)
  }

  return (
    <div className={background.toLowerCase()} id={anchor_key}>
      <div {...storyblokEditable(blok)} className='container widget' style={{ paddingTop: 0 }}>
        <div className='row'>
          <div className='col'>
            <div className='graph market'>
              <div className='row'>
                <div className='col-lg-8 col-12'>
                  <PerformanceGraph
                    title={'Market Performance'}
                    data={seriesData}
                    selectedIdentifiers={selectedSeriesIdentifiers}
                    identifier={identifier}
                  />
                  <div className='legend'>
                    {seriesData?.length > 0 && series &&
                      [{ identifier, name: name }, ...series].map((o) => {
                        const currentSeries = seriesData.find((s) => s.key === o.identifier.toUpperCase())
                        return (
                          <div key={o.identifier}
                               className={`legend-item ${selectedSeriesIdentifiers.indexOf(o.identifier.toUpperCase()) >= 0 ? '' : 'disabled'}`}
                               onClick={() => toggleSeries(o.identifier.toUpperCase())}>
                            <span style={{ background: currentSeries?.colour }} className='legend-indicator'/>
                            <span className='legend-text'>
															{o.name} <img src='/images/visibility.svg' alt=''/>
														</span>
                          </div>
                        )
                      })}
                  </div>
                  {disclaimer && <div className='graph-disclaimer'>{disclaimer}</div>}
                </div>
                <div className='col-lg-4 col-12'>
                  <KeyStatistics
                    data={overviewData}
                    language={language}
                    coin_entitlement_calculator={coin_entitlement_calculator}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketPerformanceGraph
