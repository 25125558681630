import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { storyblokEditable } from '@storyblok/react';
import { gql, useQuery } from '@apollo/client';
import Download from '../../icons/Download.svg';
import { DirectionalHint, Dropdown } from '@fluentui/react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import forwardBlack from '../../icons/forwardBlack.svg';
import { logAction } from '../../actions';
import { copyIsin } from '../../utils';
import { etpSlug } from '../../constants';


export const ETPList = ({blok, language}) => {
    const query = gql`
	{
		ExchangetradedproductItems${language !== 'en' ? `(starts_with: "${language}/${etpSlug}/")` : `(starts_with: "${etpSlug}/")`} {
			items {
				content {
					_uid
					name
					logo {
						filename
					}
					isin
					fact_sheet {
						filename
					}
					fact_sheet_name
					type {
						content
					}
				}
				id
				full_slug
			}
		}
	}
`

    const { /*loading, error,*/ data} = useQuery(query)
    const location = useLocation()

    const {background, documents_link} = blok
    const {ExchangetradedproductItems} = data || {}

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [selectedETP, setSelectedETP] = useState(searchParams.get('filter') || '')
    const [selectedExposure, setSelectedExposure] = useState(searchParams.get('exposure') || 'all')

    const [etps, setEtps] = useState([])

    const baseEtps = useMemo(() => {
        if (ExchangetradedproductItems) {
            return Array.from(ExchangetradedproductItems.items)
                .flatMap((etp) => {
                    return {id: etp.id, full_slug: etp.full_slug, ...etp.content}
                })
        }

        return []
    }, [ExchangetradedproductItems])

    useEffect(() => {
        if (baseEtps.length > 0) {
            fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=${baseEtps.map((o) => !!o?.isin && `ETP_LISTINFO_${o.isin}`).join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((value) => {
                value.json().then((value) => {
                    const values = [...baseEtps]
                    values.forEach((o) => {
                        const widget = value.find((val) => val.key === `ETP_LISTINFO_${o.isin.toUpperCase()}`)
                        const section = widget?.sections.find((section) => section.key === `${o.isin.toUpperCase()}_INFO`)

                        section?.meta.forEach((element) => {
                            o[element.key] = element.value
                        })
                    })

                    setEtps(values)
                })
            })
        }
    }, [baseEtps])

    const filteredEtps = useMemo(() => etps.filter((item) => (selectedETP === '' || item.type.content.title === selectedETP) && (selectedExposure === 'all' || item.assetCode.indexOf(selectedExposure) > -1)), [etps, selectedExposure, selectedETP])

    const etpTypes = useMemo(() => {
        return etps
            .flatMap((etp) => etp.type.content.title)
            .filter((val, index, array) => array.indexOf(val) === index)
            .map((etpType) => {
                return {
                    title: etpType,
                    icon: etps.find((etp) => etp.type.content.title === etpType)?.type.content.icon,
                }
            })
    }, [etps])

    const exposures = useMemo(() => {
        return [
            {key: 'all', text: 'All Exposures'},
            ...etps
                .map((etp) => etp.assetCode)
                .filter((val, index, array) => array.indexOf(val) === index)
                .sort()
                .map((etp) => {
                    return {key: etp, text: etp}
                }),
        ]
    }, [etps])

    const onQueryParamsChange = useCallback(
        (exposure, etp) => {
            const params = new URLSearchParams()

            if (etp && etp !== '') {
                params.append('filter', etp)
            }
            if (exposure && exposure !== 'all') {
                params.append('exposure', exposure)
            }

            navigate({search: params.toString()})
            setSelectedExposure(exposure)
            setSelectedETP(etp)
        },
        [navigate]
    )

    return (
        <div className={background.toLowerCase()}>
            <div {...storyblokEditable(blok)} className='widget' id='etplist'>
                <div className='container pattern-wrapper'>
                    <div className='row'>
                        <div className='col'>
                            <div className='etp-filters'>
                                <div className='etp-filter'>
                                    <Dropdown
                                        calloutProps={{
                                            directionalHint: DirectionalHint.bottomLeftEdge,
                                            directionalHintFixed: true,
                                        }}
                                        label='SELECT EXPOSURE'
                                        options={exposures}
                                        selectedKey={selectedExposure}
                                        onChange={(event, option) => onQueryParamsChange(option.key, selectedETP)}
                                    />
                                </div>
                                <div className='etp-filter'>
                                    <button className={`button button--tag ${selectedETP === '' ? 'is-active' : ''}`}
                                            onClick={() => onQueryParamsChange(selectedExposure, '')}>
                                        All ETPs
                                    </button>
                                    {etpTypes.map((etpType) => (
                                        <button
                                            className={`button button--tag ${selectedETP === etpType.title ? 'is-active' : ''}`}
                                            key={etpType.title}
                                            onClick={() => onQueryParamsChange(selectedExposure, etpType.title)}>
                                            {etpType.title} {etpType.icon.id ?
                                            <img src={etpType.icon.filename} alt={etpType.title}/> : <></>}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div className='etps'>
                                <div className='etp'>
                                    <span className='etp-logo grid-header'></span>
                                    <span className='etp-name grid-header'>Product</span>
                                    <span className='etp-fee grid-header'>Fee</span>
                                    <span className='etp-isin grid-header'>ISIN</span>
                                    <span className='etp-bloomberg grid-header'>Bloomberg</span>
                                    <span className='etp-currencies grid-header'>Trading Currencies</span>

                                    <span className='etp-factsheet grid-header'>Factsheet</span>
                                </div>

                                {filteredEtps.map((etp) => (
                                    <div key={etp.id} onClick={() => navigate('/' + etp.full_slug)} className='etp'>
										<span className='etp-logo'>
											<img src={etp.logo.filename} alt={etp.name}/>
										</span>
                                        <span className='etp-name'>
											<h5>{etp.name}</h5>
										</span>
                                        <span className='etp-fee'>{etp.fee}</span>
                                        <span className='etp-isin'>
											<span
                                                onClick={(ev) => {
                                                    copyIsin({isin: etp.isin, title: etp.name})
                                                    ev.stopPropagation()
                                                    return false
                                                }}
                                            >
												{etp.isin} <FontAwesomeIcon icon={'fas fa-copy'}/>
											</span>
										</span>
                                        <span className='etp-bloomberg'>{etp.bloomberg}</span>
                                        <span className='etp-currencies'>{etp.tradingCurrencies}</span>

                                        <span className='etp-factsheet'>
											<a
                                                href={etp.fact_sheet.filename}
                                                onClick={(ev) => {
                                                    logAction('download', {link: etp.fact_sheet.filename})
                                                    ev.stopPropagation()
                                                    return false
                                                }}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
												<span>{etp.fact_sheet_name || 'No title specified'}</span>
												<span>
													<img src={Download} alt='download'/>
												</span>
											</a>
										</span>
                                    </div>
                                ))}

                                {documents_link && (
                                    <div className='document-link white'
                                         to={'/' + documents_link.cached_url.replace(/^\//g, '')}>
                                        <span>We publish supporting documents to accompany our ETPs</span>
                                        <Link className={`button`}
                                              to={'/' + documents_link.cached_url.replace(/^\//g, '')}
                                              onClick={() => logAction('navigation', {
                                                  from: location.pathname,
                                                  to: '/' + documents_link.cached_url.replace(/^\//g, '')
                                              })}>
                                            View ETP Documents
                                            <img src={forwardBlack} alt='' className='icon-foward'/>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Pattern img="pattern-black-white-bottom.svg" position="bottom" mobile={true} /> */}
        </div>
    )
}

export default ETPList
