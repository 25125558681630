import React from "react";
import { clearCookies } from "../../utils";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ActiveAssetBanner = ({ blok }) => {
  const { text, action_text, info_icon } = blok;
  const { pathname } = useLocation();

  const handleClick = () => {
    clearCookies();
    window.location.replace(pathname);
  };

  return (
    <div className="active_asset_banner">
      <div className="active_asset_banner-row">
        <FontAwesomeIcon icon={info_icon.icon} />
        <p>{text}</p>
        <button className="hedge-fund-solutions-button" onClick={handleClick}>{action_text}</button>
      </div>
    </div>
  );
};

export default ActiveAssetBanner;
