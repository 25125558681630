import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import logAction from '../../actions'

export const StakingRewards = ({ blok }) => {
	const { background = 'white', title, header, faq, rewards, information } = blok

	return (
		<div className={background.toLowerCase()}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-12'>
							<h5 className='label'>{title}</h5>
						</div>
						<div className='col-lg-8 col-12'>
							<div className='row'>
								<div className='col'>
									<h2 className='content'>{header}</h2>
								</div>
							</div>
							<div className='staking-rewards-container'>
								<div className='staking-information'>
									{information.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} inline={true}/>
									))}
								</div>
                                <div className="staking-divider"></div>
								<div className='staking-rewards'>
									{rewards.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} />
									))}

									<a href={faq.filename} className='link download' alt={faq.alt} target='_blank' rel='noreferrer' onClick={() => logAction('download', { link: faq.filename})}>
										<span>{faq.title}</span>
                                        <img src='/images/DownloadIconLight.svg' alt='download' className='download-icon' style={{height: 12}}/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StakingRewards
