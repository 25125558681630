import { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

function ETPBigNumber({ blok }) {
  const { primary_text, secondary_text, info_text, info_tooltip_text } = blok;
  const [showTooltip, setShowTooltip] = useState(false);
  const infoRef = useRef();
  useOnClickOutside(infoRef, () => setShowTooltip(false));

  return (
    <div className={'etp-big-number'}>
      {secondary_text ? (
        <div className={'etp-big-number-text'}>
          <h1>{primary_text}</h1>
          <h3>{secondary_text}</h3>
        </div>
      ) : (
        <h3 className='h2'>{primary_text}</h3>
      )}
      <div
        ref={infoRef}
        className={'etp-big-number-info'}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(true)}
      >
        <span>{info_text}</span>
        <img src={'/images/circle-info.svg'} alt={'info'} height={'14px'}/>
        <div className={`etp-big-number-info-tooltip${showTooltip ? ' etp-big-number-info-tooltip--visible' : ''}`}>
          {info_tooltip_text}
        </div>
      </div>
    </div>
  );
}

export default ETPBigNumber;