import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchExchangeTradedProducts } from '../../queries';
import { copyIsin, etpLink } from '../../utils';
import ETPLink from '../ETPLink';

function ETPProductCard({ title, fees, isin, aum, currencies, page_url, img_url, img_alt }) {

  function onCopy(event) {
    event.preventDefault();
    copyIsin({ isin, title });
  }

  return (
    <Link to={etpLink(page_url)} className={'etp-product-card'}>
      <img src={img_url} alt={img_alt} height={'40px'}/>
      <div className={'etp-product-card-title'}>
        <ETPLink to={page_url ?? '/'} imgSize={20}><h4>{title}</h4></ETPLink>
      </div>
      <div className={'etp-product-card-data'}>
        <div className={'etp-product-card-row'}>
          <span>Fees</span><span>{fees}</span>
        </div>
        <div className={'etp-product-card-row'} onClick={onCopy}>
          <span>ISIN</span>
          <span className={'etp-product-card-value-copy'}>
            {isin} <FontAwesomeIcon icon={'copy'}/>
          </span>
        </div>
        <div className={'etp-product-card-row'}>
          <span>AuM</span><span>$USD {aum}</span>
        </div>
        <div className={'etp-product-card-row'}>
          <span>Trading currencies</span><span>{currencies}</span>
        </div>
      </div>
    </Link>
  );
}

export default ETPProductCard;

export function useProductCardsData(productsQuery, variables) {
  const [etpData, setEtpData] = useState();
  const { data } = useQuery(productsQuery, variables);

  const isins = useMemo(
    () => data?.ExchangetradedproductItems?.items?.map(item => item.content.isin).filter(isin => isin.length),
    [data]
  );

    useEffect(() => {
      if (isins?.length) {
        fetchExchangeTradedProducts(isins)
        .then(data => setEtpData(data.map(data => ({
            ...data,
            brokerCountryInvestorMapping: JSON.parse(data.brokerCountryInvestorMapping)
          })
        )));
      }
    }, [isins]);

    console.log('etpData', etpData, !etpData, variables.variables.by_uuids);

  if (!data?.ExchangetradedproductItems?.items || (!etpData && !variables.variables.by_uuids)) {
    return [];
  }

  return data.ExchangetradedproductItems.items
    .map(({ content, full_slug }) => ({
      full_slug,
      ...content,
      ...(etpData??[]).find(({ isin }) => isin === content.isin)
    }))
    .filter(({ isin }) => isin);
}