import React from 'react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

export const TwoColumnRowSplit = ({ blok }) => {
	const { background = 'white', row_one_body, row_two_body, column_body } = blok

	return (
		<div className={background.toLowerCase()}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className='two-column-row-split'>
								<div className='top-left'>
									{row_one_body.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} inline={true} />
									))}
								</div>
								<div className='bottom-left'>
									{row_two_body.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} inline={true} />
									))}
								</div>
								<div className='right-column'>
									{column_body.map((blok) => (
										<StoryblokComponent blok={blok} key={blok._uid} inline={true} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TwoColumnRowSplit
