import { gql, useQuery } from "@apollo/client";
import { Dropdown } from "@fluentui/react";
import { useEffect, useState, useMemo, useRef } from "react";
import ETPCompanySquare from "../ETPCompanySquare";
import ETPLink from "../ETPLink";
import ETPPaginationControls from "../ETPPaginationControls";
import { INVESTOR_TYPES, cookieNames, getCookie, otherOption } from "../../utils";
import { etpNewSlug } from "../../constants";
import { Helmet } from "react-helmet";

const perPage = 15;

function ETPBrokerSearch({ blok, language, logo }) {
  const [page, setPage] = useState(0);
  const [brokerSearch, setBrokerSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("all");
  const [selectedCountry, setSelectedCountry] = useState();
  const [productsUnavailable, setProductsUnavailable] = useState(true);
  const [countryName, setCountryName] = useState(otherOption.text);
  const { title, alt_link, alt_link_text, broker_not_found_text } = blok;
  const productContainer = useRef(null);

  const countryResponse = useQuery(optionsQuery(language), {
    variables: { language },
  });

  useEffect(() => {
    console.log("logo", logo);
    const country = getCookie(cookieNames.LOCATION);
    if (country) {
      setSelectedCountry(
        countryResponse.data?.CountryItems?.items?.find?.(
          ({ slug }) => slug === country
        )?.uuid || otherOption.key
      );
      setCountryName(country);
    }
  }, [countryResponse]);

  const languageFilter = language === "en" ? undefined : language + "/*";

  const { data, loading } = useQuery(
    brokerQuery({
      country: selectedCountry,
      title: brokerSearch ? `*${brokerSearch}*` : undefined,
    }),
    { variables: { language: languageFilter } }
  );
  const { BrokerItems, LegalnoticeItems } = data || {};
  useEffect(() => {
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    if (legalNotices && investorType) {
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [LegalnoticeItems, countryName]);

  const changePage = (newPage) => {
    productContainer.current.scrollIntoView({behavior: 'smooth' });

    setTimeout(() => {
      setPage(newPage);
    }, 600);
  };

  const ShemaOrganizationSEO = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: window.location.origin,
      logo: window.location.origin + "/logo-coinshares.jpeg",
    };
  }, []);

  function handleProductChange(event, option) {
    setSelectedProduct(option.key);
    setPage(0);
  }

  function handleCountryChange(event, option) {
    setSelectedCountry(option.key);
    setPage(0);
    setCountryName(option.slug);
  }

  const noBrokers = BrokerItems?.items?.filter(
    (item) =>
      selectedProduct === "all" ||
      item.content.products.some((product) => product.slug === selectedProduct)
  ).length;

  const isNotInstitutional =
    getCookie(cookieNames.INVESTOR_TYPE) !== INVESTOR_TYPES.INSTITUTIONAL;

  if (!isNotInstitutional) return <div></div>;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ShemaOrganizationSEO)}
        </script>
      </Helmet>
      <div className={"widget white"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <h2 className={"etp-broker-search-title"}>{title}</h2>
            </div>
          </div>
          <div className={"row"} ref={productContainer}>
            <div
              className={`etp-broker-search${
                loading ? " etp-broker-search-loading" : ""
              }`}
            >
              <Search
                brokerSearch={brokerSearch}
                onBrokerSearchChange={setBrokerSearch}
                onProductChange={handleProductChange}
                onCountryChange={handleCountryChange}
                selectedCountry={selectedCountry}
                language={languageFilter}
                selectedProduct={selectedProduct}
              />
              <div className={"etp-broker-search-brokers"}>
                {!productsUnavailable &&
                  BrokerItems?.items
                    ?.filter(
                      (item) =>
                        selectedProduct === "all" ||
                        item.content.products.some(
                          (product) => product.slug === selectedProduct
                        )
                    )
                    ?.slice(page * perPage, page * perPage + perPage)
                    .map(({ content }) => (
                      <ETPCompanySquare
                        title={content.title}
                        linkUrl={content.link.cachedUrl}
                        logoUrl={content.logo.filename}
                      />
                    ))}
              </div>
              {(noBrokers <= 0 || productsUnavailable) && (
                <div className={"broker-not-found"}>
                  {broker_not_found_text}
                </div>
              )}
              {!productsUnavailable && (
                <div className={"etp-broker-search-pagination"}>
                  <ETPPaginationControls
                    page={page}
                    pages={Math.ceil(noBrokers / perPage)}
                    onClick={changePage}
                  />
                </div>
              )}
              <div className={"etp-broker-search-alt-button"}>
                <ETPLink to={alt_link.id ? alt_link.cached_url : null}>
                  {alt_link_text}
                </ETPLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ETPBrokerSearch;

function Search({
  brokerSearch,
  onBrokerSearchChange,
  onProductChange,
  onCountryChange,
  language,
  selectedCountry,
  selectedProduct,
}) {
  const { data } = useQuery(optionsQuery(language), {
    variables: { language },
  });
  const { ExchangetradedproductItems, CountryItems } = data || {};
  const productOptions = ExchangetradedproductItems?.items
    .filter((item) => !!item.content.name && !!item.slug)
    .map((item) => ({ text: item.content.name, key: item.slug }));
  productOptions?.unshift({ text: "All", key: "all" });
  const countryOptions =
    CountryItems?.items?.map?.((item) => ({
      text: item.content.title,
      key: item.uuid,
      slug: item.slug,
    })) ?? [];

  return (
    <div className={"etp-broker-search-filters etp-form"}>
      <div className={"etp-input-group"}>
        <label>Broker Name</label>
        <input
          type={"text"}
          placeholder={"Who are you looking for?"}
          value={brokerSearch}
          onChange={(e) => onBrokerSearchChange(e.target.value)}
        />
      </div>
      <div className={"etp-input-group"}>
        <label>Country</label>
        <Dropdown
          selectedKey={selectedCountry}
          options={[...countryOptions, otherOption]}
          placeholder={"Select a country"}
          onChange={onCountryChange}
        />
      </div>
      {/* <div className={'etp-input-group'}>
        <label>Product</label>
        <Dropdown selectedKey={selectedProduct} options={productOptions} placeholder={'Select a product'} onChange={onProductChange} />
      </div>
  */}
    </div>
  );
}

const brokerQuery = ({ country = "*", title = "*" }) => gql`
  query ($language: String) {
    BrokerItems(
      starts_with: $language,
      sort_by: "name:asc",
      filter_query: {countries: {in_array: "${country}"}, title: {like: "${title}"}}
    ) {
      items {
        uuid
        content {
          title
          logo {
            filename
          }
          link {
            cachedUrl
          }
          products {
            id
            name
            slug
          }
          countries {
            id
          }
        }
      }
    }
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;

const optionsQuery = () => gql`
  query {
    ExchangetradedproductItems(starts_with: "${etpNewSlug}/", sort_by: "name:asc") {
      items {
        content {
          _uid
          name
          logo {
            filename
          }
        }
        id
        full_slug
        slug
      }
    }

    CountryItems(sort_by: "name:asc") {
      items {
        uuid
        slug
        name
        content {
          title
        }
      }
    }
  }
`;
