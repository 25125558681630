import { useEffect, useRef } from "react";

function useHash(hash) {
  const ref = useRef();

  useEffect(() => {
    if (hash) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const location = window.location.toString().split('#')[0];
            const oldHash = window.location.hash;

            if (oldHash !== hash) {
              window.history.replaceState(null, null, location + '#' + hash);
            }
          }
        });
      }, {
        threshold: [0.4]
      });
      observer.observe(ref.current);

      return () => observer.unobserve(ref.current);
    }
  }, [hash]);

  return ref;
}

export default useHash;