import arrowRightWhite from '../../icons/arrowRightWhite.svg';
import './ETPNavbarActionButton.scss';
import { cookieNames, etpLink, getCookie, makeURLFromRoot } from '../../utils';
import { Link } from 'react-router-dom';

export function ETPNavbarActionButton({ blok }) {
  const { xbt_link, physical_link, text } = blok;

  const location = getCookie(cookieNames.LOCATION);
  const { cached_url, target } = location === 'sweden' ? xbt_link : physical_link;

  return (
    <Link className='choose-etp-btn d-flex' to={makeURLFromRoot(etpLink(cached_url.replace(/\/$/, '')))} target={target}>
      <span className='separator'>{text}</span>
      <img src={arrowRightWhite} alt='arrow right'/>
    </Link>
  );
}