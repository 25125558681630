import { gql, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DirectionalHint, Dropdown, Modal } from "@fluentui/react";
import { cookieNames, getCookie, setCookie, getLanguage } from "../../utils";
import ETPButton from "../ETPButton";
import { useLocation, useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";

const defaultFormValue = {
  location_and_investor_type: "",
};

export const ActivePopUpNotice = ({ blok, language }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useQuery(query, {
    variables: { language: language !== "en" ? `${language}/*` : undefined },
  });
  const { LanguageItems, LocationandinvestortypeItems } = data || {};
  const [showPopup, setShowPopup] = useState(false);

  const locationAndInvestor = useMemo(() => {
    const items =
      LocationandinvestortypeItems?.items.map((item) => ({
        key: item.slug,
        text: item.content.name,
        showFund: item.content.show_fund,
      })) || [];
    return items;
  }, [LocationandinvestortypeItems]);

  const formValidation = {
    location_and_investor_type: {
      required: true,
      errorMessage: blok.error_message,
    },
  };

  const handleSubmit = useCallback(
    (formValues) => {
      setCookie(
        cookieNames.LOCATION_AND_INVESTOR_TYPE,
        formValues.location_and_investor_type,
        blok.cookie_expiration_days
      );
      const allLanguageCodes = LanguageItems.items.map(
        ({ content }) => content.code
      );
      const firstSubdirectory = location.pathname.split("/")[1];
      const pathnameHasLanguage = allLanguageCodes.includes(firstSubdirectory);

      setShowPopup(false);

      let language = getLanguage();
      if (pathnameHasLanguage) {
        if (language === "en") {
          navigate(location.pathname);
        } else {
          const pathWithoutLanguage = location.pathname
            .split("/")
            .slice(2)
            .join("/");
          navigate(`${language}/${pathWithoutLanguage}`);
        }
      } else {
        navigate(location.pathname);
      }
      window.location.reload();
    },
    [
      LanguageItems,
      LocationandinvestortypeItems?.items,
      blok.cookie_expiration_days,
      location,
      navigate,
    ]
  );

  const { formState, handleOnChange, handleOnSubmit } = useForm(
    defaultFormValue,
    formValidation,
    handleSubmit
  );

  useEffect(() => {
    if (!getCookie(cookieNames.LOCATION_AND_INVESTOR_TYPE)) {
      setShowPopup(true);
    }
  }, []);

  let currentInvestorDetails = locationAndInvestor.find(
    (item) => item.key === formState?.location_and_investor_type.value
  );

  return (
    <Modal
      isOpen={showPopup}
      onDismiss={(ev) => {
        if (ev.target.classList.indexOf("ms-Overlay") >= 0) {
          ev.preventDefault();
          setShowPopup(false);
        }
      }}
      containerClassName="active-popup-notice black"
    >
      <div className="modal-header">
        <h2 className="modal-header">{blok.title}</h2>
      </div>
      <div className="col-12">
        <p className="modal-description">{blok.description}</p>
      </div>
      <div className="column">
        <Dropdown
          calloutProps={{
            directionalHint: DirectionalHint.bottomLeftEdge,
            directionalHintFixed: true,
          }}
          label={blok.location_and_investor_type}
          placeholder={blok.placeholder_text}
          options={locationAndInvestor}
          size="small"
          onChange={(ev, option) => {
            if (handleOnChange)
              handleOnChange("location_and_investor_type", option.key);
          }}
          name="location_and_investor_type"
          type="text"
          errorMessage={formState.location_and_investor_type.error}
        />
      </div>
      <div>
        <p className="notice-disclaimer">
          <span>{blok.confirmation_text}&nbsp;</span>
          <span>
            <a href="/" target="_blank" rel="noreferrer">
              {blok.confirmation_link_name}
            </a>
          </span>
        </p>
      </div>
      <div className="d-flex justify-space-between align-items-center">
        <div className="me-3">
          {currentInvestorDetails && !currentInvestorDetails?.showFund && (
            <div className="missing-notice">
              <span>{blok.missing_notice_text}</span>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <ETPButton
            blok={{ onClick: handleOnSubmit, text: blok.confirm_button_title }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ActivePopUpNotice;

const query = gql`
  query {
    LanguageItems {
      items {
        uuid
        slug
        content {
          code
        }
      }
    }

    LocationandinvestortypeItems {
      items {
        uuid
        slug
        content {
          name
          language {
            slug
          }
          show_fund
        }
      }
    }
  }
`;
