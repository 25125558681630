import { gql, useQuery } from '@apollo/client';
import NewOfferingCard from './NewOfferingCard';

function NewOfferingSection({offeringAmounts, title, cards, show_descriptions, isEtp, full_page_card, smaller_bottom_margin, hide_bottom_text}) {
  const { data: CardsContent } = useQuery(query, { variables: { uuid: (cards || []).join(',') } });

  function formatValue(cache_key) {
    const offeringAmount = offeringAmounts[cache_key];
    if (!offeringAmount) {
      return null;
    }
    return `${offeringAmount.value} ${offeringAmount.suffix}`;
  }

  return (
    <div className={`row ${smaller_bottom_margin ? 'smaller-bottom-margin' : ''}`}>
      <div className='col-12'><h2>{title}</h2></div>

      {(
        CardsContent?.OfferingItems?.items.map(({ content }, index, array) =>
        <div className={`${!full_page_card ? `col-md-${array.length % 2 == 0 ? 6 : 4}` : 'col-md-12'}`}
          key={content.uuid}
        >
          <NewOfferingCard
            link={content.link.cachedUrl}
            linkTitle={content.title}
            text={show_descriptions ? content.description : undefined}
            label={content.statistic_title}
            labelValue={content.statistic_value || formatValue(content.cache_key)}
            isNotEtp={isEtp}
            full_page_card={full_page_card}
            hide_bottom_text={hide_bottom_text}
          />
        </div>)
      )}
    </div>
  );
}

export default NewOfferingSection;

const query = gql`
  query($uuid: String) {
    OfferingItems(by_uuids: $uuid) {
      items {
        content {
          cache_key
          component
          currency
          description
          long_title
          link_title
          link_long_title
          statistic_title
          statistic_value
          title
          icon {
            filename
          }
          light_icon {
            filename
          }
          dark_icon {
            filename
          }
          link {
            cachedUrl
          }
          sort_order
        }
        uuid,
        full_slug
      }
    }
  }
`;
