import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { useMemo } from 'react'

const Column = ({ blok }) => {
    const { width, show_left_border } = blok
    const { xsmall, small, medium, large, xlarge } = width || {}


    const classNames = useMemo(() => {
        const names = []

        if (width) {
            if (xsmall) names.push(`col-${xsmall}`)
            if (small) names.push(`col-lg-${small}`)
            if (medium) names.push(`col-lg-${medium}`)
            if (large) names.push(`col-lg-${large}`)
            if (xlarge) names.push(`col-lg-${xlarge}`)
        }

        if (show_left_border) {
            names.push('bl-blue')
        }

        return names.join(' ')
    },[large, medium, show_left_border, small, width, xlarge, xsmall])



	return (
		<div {...storyblokEditable(blok)} className={classNames}>
			{blok.body.map((blok) => (
				<StoryblokComponent blok={blok} key={blok._uid} />
			))}
		</div>
	)
}

export default Column
