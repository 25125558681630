import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { RichText } from '../RichText'

export const QuarterlyAnnualReportsQuarter = ({ blok }) => {
    return <div {...storyblokEditable(blok)}>
        <RichText blok={blok.body} inline={true}/>
    </div>
}

export default QuarterlyAnnualReportsQuarter