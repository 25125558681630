import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const TwoColumn = ({ blok }) => {
	const { column_one_body, column_two_body } = blok

	return (
		<div className='white'>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6'>
							{column_one_body.map((blok) => (
								<StoryblokComponent blok={blok} key={blok._uid} inline={true} />
							))}
						</div>
						<div className='col-lg-6'>
							{column_two_body.map((blok) => (
								<StoryblokComponent blok={blok} key={blok._uid} inline={true}/>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TwoColumn
