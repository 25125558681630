import { StoryblokComponent } from '@storyblok/react';

function ETPReserveWidgets({ blok }) {
  const {
    physical_logo,
    physical_text,
    physical_widget_link,
    physical_products_link,
    xbt_logo,
    xbt_text,
    xbt_widget_link,
    xbt_products_link,
  } = blok;

  return (
    <div className='widget black'>
      <div className='container etp-reserve-widgets'>
        <div className='row'>
          <div className='col-lg-6 etp-reserve-widgets-text'>
            <img src={physical_logo.filename}/>
            <p>{physical_text}</p>
            {physical_products_link.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid}/>)}
          </div>
          <div className='col-lg-6'>
            <div className='etp-reserve-widgets-widget'>
              <iframe src={physical_widget_link.url}/>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 order-lg-2 etp-reserve-widgets-text'>
            <img src={xbt_logo.filename}/>
            <p>{xbt_text}</p>
            {xbt_products_link.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid}/>)}
          </div>
          <div className='col-lg-6 order-lg-1'>
            <div className='etp-reserve-widgets-widget'>
              <iframe src={xbt_widget_link.url}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPReserveWidgets;