import ETPLink from "../ETPLink";

function ETPDocumentLink({ blok }) {
  const {text, link, link_text} = blok;

  return (
    <div className={'row etp-document-link'}>
      <div className={'col-md-8'}>{text}</div>
      <div className={'col-md-4 d-flex justify-content-md-end'}>
        <ETPLink to={link.cached_url}>{link_text}</ETPLink>
      </div>
    </div>
  )
}

export default ETPDocumentLink;