import { StoryblokComponent } from "@storyblok/react";
import useHash from "../../hooks/useHash";

function ETPPresentation({ blok }) {
  const {
    title,
    button,
    big_numbers,
    bitcoin_info,
    article_links,
    anchor_key,
    text_one,
    text_two,
    logo,
  } = blok;
  const ref = useHash(anchor_key);

  return (
    <div
      className={"widget widget--slim gray etp-presentation"}
      id={anchor_key}
      ref={ref}
    >
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-2"}>
          {logo && 
            <div className={"etp-presentation__picture"}>
              <img src={logo.filename} alt="" />
            </div>
          } 
          </div>
          <div className={"col-lg-6"}>
            <h2>{title}</h2>
          </div>
          <div
            className={
              "col-lg-4 d-flex justify-content-lg-end align-items-start"
            }
          >
            {button?.map((blok) => (
              <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid} />
            ))}
          </div>
        </div>
        {big_numbers?.length > 0 && (
          <div className={"row"}>
            <div className={"col-lg-2"} />
            <div className={"col-lg-10"}>
              <div className={"etp-presentation-numbers"}>
                {big_numbers?.map((blok) => (
                  <StoryblokComponent
                    blok={blok}
                    id={blok._uid}
                    key={blok._uid}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {bitcoin_info?.length > 0 && (
          <div className={"row"}>
            <div className={"col-lg-2"} />
            <div className={"col-lg-10"}>
              {bitcoin_info?.map((blok) => (
                <StoryblokComponent
                  blok={blok}
                  id={blok._uid}
                  key={blok._uid}
                />
              ))}
            </div>
          </div>
        )}
        {(text_one || text_two) && (
          <div className={"row"}>
            <div className={"col-lg-2"} />
            <div className={"col-lg-5"}>{text_one}</div>
            <div className={"col-lg-5"}>{text_two}</div>
          </div>
        )}
        {article_links?.length > 0 && (
          <div className={"row"}>
            <div className={"col-lg-2"} />
            <div className={"col-lg-10 etp-presentation-articles"}>
              {article_links?.map((blok) => (
                <StoryblokComponent
                  blok={blok}
                  id={blok._uid}
                  key={blok._uid}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ETPPresentation;
