import ETPButton from '../ETPButton';
import { cookieNames, getCookie } from '../../utils';

function ETPAllProductsButtons({ blok }) {
  const { physical_link, xbt_link } = blok;

  const location = getCookie(cookieNames.LOCATION);
  const link = location === 'sweden' ? xbt_link : physical_link;

  return (
    <ETPButton blok={{ ...blok, link }}/>
  );
}

export default ETPAllProductsButtons;