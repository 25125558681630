import React, { useEffect, useState } from 'react'
import { storyblokEditable } from '@storyblok/react'
import * as d3 from 'd3'
import { Scrollbar } from '../Scrollbar';

export const FairValueCalculation = ({ blok }) => {
  const { anchor_key, title, text, cache_key } = blok
  const [fairValueValues, setFairValueValues] = useState()
  const { AccruedFee, Date: fairValueDate, Divisor, FairValue, IndexPrice2 } = fairValueValues || {}

  useEffect(() => {
    if (cache_key) {
      fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=XBT_PROVFAIRVALUE_${cache_key}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((value) => {
        value.json().then((value) => {

          const widget = value.find((val) => val.key === `XBT_PROVFAIRVALUE_${cache_key.toUpperCase()}`)
          const section = widget.sections.find((section) => section.key === cache_key.toUpperCase())

          const fairValueCalc = {}
          section.meta.forEach((o, i) => fairValueCalc[o.key] = o)

          setFairValueValues(fairValueCalc)
        })
      })
    }
  }, [cache_key])

  return (
    <div id={anchor_key} className='widget black'>
      <div {...storyblokEditable(blok)}>
        <div className='container'>
          {!cache_key && <>No Cache Key!</>}
          {cache_key && (
            <>
              <div className={'row fair-value-header'}>
                <div className={'col-lg-6'}><h3>{title}</h3></div>
                <div className={'col-lg-6'}>{text}</div>
              </div>
              <div className={'row'}>
                <Scrollbar>
                  <div className={'fair-value-calculation'}>
                    <h2>Fair Value</h2>
                    <h2 className={'fair-value-symbol'}>=</h2>
                    <h2 className={'fair-value-symbol'}>(</h2>
                    <div className={'fair-value-group'}>
                      <h2 className={'fair-value-value'}>
                        {IndexPrice2?.prefix} {IndexPrice2?.value} {IndexPrice2?.suffix}
                      </h2>
                      <div className={'fair-value-border'}>
                        <div/>
                        <div/>
                      </div>
                      <div className={'fair-value-group-info'}>BTC Price Index calculated</div>
                    </div>
                    <h2 className={'fair-value-symbol'}>-</h2>
                    <div className={'fair-value-group'}>
                      <h2 className={'fair-value-value'}>
                        {AccruedFee?.prefix} {AccruedFee?.value} {AccruedFee?.suffix}
                      </h2>
                      <div className={'fair-value-border'}>
                        <div/>
                        <div/>
                      </div>
                      <div className={'fair-value-group-info'}>Accrued fees since inception</div>
                    </div>
                    <h2 className={'fair-value-symbol'}>)</h2>
                    <h2 className={'fair-value-symbol'}>×</h2>
                    <div className={'fair-value-group'}>
                      <h2 className={'fair-value-value'}>{Divisor?.value}</h2>
                      <div className={'fair-value-border'}>
                        <div/>
                        <div/>
                      </div>
                      <div className={'fair-value-group-info'}>Divisor 1 XBT = 0.05 BTC</div>
                    </div>
                    <h2 className={'fair-value-symbol'}>=</h2>
                    <h2 className={'fair-value-value'}>{FairValue?.prefix} {FairValue?.value} {FairValue?.suffix}</h2>
                  </div>
                </Scrollbar>
              </div>
              <div className={'row fair-value-footer'}>
                <div className={'col-lg-6'}>
                  Last updated {d3.utcFormat('%Y-%m-%d %H:%M:%S')(new Date(fairValueDate?.value))} UTC
                </div>
                <div className={'col-lg-6'}>
                  *Fair Value is not indicative of current market price; Index sources listed in prospectus
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// Last updated {d3.utcFormat('%Y-%m-%d %H:%M:%S')(new Date(fairValueDate?.value))} UTC

export default FairValueCalculation

