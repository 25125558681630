import React, { useMemo } from 'react'
import { InlineTable } from '../InlineTable'
import { RichText } from '../RichText'
import { TabStrip } from '../TabStrip'

export const InvestorCapTable = ({ blok }) => {
	const { director_pdmr_ownership, director_pdmr_ownership_description, director_pdmr_ownership_footnote, significant_shareholders, significant_shareholders_footnote, significant_shareholders_description } = blok

	const shareholdersTab = useMemo(() => {
		return (
			<>
            {significant_shareholders_description && <RichText inline={true} blok={significant_shareholders_description} />}
				<InlineTable blok={{ table: significant_shareholders }} />
				{significant_shareholders_footnote && <p style={{ marginTop: 40, marginBottom: 40 }}>{significant_shareholders_footnote}</p>}
			</>
		)
	}, [significant_shareholders, significant_shareholders_footnote, significant_shareholders_description])

	const directorsTab = useMemo(() => {
		return (
			<>
				{director_pdmr_ownership_description && <RichText inline={true} blok={director_pdmr_ownership_description} />}
				<InlineTable blok={{ table: director_pdmr_ownership }} />
				{director_pdmr_ownership_footnote && <p style={{ marginTop: 40, marginBottom: 40 }}>{director_pdmr_ownership_footnote}</p>}
			</>
		)
	}, [director_pdmr_ownership, director_pdmr_ownership_footnote, director_pdmr_ownership_description])

	return (
		<>
			<TabStrip
				blok={{
					tabs: [
						{ _uid: '1', component: 'tab', title: 'Significant Shareholders', content: shareholdersTab },
						{ _uid: '2', component: 'tab', title: 'Director & PDMR Ownership', content: directorsTab },
					],
				}}
			/>
		</>
	)
}

export default InvestorCapTable
