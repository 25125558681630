import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import {initializeIcons} from "@fluentui/react";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faLinkedin,
  faFacebook,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faChevronRight,
  faChevronLeft,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faCopy,
  faPersonArrowDownToLine,
  faTentArrowDownToLine,
  faCircleInfo,
  faArrowUpRightFromSquare,
  faSquareArrowUpRight,
  faCheck,
  faXmark,
  faHouse,
  faGear,
  faGears,
} from "@fortawesome/free-solid-svg-icons";
import {apiPlugin, storyblokInit} from "@storyblok/react";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {
  Announcements,
  Articles,
  AssetBreakdownGraph,
  BenchmarkGraph,
  BoxSets,
  Breadcrumb,
  BrokerSelect,
  Button,
  ButtonStrip,
  CardSet,
  Carousel,
  CarouselCard,
  ComplexTable,
  ContactForm,
  DailyHedgingPosition,
  DailyHedgingPositionGraph,
  DocumentList,
  ETPDocuments,
  ETPList,
  ETPsTotalAmountInvested,
  ExchangeList,
  FairValueCalculation,
  FeaturedProducts,
  FeatureStatements,
  Headline,
  IndexForm,
  IndexList,
  Information,
  InlineTable,
  InvestmentPortfolio,
  InvestorCapTable,
  KeyInformation,
  KnowledgeBytes,
  KnowledgeBytesTopic,
  KnowledgeView,
  MarketPerformanceGraph,
  Nasdaq,
  NewsLetterForm,
  OfferingSummary,
  Paragraph,
  PopupNotice,
  ProductAlert,
  QuarterlyAnnualReportsQuarter,
  Quote,
  RichText,
  Row,
  SimilarETPs,
  StakingRewards,
  StatisticsList,
  StoryArticle,
  StructureDiagram,
  Tab,
  TabStrip,
  TwitterFeed,
  Volume,
  ActivePopUpNotice,
  ActiveAssetContactForm,
  PartnerAndLeader,
  ActiveAssetBanner,
} from "./components";
import Column from "./components/Column";
import ETPAllProductsButtons from "./components/ETPAllProductsProducts";
import ETPArticleCategories from "./components/ETPArticleCategories";
import ETPArticleLink from "./components/ETPArticleLink";
import ETPArticles from "./components/ETPArticles";
import ETPArticlesSelected from "./components/ETPArticlesSelected";
import ETPArticlesSearch from "./components/ETPArticlesSearch";
import ETPAssetBreakdownGraph from "./components/ETPAssetBreakdownGraph";
import ETPBanner from "./components/ETPBanner/ETPBanner";
import ETPBigNumber from "./components/ETPBigNumber";
import ETPBitcoinEffect from "./components/ETPBitcoinEffect";
import ETPBrokers from "./components/ETPBrokers";
import ETPBrokerSearch from "./components/ETPBrokerSearch";
import ETPButton from "./components/ETPButton";
import ETPChooseUs from "./components/ETPChooseUs";
import ETPCompanySquare from "./components/ETPCompanySquare";
import ETPDisclaimer from "./components/ETPDisclaimer";
import ETPDocumentLink from "./components/ETPDocumentLink";
import ETPDocumentsTable from "./components/ETPDocumentsTable";
import ETPFAQs from "./components/ETPFAQs";
import ETPFAQ from "./components/ETPFAQs/ETPFAQ";
import ETPFeaturedStatements from "./components/ETPFeaturedStatements";
import ETPFeaturedStatement from "./components/ETPFeaturedStatements/ETPFeaturedStatement";
import ETPHowStakingWorks from "./components/ETPHowStakingWorks";
import ETPKeyInformation from "./components/ETPKeyInformation";
import ETPLeadership from "./components/ETPLeadership";
import ETPLeadingTheWay from "./components/ETPLeadingTheWay";
import ETPNewsletter from "./components/ETPNewsletter";
import ETPNotificationBanner from "./components/ETPNotificationBanner/ETPNotificationBanner";
import ETPOffers from "./components/ETPOffers";
import ETPOffer from "./components/ETPOffers/ETPOffer";
import ETPPhysicalList from "./components/ETPPhysicalList";
import ETPPresentation from "./components/ETPPresentation";
import ETPProduct from "./components/ETPProduct";
import ETPProductCard from "./components/ETPProductCard";
import ETPProductOffer from "./components/ETPProductOffer";
import ETPProofOfReserves from "./components/ETPProofOfReserves";
import ETPQuickNavigation from "./components/ETPQuickNavigation";
import ETPRelatedProducts from "./components/ETPRelatedProducts";
import ETPReservesExplanation from "./components/ETPReservesExplanation";
import ETPReserveWidgets from "./components/ETPReserveWidgets";
import ETPSecurity from "./components/ETPSecurity";
import ETPTimeline from "./components/ETPTimeline";
import ETPTimeCard from "./components/ETPTimeline/ETPTimeCard";
import ETPVideo from "./components/ETPVideo";
import ETPXBTCard from "./components/ETPXBTCard";
import ETPXBTList from "./components/ETPXBTList";
import FeaturedLinks from "./components/FeaturedLinks";
import FeaturedLink from "./components/FeaturedLinks/FeaturedLink";
import {TwoColumn} from "./components/Grid";
import ImageLink from "./components/ImageLink";
import AssetSummary from "./components/Layout/AssetSummary";
import Container from "./components/Layout/Container";
import {ETPFooter} from "./components/Layout/ETPFooter";
import ETPHero from "./components/Layout/ETPHero";
import {ETPNavbarActionButton} from "./components/Layout/ETPNavbarActionButton";
import Footer from "./components/Layout/Footer";
import Global from "./components/Layout/Global";
import Header from "./components/Layout/Header";
import IFrame from "./components/Layout/IFrame";
import {MenuNavigation} from "./components/Layout/MenuNavigation";
import Navigation from "./components/Layout/Navigation";
import NavigationItem from "./components/Layout/NavigationItem";
import TwoColumnRowSplit from "./components/Layout/TwoColumnRowSplit";
import LogoList from "./components/LogoList";
import Page from "./components/Page";
import PortfolioCard from "./components/PortfolioCard";
import ProfileCard from "./components/ProfileCard";
import ETPQuote from "./components/Quote/ETPQuote";
import RelatedNews from "./components/RelatedNews";
import ETPRichtextWithVideo from "./components/ETPRichtextWithVideo";
import Team from "./components/Team";
import TimeCard from "./components/TimeCard";
import Timeline from "./components/Timeline";
import "./index.css";
import "./styles/styles.scss";
import ETPBitcoinMarketCap from "./components/ETPBitcoinMarketCap";
import ETPArticleCategorySelector from "./components/ETPArticleCategorySelector";
import NewBranding from "./components/NewBranding";
import NewOfferingSummary from "./components/NewOfferingSummary";
import ETPReserveDetails from "./components/ETPReservesDetails";
import ETPMvrvGraph from "./components/ETPMvrvGraph/ETPMvrvGraph";
import BrokerContactForm from "./components/BrokerContactForm";

library.add(
  faTwitter,
  faLinkedin,
  faFacebook,
  faFacebookF,
  faInstagram,
  faYoutube,
  faGlobe,
  faChevronRight,
  faChevronLeft,
  faHouse,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faCopy,
  faPersonArrowDownToLine,
  faTentArrowDownToLine,
  faCircleInfo,
  faArrowUpRightFromSquare,
  faSquareArrowUpRight,
  faCheck,
  faXmark,
  faGear,
  faGears
);
initializeIcons();

const isStoryblokDraft = window.location.search.includes("_storyblok");
const token = isStoryblokDraft
  ? process.env.REACT_APP_PREVIEW_TOKEN
  : process.env.REACT_APP_PUBLIC_TOKEN;
const version = isStoryblokDraft ? "draft" : "published";

storyblokInit({
  accessToken: token,
  use: [apiPlugin],
  components: {
    announcements: Announcements,
    article: StoryArticle,
    articles: Articles,
    asset_breakdown_graph: AssetBreakdownGraph,
    asset_summary: AssetSummary,
    benchmark_graph: BenchmarkGraph,
    box_sets: BoxSets,
    breadcrumb: Breadcrumb,
    broker_selector: BrokerSelect,
    button: Button,
    button_strip: ButtonStrip,
    card: CarouselCard,
    card_set: CardSet,
    carousel: Carousel,
    column: Column,
    complex_table: ComplexTable,
    contact_form: ContactForm,
    container: Container,
    daily_hedging_position: DailyHedgingPosition,
    daily_hedging_position_graph: DailyHedgingPositionGraph,
    document_list: DocumentList,
    etp_all_products_button: ETPAllProductsButtons,
    etp_article_categories: ETPArticleCategories,
    etp_article_category_selector: ETPArticleCategorySelector,
    etp_article_link: ETPArticleLink,
    etp_articles: ETPArticles,
    etp_articles_selected: ETPArticlesSelected,
    etp_articles_search: ETPArticlesSearch,
    etp_asset_breakdown_graph: ETPAssetBreakdownGraph,
    etp_banner: ETPBanner,
    etp_big_number: ETPBigNumber,
    etp_bitcoin_effect: ETPBitcoinEffect,
    etp_broker_search: ETPBrokerSearch,
    etp_brokers: ETPBrokers,
    etp_btc_market_cap: ETPBitcoinMarketCap,
    etp_button: ETPButton,
    etp_choose_us: ETPChooseUs,
    etp_company_square: ETPCompanySquare,
    etp_disclaimer: ETPDisclaimer,
    etp_document_link: ETPDocumentLink,
    etp_documents_table: ETPDocumentsTable,
    etp_faq: ETPFAQ,
    etp_faqs: ETPFAQs,
    etp_featured_statement: ETPFeaturedStatement,
    etp_featured_statements: ETPFeaturedStatements,
    etp_footer: ETPFooter,
    etp_hero: ETPHero,
    etp_how_staking_works: ETPHowStakingWorks,
    etp_key_information: ETPKeyInformation,
    etp_leadership: ETPLeadership,
    etp_leading_the_way: ETPLeadingTheWay,
    etp_navbar_action_button: ETPNavbarActionButton,
    etp_newsletter: ETPNewsletter,
    etp_notification_banner: ETPNotificationBanner,
    etp_offer: ETPOffer,
    etp_offers: ETPOffers,
    etp_physical_list: ETPPhysicalList,
    etp_presentation: ETPPresentation,
    etp_product: ETPProduct,
    etp_product_card: ETPProductCard,
    etp_product_documents: ETPDocuments,
    etp_product_offer: ETPProductOffer,
    etp_proof_of_reserves: ETPProofOfReserves,
    etp_quick_navigation: ETPQuickNavigation,
    etp_quote: ETPQuote,
    etp_related_products: ETPRelatedProducts,
    etp_reserve_details: ETPReserveDetails,
    etp_reserve_widgets: ETPReserveWidgets,
    etp_reserves_explanation: ETPReservesExplanation,
    etp_security: ETPSecurity,
    etp_timecard: ETPTimeCard,
    etp_timeline: ETPTimeline,
    etp_video: ETPVideo,
    etp_xbt_card: ETPXBTCard,
    etp_xbt_list: ETPXBTList,
    etp_mvrv: ETPMvrvGraph,
    etps_total_amount_invested: ETPsTotalAmountInvested,
    exchange_list: ExchangeList,
    exchange_traded_product: Page,
    exchange_traded_product_list: ETPList,
    fair_value_calculation: FairValueCalculation,
    feature_statements: FeatureStatements,
    featured_link: FeaturedLink,
    featured_links: FeaturedLinks,
    featured_products: FeaturedProducts,
    footer: Footer,
    global: Global,
    header: Header,
    headline: Headline,
    iframe: IFrame,
    image_link: ImageLink,
    index: Page,
    index_form: IndexForm,
    index_list: IndexList,
    information: Information,
    inline_content: RichText,
    inline_table: InlineTable,
    investment_portfolio: InvestmentPortfolio,
    investor_cap_table: InvestorCapTable,
    key_information: KeyInformation,
    knowledge_bytes: KnowledgeBytes,
    knowledge_bytes_topic: KnowledgeBytesTopic,
    knowledge_category: Page,
    knowledge_view: KnowledgeView,
    legal_notice: Page,
    logo_list: LogoList,
    market_performance_graph: MarketPerformanceGraph,
    menu_navigation: MenuNavigation,
    nasdaq: Nasdaq,
    navigation: Navigation,
    navigation_item: NavigationItem,
    new_branding: NewBranding,
    new_offering_summary: NewOfferingSummary,
    newsletter_form: NewsLetterForm,
    offering_summary: OfferingSummary,
    page: Page,
    paragraph: Paragraph,
    popup_notice: PopupNotice,
    portfolio_card: PortfolioCard,
    product_alert: ProductAlert,
    profile_card: ProfileCard,
    quarterly_annual_reports_quarter: QuarterlyAnnualReportsQuarter,
    quote: Quote,
    related_news: RelatedNews,
    rich_text: RichText,
    richtext_with_video: ETPRichtextWithVideo,
    row: Row,
    similar_etps: SimilarETPs,
    social_media_icon: ImageLink,
    staking_rewards: StakingRewards,
    statistics_list: StatisticsList,
    structure_diagram: StructureDiagram,
    tab: Tab,
    tab_strip: TabStrip,
    team: Team,
    time_card: TimeCard,
    timeline: Timeline,
    twitter_feed: TwitterFeed,
    two_column: TwoColumn,
    two_column_row_split: TwoColumnRowSplit,
    volume: Volume,
    active_popup_notice: ActivePopUpNotice,
    active_asset_contact_form: ActiveAssetContactForm,
    partner_and_leader: PartnerAndLeader,
    active_asset_banner: ActiveAssetBanner,
    broker_contact_form: BrokerContactForm,
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_STORYBLOK_GRAPH_API,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      token: token,
      version: version,
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
