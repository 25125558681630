import React from 'react';

import { storyblokEditable } from '@storyblok/react';
import BackChevron from '../../icons/BackChevron.svg';
import { Link, useLocation } from 'react-router-dom';
import { logAction } from '../../actions';
import { etpLink } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Breadcrumb = ({ blok }) => {
  const { link, icon, title } = blok;
  const location = useLocation();

  if (link.id !== "") {
    return (
      <>
        <Link
          {...storyblokEditable(blok)}
          to={etpLink(link.cached_url) || '/'}

          onClick={() => logAction('navigation', { from: location.pathname, to: link.cached_url || '/' })}
        >
          {icon && icon.icon && <FontAwesomeIcon icon={`${icon.type} ${icon.icon}`} />}
          <span>{title}</span>
        </Link>
        <img src={BackChevron} alt='back' />
      </>
    );
  }
  return <>
    {link?.linktype === 'url' ? (<a {...storyblokEditable(blok)} href={link.url} >
      <img src={BackChevron} alt='back' /><span>{title}</span>
    </a>) : <span> {title} </span>}
  </>
};

export default Breadcrumb;
