import { Dropdown } from "@fluentui/react";
import ETPButton from "../ETPButton";
import { gql, useQuery } from "@apollo/client";
import {
  INVESTOR_TYPES,
  cookieNames,
  getCookie,
  getLanguage,
  otherOption,
  startsWith,
} from "../../utils";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { RichText } from "../RichText";

function ETPNewsletter({ blok, language }) {
  const {
    title,
    text,
    button_text,
    theme = "black",
    form_submitted_msg,
    email_text,
    language_text,
    country_text,
    broker_text,
    email_error_text,
    terms_and_condition,
  } = blok;
  const { data } = useQuery(optionsQuery, {
    variables: { startsWith: startsWith(language) },
  });
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedBroker, setSelectedBroker] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const location = useLocation();
  const [countries, brokers, languages] = useMemo(() => {
    const countries = (data?.CountryItems?.items ?? []).map(
      ({ slug, name, content }) => ({
        key: slug,
        text: name,
        code: content.code,
      })
    );
    const brokers = (data?.BrokerItems?.items ?? []).map(({ slug, name }) => ({
      key: slug,
      text: name,
    }));
    const languages = (data?.LanguageItems?.items ?? []).map(
      ({ slug, content }) => ({
        key: slug,
        text: content.language,
        code: content.code,
      })
    );
    countries.push(otherOption);
    return [countries, brokers, languages];
  }, [data]);

  useEffect(() => {
    const country = getCookie(cookieNames.LOCATION);
    if (country) {
      setSelectedCountry(countries?.find(({ key }) => key === country));
    }
    const language = languages?.find(
      (language) => language.code === getLanguage()
    );
    if (language) {
      setSelectedLanguage(language);
    }
  }, [countries, languages, location]);

  async function handleSubmit() {
    if (!selectedEmail || !selectedEmail.includes("@")) {
      setError(email_error_text);
    } else {
      try {
        await updateClientCustomerIO({
          email: selectedEmail,
          country: selectedCountry?.key,
          broker: selectedBroker?.text,
          language: selectedLanguage?.code,
        });
        setSubmitted(true);
      } catch (e) {
        console.error(e);
        setError("Sorry, something went wrong");
      }
    }
  }

  function handleCountryChange(e, option) {
    setSelectedCountry(option);
  }

  function handleBrokerChange(e, option) {
    setSelectedBroker(option);
  }

  function handleLanguageChange(e, option) {
    setSelectedLanguage(option);
  }

  function handleEmailChange(e) {
    setSelectedEmail(e.target.value?.toLowerCase());
  }

  let showNewsLetter = true;

  if (
    location.pathname.includes("/physical/") ||
    location.pathname.includes("/how-to-buy") || (location.pathname.includes("/xbt/") &&
    !location.pathname.includes("/xbt/landing"))
  ) {
    if (getCookie(cookieNames.INVESTOR_TYPE) === INVESTOR_TYPES.INSTITUTIONAL) {
      showNewsLetter = false;
    }
  }

  return (
    <>
      {showNewsLetter && (
        <div className={`widget ${theme} etp-newsletter`}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <div className={"me-3"}>
                  <div className={"label uppercase mb-4"}>{title}</div>
                  <h2 className="h3">{text}</h2>
                </div>
              </div>
              <div className={"col-lg-8"}>
                <form className={"etp-form"}>
                  <div className={"row mb-4"}>
                    <div className={"col-lg-6 etp-input-group"}>
                      <label>{email_text}*</label>
                      <input
                        required
                        type={"email"}
                        placeholder={email_text}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className={"col-lg-6 etp-input-group"}>
                      <label>{language_text}*</label>
                      <Dropdown
                        title={language_text}
                        placeholder={language_text}
                        options={languages}
                        selectedKey={selectedLanguage?.key}
                        onChange={handleLanguageChange}
                      />
                    </div>
                  </div>
                  <div className={"row mb-5"}>
                    <div className={"col-lg-6 etp-input-group"}>
                      <label>{country_text}*</label>
                      <Dropdown
                        title={country_text}
                        placeholder={country_text}
                        options={countries}
                        selectedKey={selectedCountry?.key}
                        onChange={handleCountryChange}
                      />
                    </div>
                    <div className={"col-lg-6 etp-input-group"}>
                      <label>{broker_text}</label>
                      <Dropdown
                        title={broker_text}
                        placeholder={broker_text}
                        options={brokers}
                        onChange={handleBrokerChange}
                      />
                    </div>
                  </div>
                  <div>
                    {submitted && (
                      <p className="text-end">{form_submitted_msg}</p>
                    )}
                    {!submitted && error && <p>{error}</p>}
                  </div>
                  <div className={"mb-4"}>
                    <div className={"d-flex newsletter_terms_and_condition"}>
                      <div className="col-lg-10">
                        <RichText inline={true} blok={terms_and_condition} />
                      </div>
                      <div className="col-lg-2 submit_button">
                        <ETPButton
                          blok={{ onClick: handleSubmit, text: button_text }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function sortByText(x, y) {
  return x.text.toLowerCase() < y.text.toLowerCase() ? -1 : 1;
}

const optionsQuery = gql`
  query ($startsWith: String) {
    CountryItems(starts_with: $startsWith, sort_by: "name:asc") {
      items {
        uuid
        content {
          title
          code
        }
        slug
        name
      }
    }

    BrokerItems(starts_with: $startsWith, sort_by: "name:asc") {
      items {
        uuid
        content {
          title
          logo {
            filename
          }
          link {
            cachedUrl
          }
          country
        }
        slug
        name
      }
    }

    LanguageItems(sort_by: "name:asc") {
      items {
        content {
          language
          code
        }
        slug
        uuid
      }
    }
  }
`;

export const updateClientCustomerIO = (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URI}/customer-io?ApiKey=${process.env.REACT_APP_API_KEY}`,
    data
  );
};

export default ETPNewsletter;
