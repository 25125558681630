import { gql, useQuery } from "@apollo/client";
import { StoryblokComponent } from "@storyblok/react";
import { cookieNames, getCookie, startsWith } from "../../utils";
import ETPLink from "../ETPLink";
import ETPProductCard, { useProductCardsData } from "../ETPProductCard";
import { etpNewSlug } from "../../constants";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";

function ETPXBTList({ blok, language }) {
  const {
    title,
    text,
    more_info,
    document_link,
    link_text,
    disclaimer,
    not_available_message,
  } = blok;
  const products = useProductCardsData(productsQuery, {
    variables: { startsWith: startsWith(language, `${etpNewSlug}/xbt`) },
  });
  const [productsUnavailable, setProductsUnavailable] = useState(true);
  const { data } = useQuery(query);
  const { LegalnoticeItems } = data || {};

  const xbtProductSD = useMemo(() => {
    return products.map((product) => {
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product.name,
        description: product.name,
        sku: product.isin,
        brand: {
          "@type": "Brand",
          name: "Coinshares",
        },
        image: [product.logo.filename],
        offers: {
          "@type": "Offer",
          priceCurrency: "EUR",
          price: product.aum.replace(/,/gi, "") || 0,
          availability: "https://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: "Coinshares",
          },
        },
      };
    });
  }, [products]);

  useEffect(() => {
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    let countryName = getCookie(cookieNames.LOCATION);
    if (legalNotices) {
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [LegalnoticeItems]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(xbtProductSD)}
        </script>
      </Helmet>
      <div className={"widget black"}>
        <div className={"container"}>
          <div className={"etp-xbt-list-products"}>
            <div className={"etp-xbt-list-title"}>
              <h2 className="h3">{title}</h2>
              <p>{text}</p>
            <div className="not-available-message">{productsUnavailable && <p>{not_available_message}</p>}</div>
            </div>
            {!productsUnavailable &&
              products?.map((product) => (
                <ETPProductCard
                  key={product.isin}
                  title={product.name}
                  isin={product.isin}
                  aum={product.aum}
                  fees={product.fee}
                  currencies={product.tradingCurrencies}
                  img_alt={product.name}
                  img_url={product.logo.filename}
                  page_url={product.full_slug}
                />
              ))}

            <div className="etp-xbt-list-more-info">
              <p>{more_info}</p>
              <ETPLink to={document_link.cached_url}>{link_text}</ETPLink>
            </div>
          </div>
          <div className={"mt-5"}>
            {disclaimer?.map((d) => (
              <StoryblokComponent blok={d} id={d._uid} key={d.uuid} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ETPXBTList;

const productsQuery = gql`
  query ($startsWith: String) {
    ExchangetradedproductItems(
      starts_with: $startsWith
      sort_by: "name:asc"
      filter_query: { type: { in: "6e3673f2-541c-4172-b147-45c46b3feaf9" } }
    ) {
      items {
        content {
          _uid
          name
          fee
          isin
          bloomberg
          type {
            content
            uuid
          }
          trading_currencies
          logo {
            filename
          }
        }
        id
        full_slug
      }
    }
  }
`;

const query = gql`
  query {
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;
