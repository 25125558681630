import { getCookie } from './utils';

export const logAction = (action, data) => {
  const visitor_id = getCookie('visitor_id882933');
  if (visitor_id) {
    fetch(`${process.env.REACT_APP_API_URI}/CustomActions?ApiKey=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{
        key: getCookie('visitor_id882933'),
        name: action,
        data: JSON.stringify({ source: 'website', ...data })
      }]),
    });
  }
};

export default logAction;