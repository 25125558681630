import ETPLink from "../ETPLink";

function FeaturedLink({ blok, index }) {
  const { title, text, link, link_text } = blok;

  return (
    <div className={'featured-link'}>
      <img src={linkImgUrl(index)} alt={'squares graphic'} className={'featured-link-graphic'}/>
      <div className={'featured-link-buffer'}/>
      <div className={'featured-link-body'}>
        <h3 className={'featured-link-title'}>{title}</h3>
        <p className={'featured-link-text'}>{text}</p>
        {link.id && <ETPLink to={link.cached_url}>{link_text}</ETPLink>}
       </div>
    </div>
  )
}

export default FeaturedLink;

function linkImgUrl(index) {
  switch (index) {
    case 0:
      return "/images/four_by_four_1.svg";
    case 1:
      return "/images/four_by_four_2.svg";
    default:
      return "/images/four_by_four_3.svg";
  }
}