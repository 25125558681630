import { StoryblokComponent } from "@storyblok/react";
import { useMemo } from "react";
import { Helmet } from "react-helmet";

function ETPFAQs({ blok }) {
  const { title, faqs } = blok;

  const FAQ = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqs.map((q) => {
        return {
          "@type": "Question",
          "name": q.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": q.answer
          }
        }
      })
    }
  }, [blok])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(FAQ)}</script>
      </Helmet>
      <div className={'widget gray'}>
        <div className={'container'}>
          <div className={'row'}>
            <h2 className={'col-lg-5 faqs-title'}>
              <span className={'etp-label'}>
                { title ? title : 'Frequently asked questions'}
              </span>
            </h2>
            <div className={'col-lg-7 etp-faqs'}>
              {faqs?.map(faq => <StoryblokComponent blok={faq} id={faq._uid} key={faq._uid} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ETPFAQs;