import { gql, useQuery } from "@apollo/client";
import { Dropdown } from "@fluentui/react";
import { StoryblokComponent } from "@storyblok/react";
import { useEffect, useMemo, useState, useRef } from "react";
import { cookieNames, getCookie, otherOption, startsWith } from "../../utils";
import ETPPaginationControls from "../ETPPaginationControls";
import ETPPillOptions from "../ETPPillOptions";
import ETPProductCard, { useProductCardsData } from "../ETPProductCard";
import { etpNewSlug } from "../../constants";
import { Helmet } from "react-helmet";

const perPage = 9;

function ETPPhysicalList({ blok, language }) {
  const { title, text, document_link, disclaimer, not_available_message } =
    blok;
  const [page, setPage] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedTag, setSelectedTag] = useState("All");
  const [productsUnavailable, setProductsUnavailable] = useState(false);
  // const [countryName, setCountryName] = useState('');
  const productContainer = useRef(null);

  const products = useProductCardsData(productsQuery, {
    variables: { startsWith: startsWith(language, `${etpNewSlug}/physical`) },
  });
  const { data: optionsData } = useQuery(optionsQuery, {
    variables: { startsWith: startsWith(language) },
  });

  const filteredProducts = filterProducts(
    products,
    selectedTag,
    selectedCountry
  );
  const productsForPage = filteredProducts?.slice(
    page * perPage,
    page * perPage + perPage
  );
  const totalPages = Math.ceil((filteredProducts?.length ?? 0) / perPage);
  const { LegalnoticeItems } = optionsData || {};

  const countryOptions = optionsData?.CountryItems?.items
  ?.map(({ content, uuid }) => ({ text: content.title, key: uuid }))
  ?.sort(sortByText)

  countryOptions?.push(otherOption);

  useEffect(() => {
    if (countryOptions) {
      const location = getCookie(cookieNames.LOCATION);
      if (location === otherOption.key) {
        if (selectedCountry?.key !== otherOption.key) {
          setSelectedCountry(otherOption);
        }
      } else {
        const countryOption = optionsData?.CountryItems?.items?.find(
          (item) => item.slug === location
        );
        if (countryOption && !selectedCountry?.key) {
          setSelectedCountry({
            text: countryOption.content.title,
            key: countryOption.uuid,
          });
        }
      }
    }
  }, [optionsData, countryOptions, selectedCountry?.key]);

  useEffect(() => {
    const legalNotices = LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    if (legalNotices && investorType) {
      let countryName = selectedCountry?.text
        ?.toLowerCase()
        .split(" ")
        .join("-");
      const legalNotice = legalNotices.find(
        (o) => o.slug === `${investorType}-${countryName}`
      );
      setProductsUnavailable(!legalNotice);
    }
  }, [LegalnoticeItems, selectedCountry]);

  function handleProductTypeChange(event, option) {
    setPage(0);
    setSelectedTag(option.key);
  }

  const filterMessage = filteredProducts?.length === 0 || productsUnavailable;

  const changePage = (newPage) => {
    productContainer.current.scrollIntoView({behavior: 'smooth' });

    setTimeout(() => {
      setPage(newPage);
    }, 600);
  };

  const physicalProductSD = useMemo(() => {
    return productsForPage.map((product) => {
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product.name,
        description: product.name,
        sku: product.isin,
        brand: {
          "@type": "Brand",
          name: "Coinshares",
        },
        image: [product.logo.filename],
        offers: {
          "@type": "Offer",
          priceCurrency: "EUR",
          price: product.aum.replace(/,/gi, "") || 0,
          availability: "https://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: "Coinshares",
          },
        },
      };
    });
  }, [productsForPage]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(physicalProductSD)}
        </script>
      </Helmet>
      <div className={"widget black"}>
        <div className={"container"}>
          <div className={"etp-physical-list-title row"}>
            <div className={"col-lg-4"}>
              <h2 className={'label text-uppercase'}>{title}</h2>
            </div>
            <div className={"col-lg-8"}>
              <h3>{text}</h3>
            </div>
          </div>
          <div className={"m-5 row"}>
            <div className={"col-12"}>
              <div className={"etp-physical-list-options"} ref={productContainer}>
                <Dropdown
                  options={countryOptions}
                  selectedKey={selectedCountry?.key}
                  placeholder="Country"
                  onChange={(_, option) => {
                    console.log("option", option);
                    setSelectedCountry(option);
                  }}
                />
                <ETPPillOptions
                  options={indexOptions}
                  placeholder="Product type"
                  selectedKey={selectedTag}
                  onChange={handleProductTypeChange}
                />
              </div>
            </div>
          </div>
          <div className={"etp-physical-products"}>
            {productsForPage?.map((product) => (
              <ETPProductCard
                key={product.isin}
                title={product.name}
                isin={product.isin}
                aum={product.aum}
                fees={product.fee}
                currencies={product.tradingCurrencies}
                img_alt={product.name}
                img_url={product.logo.filename}
                page_url={product.full_slug}
              />
            ))}
            {filterMessage && <div>{not_available_message}</div>}
          </div>
          <div className={"m-5 row"}>
              <div className={"col-12 d-flex"}>
                <ETPPaginationControls
                  page={page}
                  pages={totalPages}
                  onClick={changePage}
                />
              </div>
          </div>
          <div className={"mt-5"}>
            {document_link?.map((dl) => (
              <StoryblokComponent blok={dl} id={dl._uid} key={dl._uid} />
            ))}
          </div>
          <div className={"mt-5"}>
            {disclaimer?.map((d) => (
              <StoryblokComponent blok={d} id={d._uid} key={d._uid} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ETPPhysicalList;

function sortByText(x, y) {
  return x.text < y.text ? -1 : 1;
}

// TODO: this should be fetched from storyblok api
const indexOptions = [
  { key: "All", text: "All" },
  { text: "Staked", key: "Staked" },
  { text: "Indexes", key: "Index" },
];

function filterProducts(products, selectedTag) {
  return products
    .filter(({ tag }) => selectedTag === "All" || selectedTag === tag)
}

const productsQuery = gql`
  query ($startsWith: String) {
    ExchangetradedproductItems(starts_with: $startsWith) {
      items {
        content {
          _uid
          name
          fee
          isin
          bloomberg
          type {
            content
            uuid
          }
          trading_currencies
          logo {
            filename
          }
        }
        id
        full_slug
      }
    }
  }
`;

const optionsQuery = gql`
  query ($startsWith: String) {
    CountryItems(starts_with: $startsWith) {
      items {
        uuid
        slug
        content {
          title
        }
      }
    }
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;
