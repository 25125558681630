import React from 'react'

import { storyblokEditable } from '@storyblok/react'

export const StructureDiagram = ({blok}) => {
    const { desktop_image, mobile_image } = blok

    return <div {...storyblokEditable(blok)}>
        <img className='mobile-image' width={'100%'} src={mobile_image.filename} alt={mobile_image.alt}/>
        <img className='desktop-image' width={'100%'} src={desktop_image.filename} alt={desktop_image.alt} />
    </div>
}

export default StructureDiagram