import React from 'react';
import './Footer.scss';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { etpLink } from '../../utils';
import { Link } from 'react-router-dom';

const Footer = ({ blok, language }) => {
  const { logo, copyright, social, navigation, registredText, infoText } = blok;

  return (
    <div {...storyblokEditable(blok)} className={`footer-container d-flex flex-column`} style={{ gap: '7.5rem' }}>
      <div className={`d-flex flex-md-row flex-column justify-space-between`} style={{ gap: '2rem' }}>
        <div className={`d-flex flex-lg-row flex-column align-items-baseline`} style={{ gap: '1.25rem' }}>
          {logo.map((logo) => (
            <StoryblokComponent blok={logo} key={logo._uid} language={language} />
          ))}
          <small className={`small`}>{copyright}</small>
        </div>
        <div className={`d-flex`} style={{ gap: '1.25rem' }}>
          {social.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} language={language} />
          ))}
        </div>
      </div>
      <div className={`d-flex flex-wrap justify-content-center justify-content-lg-between w-100 lt-lg-gap-2 navigation`}>
        {navigation.map((navitem) => (
          navitem.link?.linktype === 'story' ? <Link
            key={navitem._uid}
            className='navigation-item navigate'
            to={etpLink(navitem.link.cached_url)}
            target={navitem.link?.target}
          >
            {navitem.text}
          </Link> : (<a
            key={navitem._uid}
            className='navigation-item navigate'
            href={etpLink(navitem.link?.cached_url)}
            target={navitem.link?.target}
          >
            {navitem.text}
          </a>)
        ))}
      </div>
      <div className={`d-flex flex-column`} style={{ gap: '1.5rem' }}>
        <small className={`small`}>{registredText}</small>
        <p className={`info-text`}>{infoText}</p>
      </div>
    </div>
  );
};

export default Footer;
