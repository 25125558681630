import React from 'react'

export const InlineTable = ({ blok }) => {
	const { table, title } = blok
	const { thead, tbody } = table

	return (
		<>
			{title && <h5>{title}</h5>}
			<table>
				<thead>
					<tr>
						{thead.map((th) => (
							<th key={th._uid}>{th.value}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tbody.map((tr) => (
						<tr key={tr._uid}>
							{tr.body.map((td) => (
								<td key={td._uid}>{td.value}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

export default InlineTable
