import ETPLink from "../ETPLink";

function ETPArticleLink({blok}) {
  const {title, link, link_text} = blok;

  return (
    <div className={'etp-article-link'}>
      <h4>{title}</h4>
      <ETPLink to={link.cached_url}>{link_text}</ETPLink>
    </div>
  );
}

export default ETPArticleLink;