import ETPBigNumber from '../ETPBigNumber';
import { useEffect, useState } from 'react';
import { apiWidgetUrl } from '../../constants';
import { format } from 'd3';

function ETPBitcoinMarketCap({ blok }) {
  const { text_one, info_text_one, tooltip_text_one, info_text_two, tooltip_text_two } = blok;
  const [btcMarketCap, setBtcMarketCap] = useState('');

  useEffect(() => {
    fetch(`${apiWidgetUrl}&names=MARKET_CAP_BTC`)
      .then(res => res.json())
      .then(res => {
        const marketCap = res[0].sections[0].meta.find(({ key }) => key === 'MarketCap').value.replaceAll(',', '');
        setBtcMarketCap(`$${format('.3s')(Number(marketCap)).replace('G', ' billion')}`);
      });
  }, []);

  return (
    <div className='etp-btc-market-cap'>
      <ETPBigNumber
        blok={{
          primary_text: text_one,
          info_text: info_text_one,
          info_tooltip_text: tooltip_text_one
        }}
      />
      <ETPBigNumber
        blok={{
          primary_text: btcMarketCap,
          info_text: info_text_two,
          info_tooltip_text: tooltip_text_two
        }}
      />
    </div>
  );
}

export default ETPBitcoinMarketCap;