function ETPTimeCard({ blok }) {

  const splitText = blok.text.split('\n');


  return (
    <div className='etp-timecard'>
      <h3>{blok.title}</h3>
      {splitText.length === 1 ? (
        <span>{blok.text}</span>
      ) : (
        <ul>
          {splitText.map(text => <li key={text}>{text}</li>)}
        </ul>
      )}
    </div>
  );
}

export default ETPTimeCard;