import { StoryblokComponent } from "@storyblok/react";
import { cookieNames, getCookie } from "../../utils";
import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

function FeaturedLinks({ blok, language }) {
  const { title, links } = blok;

  const { data } = useQuery(query, {
    variables: { language: language !== "en" ? `${language}/*` : undefined },
  });
  const { LocationandinvestortypeItems } = data || {};

  const currentInvestorDetails = useMemo(() => {
    const items =
      LocationandinvestortypeItems?.items.find(
        (item) =>
          item.slug === getCookie(cookieNames.LOCATION_AND_INVESTOR_TYPE)
      )?.content || {};
    return items;
  }, [LocationandinvestortypeItems]);

  return (
    <>
      {currentInvestorDetails?.show_fund && (
        <div className={"container featured-links widget"}>
          <h2 className={"featured-links-title"}>{title}</h2>
          <div className={"row"}>
            {links.map((blok, index) => (
              <StoryblokComponent blok={blok} key={index} index={index} />
            ))}
          </div>
        </div>
      )}
      {/* for etp */}
      {!getCookie(cookieNames.LOCATION_AND_INVESTOR_TYPE) && (
        <div className={"container featured-links widget"}>
          <h2 className={"featured-links-title"}>{title}</h2>
          <div className={"row"}>
            {links.map((blok, index) => (
              <StoryblokComponent blok={blok} key={index} index={index} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default FeaturedLinks;

const query = gql`
  query {
    LocationandinvestortypeItems {
      items {
        uuid
        slug
        content {
          name
          language {
            slug
          }
          show_fund
        }
      }
    }
  }
`;
