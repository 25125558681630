import { gql } from '@apollo/client';
import { StoryblokComponent } from '@storyblok/react';
import ETPProductCard, { useProductCardsData } from '../ETPProductCard';

function ETPRelatedProducts({ blok }) {
  const { title, text, button, disclaimer, products } = blok;
  const data = useProductCardsData(productsQuery, { variables: { products, uuids: products?.join(',') } });

  return (
    <div className={'widget white'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-4'}>
            <h2>{title}</h2>
            <p className={'etp-related-products-text'}>{text}</p>
            {button?.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok.uuid}/>)}
            <p className={'etp-related-products-disclaimer'}>{disclaimer}</p>
          </div>
          <div className={'col-lg-8 etp-related-products-list'}>
            {data?.map(cd => (
              <ETPProductCard
                key={cd.isin}
                title={cd.name}
                fees={cd.fee}
                currencies={cd.tradingCurrencies}
                isin={cd.isin}
                page_url={cd.full_slug}
                aum={cd.aum}
                img_url={cd.logo.filename}
                img_alt={cd.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPRelatedProducts;

const productsQuery = gql`
  query($startsWith: String, $uuids: String) {
    ExchangetradedproductItems(starts_with: $startsWith, by_uuids: $uuids) {
      items {
        content {
          name
          isin
          is_featured
          trading_currencies
          logo {
            filename
            alt
          }
        }
        full_slug
      }
    }
  }
`;
