import ETPLink from '../ETPLink';

function NewOfferingCard({link, linkTitle, label, labelValue, text, isNotEtp, hide_bottom_text}) {

  return (
    <div className='new-offering-card'>
      <ETPLink to={link} isNotEtp={isNotEtp} className='new-offering-card-link' imgSize={20}>{linkTitle}</ETPLink>
      {text && <div className='new-offering-card-description'>{text}</div>}
      {!hide_bottom_text && (
        <div className='new-offering-card-stats'>
          <span className='new-offering-card-stats-label'>{label}</span>
          <span className='new-offering-card-stats-value'>{labelValue}</span>
        </div>
      )}
    </div>
  );
}

export default NewOfferingCard;

