import React, {useMemo} from 'react'
import {gql, useQuery} from '@apollo/client'

import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import {Article} from '.'
import {Scrollbar} from '../.'
import * as d3 from 'd3'

export const Articles = ({blok, language}) => {
    const {
        anchor_key,
        call_to_action,
        layout = 'layout_1',
        title,
        item_limit = '4',
        description,
        background,
        primary_topic,
        additional_topics,
        categories,
        article_types,
        crypto_types,
        product_types
    } = blok

    const filterQuery = useMemo(() => {
        const currentDate = d3.utcFormat('%Y-%m-%d %H:%M')(new Date())
        const filters = [`release_date: { lt_date: "${currentDate}" }`, `expiry_date: { gt_date: "${currentDate}" }`]

        if (primary_topic) {
            filters.push(`primary_topic: { in: "${primary_topic}" }`)
        }

        if (additional_topics && additional_topics.length > 0) {
            filters.push(`additional_topics: { in_array: "${additional_topics.join(',')}" }`)
        }

        if (article_types && article_types.length > 0) {
            filters.push(`article_type: { in: "${article_types.join(',')}" }`)
        }

        if (categories && categories.length > 0) {
            filters.push(`category: { in: "${categories.join(',')}" }`)
        }
        if (crypto_types && crypto_types.length > 0) {
            filters.push(`crypto_types: { in_array: "${crypto_types.join(',')}" }`)
        }
        if (product_types && product_types.length > 0) {
            filters.push(`product_types: { in_array: "${product_types.join(',')}" }`)
        }

        return `, filter_query: {${filters.join(',')}}`
    }, [additional_topics, article_types, categories, crypto_types, primary_topic, product_types])

    const query = gql`
    {
        ArticleItems (${language !== 'en' && !!language ? `starts_with: "${language}/*",` : ''} per_page: ${item_limit}, sort_by: "sort_by_date:desc"${filterQuery}) {
            items {
                content {
                    title
                    image {
                        filename
                    }
                    primary_topic {
                        content
                    }
                    author {
                        name
                    }
                }
                uuid
                full_slug
                first_published_at
            }
        }
    }
`

    const { /*loading, error,*/ data} = useQuery(query)

    const {ArticleItems} = data || {}

    const articleItems = useMemo(() => {
        if (ArticleItems) {
            return ArticleItems.items.flatMap((item) => {
                return {
                    uuid: item.uuid,
                    full_slug: item.full_slug,
                    datePublished: item.first_published_at,
                    ...item.content,
                    author: item.content.author.name !== null ? item.content.author.name : 'No Author',
                    tags: [item.content.primary_topic?.content !== null ? item.content.primary_topic?.content?.title : ''].filter((o) => o),
                    img: item.content?.image?.filename,
                }
            })
        }

        return []
    }, [ArticleItems])

    return (
        <div className={`${background.toLowerCase()} ${layout === 'layout_2' ? 'row' : ''}`} id={anchor_key}>
            <div {...storyblokEditable(blok)} className='container'>
                <div className={`row articles ${layout || 'layout_1'}`}>
                    {(layout === '' || layout === 'layout_1') && (
                        <>
                            <div className='col-lg-6 col-12'>
                                <h2>{title}</h2>
                            </div>
                            <div className='col-6 articles__more' style={{paddingTop: 40}}>
                                {call_to_action.map((blok) => (
                                    <StoryblokComponent blok={blok} key={blok._uid}/>
                                ))}
                            </div>

                            <div className='col-12 mt-lg-4'>
                                <div className='row'>
                                    {articleItems.map((article) => (
                                        <div key={article.uuid} className='col-lg-3 col-md-6 col-sm-12'>
                                            <Article article={article}/>
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </>
                    )}

                    {layout === 'layout_2' && (
                        <>
                            <div className='col'>
                                <h5 className='label'>{title}</h5>
                                <Scrollbar>
                                    <div className='related-articles'>
                                        {articleItems.map((article) => (
                                            <Article key={article.uuid} article={article}/>
                                        ))}
                                    </div>
                                </Scrollbar>
                            </div>
                        </>
                    )}

                    {layout === 'layout_3' && (
                        <>
                            <div className='col-4'>
                                <h5 className='label'>{title}</h5>
                                <p>{description}</p>
                            </div>
                            <div className='col-8'>
                                <Scrollbar>
                                    <div className='articles-horizontal'>
                                        {articleItems.map((article) => (
                                            <Article key={article.uuid} article={article}/>
                                        ))}
                                    </div>
                                </Scrollbar>
                            </div>
                        </>
                    )}

                    {layout === 'layout_6' && (
                        <>
                            <div className='col-lg-4 col-12'>
                                <h5 className='label'>{title}</h5>
                            </div>
                            <div className='col-lg-8 col-12'>
                                <p className='pull-quote'>{description}</p>
                            </div>
                            <div className='col-12'>
                                <Scrollbar>
                                    <div className='articles-horizontal'>
                                        {articleItems.map((article) => (
                                            <Article key={article.uuid} article={article}/>
                                        ))}
                                    </div>
                                </Scrollbar>


                            </div>

                            {call_to_action && call_to_action.length > 0 &&
                                <div className='col-12' style={{paddingTop: 50}}>
                                    {call_to_action.map((blok) => (
                                        <StoryblokComponent blok={blok} key={blok._uid}/>
                                    ))}
                                </div>
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Articles
