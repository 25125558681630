import React, { useState, useEffect, useMemo } from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { useQuery, gql } from '@apollo/client';
import useHash from '../../hooks/useHash';
import ChevronLarge from '../../icons/ChevronLargeDark.svg';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, DirectionalHint } from '@fluentui/react';
import logAction from '../../actions';
import { apiWidgetUrl, countryKey, getJsonRequest } from '../../constants';
import axios from 'axios';

const infoTabs = [
  { key: '5', text: 'Index' },
  { key: '0', text: 'Listing & Codes' },
  { key: '1', text: 'Structure' },
  { key: '2', text: 'Documents' },
  { key: '3', text: 'Key Service Provider' },
  { key: '4', text: 'Legal & Tax' },
];

export const KeyInformation = ({ blok, isin, language }) => {
  const { anchor_key, title, document_list, documents_link, documents } = blok;
  const location = useLocation();
  const ref = useHash(anchor_key);

  const [selectedKey, setSelectedKey] = useState('0');
  const [index, setIndex] = useState();
  const [structure, setStructure] = useState();
  const [legalAndTax, setLegalAndTax] = useState();
  const [keyserviceProvider, setKeyserviceProvider] = useState();
  const [listingCodes, setListingCodes] = useState();
  const [availableCountries, setAvailableCountries] = useState([]);

  const query = gql`
    {
      DatasourceEntries(datasource: "key-translations", dimension: "${language}", per_page: 1000) {
        items {
          name
          dimensionValue
          value
        }
      }
      CountryItems {
        items {
          uuid
          name
          slug
          content {
            icon {
              filename
              alt
            }
            title
          }
        }
      }
    }
  `;
  const { data } = useQuery(query);
  const { DatasourceEntries } = data || {};

  const datasourceEntries = useMemo(() => {
    if (DatasourceEntries) return DatasourceEntries.items;

    return [];
  }, [DatasourceEntries]);

  const countries = data?.CountryItems?.items;

  useEffect(() => {
    if (isin) {
      const keyStatsKey = `ISIN_KEYSTATS_${isin}`;
      const listCodesKey = `ISIN_LISTCODES_${isin}`;
      const listInfoKey = `ETP_LISTINFO_${isin}`;

      axios(`${apiWidgetUrl}&names=${keyStatsKey},${listCodesKey},${listInfoKey}`)
        .then(({ data }) => {
          const widget = data.find((v) => v.key === keyStatsKey);
          setStructure(widget.sections.find((v) => v.key === `${isin}_STRUCTURE`)?.meta);
          const indexMeta = widget.sections.find((v) => v.key === `${isin}_INDEX`)?.meta;
          if (indexMeta) {
            setSelectedKey('5');
          }
          setIndex(indexMeta);
          setKeyserviceProvider(widget.sections.find((v) => v.key === `${isin}_KEYSERVICEPROVIDER`)?.meta);
          setLegalAndTax(widget.sections.find((v) => v.key === `${isin}_LEGALANDTAX`)?.meta);

          const listingCodes = data
            .find((v) => v.key === listCodesKey)
            .sections.flatMap((o) =>
              Object.assign(
                {},
                ...o.meta.map((m) => {
                  return { [m.key]: m.value };
                })
              )
            );

          setListingCodes(listingCodes);

          const isinCountries = new Set(JSON.parse(data
            .find(d => d.key === listInfoKey).sections[0].meta
            .find(m => m.key === 'brokerCountryInvestorMapping')
            .value)
            .map(m => m.country));

          setAvailableCountries([...isinCountries].sort());
        });
    }
  }, [isin]);

  return (
    <div className='black' id={anchor_key} ref={ref}>
      <div {...storyblokEditable(blok)} className='widget'>
        <div className='container'>
          <div className={'row key-information-title'}>
            <div className={'col'}>
              <h2 className='h3'>{title}</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='key-information-options'>
                <div className='key-information-dropdown'>
                  <Dropdown
                    calloutProps={{
                      directionalHint: DirectionalHint.bottomLeftEdge,
                      directionalHintFixed: true,
                    }}
                    className='tab-dropdown'
                    options={infoTabs}
                    styles={{
                      dropdownItemSelected: {
                        background: 'white',
                        color: '#122444',
                      },
                    }}
                    selectedKey={selectedKey}
                    onChange={(ev, option) => setSelectedKey(option.key)}
                  />
                </div>
                <div className='key-information-selectors'>
                  {infoTabs
                    .filter((o) => (index === undefined ? o.key !== '5' : o))
                    .map((o) => (
                      <button
                        key={o.key}
                        className={`button button--tag ${selectedKey === o.key ? 'is-active' : ''}`}
                        onClick={() => setSelectedKey(o.key)}
                      >
                        <h3 className=''>{o.text}</h3>
                      </button>
                    ))}
                </div>
              </div>
              <div className='key-information-content'>
                {selectedKey === '5' && index && (
                  <div className='row'>
                    {index.map((o) => {
                      const fieldName = datasourceEntries.find((de) => de.name === o.key);
                      return (
                        <div key={o.key} className='col-12 col-md-6'>
													<span className='key-information-index'>
														<span className='text-bold'>{fieldName?.dimensionValue || fieldName?.value || o.key}:</span>
														<span>{index.find((i) => i.key === o.key)?.value}</span>
													</span>
                        </div>
                      );
                    })}
                  </div>
                )}
                {selectedKey === '0' && listingCodes && (
                  <div>
                    <div className='grid-table'>
                      <div className='grid-headers'>
                        <div className='grid-header'>Country</div>
                        <div className='grid-header'>Exchange</div>
                        <div className='grid-header'>Trading Currency</div>
                        <div className='grid-header'>Bloomberg Code</div>
                        <div className='grid-header'>ISIN</div>
                        <div className='grid-header'>SEDOL</div>
                        <div className='grid-header'>WKN</div>
                        <div className='grid-header'>Listing Date</div>
                      </div>
                      {listingCodes.map((o, i) => (
                        <div className='grid-row' key={i}>
                          <div className='grid-column'>Country</div>
                          <div className='grid-value'>{o.country}</div>
                          <div className='grid-column'>Exchange</div>
                          <div className='grid-value'>{o.exchangeName}</div>
                          <div className='grid-column'>Trading Currency</div>
                          <div className='grid-value'>{o.Currency}</div>
                          <div className='grid-column'>Bloomberg Code</div>
                          <div className='grid-value'>{o.bloombergCode}</div>
                          <div className='grid-column'>ISIN</div>
                          <div className='grid-value'>{o.isin}</div>
                          <div className='grid-column'>SEDOL</div>
                          <div className='grid-value'>{o.sedol}</div>
                          <div className='grid-column'>WKN</div>
                          <div className='grid-value'>{o.wkn}</div>
                          <div className='grid-column'>Listing Date</div>
                          <div className='grid-value'>{o.listingDate}</div>
                        </div>
                      ))}
                    </div>

                    {documents_link && documents_link.cached_url && (
                      <Link
                        className='document-link'
                        to={'/' + documents_link.cached_url.replace(/^\//g, '')}
                        onClick={() => logAction(
                          'navigation',
                          { from: location.pathname, to: '/' + documents_link.cached_url.replace(/^\//g, '') }
                        )}
                      >
                        <span>We publish supporting documents to accompany our ETPs</span>
                        <span style={{ display: 'flex', columnGap: 40, alignItems: 'center' }}>
													View ETP Documents <img style={{ height: 42 }} src={ChevronLarge} alt='forward'/>
												</span>
                      </Link>
                    )}
                  </div>
                )}
                {selectedKey === '1' && structure && (
                  <div>
                    <table>
                      <tbody>
                      {structure.map((o) => {
                        const fieldName = datasourceEntries.find((de) => de.name === o.key);
                        return (
                          <tr key={o.key}>
                            <td>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
                            <td>{o.value}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                )}
                {selectedKey === '2' && 
                  <div className='documents-list'>
                    <div className='row'>
                      {documents && documents.map((blok) => (
                        <div class="col-12">
                          <a href={blok?.static_link || blok?.assets_link?.filename || ''} class="document" target="_blank" alt="" rel="noreferrer">
                            <span>{blok?.name}</span> 
                            <img src="/images/DownloadIconLight.svg" alt="download" />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>}
                {selectedKey === '3' && keyserviceProvider && (
                  <div>
                    <table>
                      <tbody>
                      {keyserviceProvider.map((o) => {
                        const fieldName = datasourceEntries.find((de) => de.name === o.key);
                        return (
                          <tr key={o.key}>
                            <td>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
                            <td>{o.value}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                )}

                {selectedKey === '4' && legalAndTax && (
                  <div>
                    <table>
                      <tbody>
                      {legalAndTax.map((o) => {
                        const fieldName = datasourceEntries.find((de) => de.name === o.key);
                        return (
                          <tr key={o.key}>
                            <td>{fieldName?.dimensionValue || fieldName?.value || o.key}</td>
                            <td>{o.value}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={'row mt-5'}>
            <div className={'col-sm-3'}><span className={'label'}>Available in</span></div>
            <div className={'col-sm-9'}>
              <div className='key-information-countries'>
                {availableCountries.map(name => {
                  const data = countries?.find((c) => c.name === name);
                  if (!data?.content?.icon) {
                    return null;
                  }
                  return (
                    <div className={'key-information-country'} key={name}>
                      <img src={data.content.icon.filename} alt={data.content.icon.alt} height={'16px'} width={'16px'}/>
                      <span>{data.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='container pattern-wrapper'><img src='/images/pattern-black-gray-bottom.svg' className='pattern pattern-bottom' alt='' /></div> */}
    </div>
  );
};

export default KeyInformation;
