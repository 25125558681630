import { etpNewSubdirectory, etpSubdirectory, etpSubdomain } from './constants';

function nth(date) {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

/**
 * Formats a date like this: 23rd April 2022
 * @param dateString {string}
 * @returns {undefined|string}
 */
export function formatDate(dateString) {
  try {
    const date = new Date(dateString);
    return `${date.getDate()}${nth(date.getDate())} ${date.toLocaleString(
      'default',
      { month: 'short' }
    )} ${date.getFullYear()}`;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

/**
 * Format language for graphql queries
 * @param language {string}
 * @param [folder] {string}
 * @returns {string}
 */
export function startsWith(language, folder) {
  return language !== 'en' ? `${language}/${folder ?? '*'}` : folder;
}

export function copyIsin({ isin, title }) {
  navigator.clipboard.writeText(isin);
  window?.dataLayer.push({
    event: 'isin_copy',
    product_name: title,
  });
}

export function copyBloomberg({ bloomberg, title }) {
  navigator.clipboard.writeText(bloomberg);
  window?.dataLayer.push({
    event: 'bloomberg_copy',
    product_name: title,
  });
}

export function makeURLFromRoot(path) {
  return (path[0] !== '/') ? `/${path}` : path;
}

export const setCookie = (cname, cvalue = '', exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + '; path=/';
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function clearCookies() {
  Object.values(cookieNames).forEach(name => setCookie(name, '', new Date(0)));
}

export const cookieNames = {
  INVESTOR_TYPE: 'investor_type',
  LOCATION: 'location',
  LOCATION_AND_INVESTOR_TYPE: 'location_and_investor_type'
};

export function etpLink(link) {
  return link.replace(`${etpSubdirectory}/`, '').replace(`${etpNewSubdirectory}/`, '').replace('home', '');
}

export function isETPSubdomain() {
  return window.location.host.split('.').includes(etpSubdomain);
}

export function setLanguage(language) {
  window.localStorage.setItem('language', language);
}

export function getLanguage() {
  return window.localStorage.getItem('language');
}

export const otherOption = { key: 'other', text: 'Other' };

export const activeAssetOtherOption= { key: 'Other Countries/Investor Types', text: 'Other' };

export const otherHedgeFundSolution='other-countries-investor-types'

export const INVESTOR_TYPES={
  INSTITUTIONAL:"institutional",
  INDIVIDUAL:"individual",
}