import React, { useEffect, useMemo, useState } from 'react'

import { storyblokEditable } from '@storyblok/react'

export const Volume = ({ blok }) => {
	const { anchor_key, background, rotation_keys, rotation_titles } = blok
	const [volumes, setVolumes] = useState([])
	// const [indexToShow, setIndexToShow] = useState(0)
	// const indexBounce = useDebounce(indexToShow, 5000)
	const titles = useMemo(() => rotation_titles.split(','), [rotation_titles])
	const keys = useMemo(() => rotation_keys.split(','), [rotation_keys])

	// useEffect(() => {
	// 	setIndexToShow((index) => index + 1)
	// }, [indexBounce])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=CAPITAL_MARKETS`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((value) => {
			value.json().then((value) => {
				const capitalMeta = value.find((v) => v.key === 'CAPITAL_MARKETS')?.sections.find((v) => v.key === 'CAPITAL_MARKETS')?.meta
				setVolumes(capitalMeta)
			})
		})
	}, [])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row volume-container'>
						{titles.map((o, i) => {
							const meta = volumes.find((o) => keys[i] === o.key)
							return (
								<div key={o.key} className={`volume col-md-4`}>
									<div className='volume-title'>{o}</div>
									<h2>
										{meta?.value}
										{meta?.suffix ? <span> {meta.suffix}</span> : <></>}
									</h2>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Volume
