import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const LinkedinShare = (props) => {
	const url = `url=${props.url || ''}`
	const title = `&title=${document.title}`
	const summary = props.summary ? `&summary=${props.summary}` : ''
	const shareUrl = encodeURI(`https://www.linkedin.com/shareArticle?mini=true&${url}${title}${summary}`)

	return (
		<a
			href={shareUrl}
			target='popup'
			style={props.style}
			className={props.className}
            title={props.title}
			onClick={() => {
				window.open(shareUrl, 'popup', 'width=480,height=680')
				return false
			}}
		>
			<FontAwesomeIcon icon={'fab fa-linkedin-in'} />
		</a>
	)
}

export default LinkedinShare
