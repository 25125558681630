import React, { useMemo } from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { Exchange } from '.'
import { Scrollbar } from '../.'
import { useQuery, gql } from '@apollo/client'

export const ExchangeList = ({ blok, language }) => {
	const { anchor_key, background, call_to_action, description, title, exchanges } = blok

	const query = gql`
		{
			ExchangeItems (${language !== 'en' ? `starts_with: "${language}/*",` : ''} by_uuids: "${exchanges.length > 0 ? exchanges.join(',') : '1'}") {
				items {
					id
					content {
						component
						country
						logo {
							filename
							title
						}
						title
					}
				}
			}
		}
	`
	const { /*loading, error,*/ data } = useQuery(query)
	const { ExchangeItems } = data || {}

	const exchangeList = useMemo(() => {
		if (ExchangeItems) {
			return ExchangeItems.items.flatMap((item) => {
				return { id: item.id, ...item.content }
			})
		}

		return []
	}, [ExchangeItems])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='container'>
				<div className='row exchange-list'>
					<div className='col-lg-4 col-sm-12'>
						<h5 className='label'>{title}</h5>
					</div>
					<div className='col-lg-8 col-sm-12'>
						<div className='page-content' style={{ paddingBottom: 40 }}>
							{description}
						</div>

						{call_to_action.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>

					{exchangeList.length > 0 && (
						<div className='col-12'>
							<Scrollbar>
								<div className='exchanges'>
									{exchangeList.map((exchange) => (
										<Exchange key={exchange.id} exchange={exchange} />
									))}
								</div>
							</Scrollbar>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ExchangeList
