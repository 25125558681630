import React, { useEffect, useRef } from 'react'
import { useMemo } from 'react'

export const CarouselCard = ({ blok, index, count, layout = 'layout_1', onMiddleChange, positions }) => {
	const { description, show_card_count, title, dark_icon } = blok

	const cardRef = useRef()
	const isCenter = useMemo(() => positions.find((p) => p.index === index).center, [positions, index])
	const style = useMemo(() => {
		if (layout === 'layout_2' && !isCenter) {
			return { filter: 'blur(6px)', border: '0' }
		} else if (!isCenter) {
			const thisCard = positions.find((p) => p.index === index) || { index: 0 }
			const centerCard = positions.find((p) => p.center) || { index: 0 }

			const difference = Math.abs(thisCard.index - centerCard.index)
			const opacity = difference === 0 ? 1 : 1 - difference * 0.2 - 0.4

			if (difference <= 1 || layout === 'layout_5') {
				return { opacity: opacity }
			} else return { filter: 'blur(6px)', opacity: opacity }
		} 

        return {}
	}, [index, isCenter, layout, positions])

	useEffect(() => {
        const viewportHeight = document.documentElement.clientHeight

        const getInView = () => {
            const elm = cardRef.current
            const pos = elm.getBoundingClientRect()
            const topPerc = (pos.top / viewportHeight) * 100
            const bottomPerc = (pos.bottom / viewportHeight) * 100
            const middle = (topPerc + bottomPerc) / 2

            if (onMiddleChange) onMiddleChange(index, middle)
        }

		if (cardRef && cardRef.current && cardRef.current !== null) {
			window.addEventListener('scroll', getInView)
			window.addEventListener('resize', getInView)
		}

        return () => {
            window.removeEventListener('scroll', getInView)
            window.removeEventListener('resize', getInView)
        }
	}, [cardRef, index, onMiddleChange])

	return (
		<div className={'carousel-card'} style={style} ref={cardRef}>
			{layout !== 'layout_5' && (
				<>
					{show_card_count && (
						<div className='card-count'>
							{(index + 1).toString().padStart(2, '0')} {layout !== 'layout_5' ? `/ ${count.toString().padStart(2, '0')}` : ''}
						</div>
					)}
					<h3 className='card-title content'>{title}</h3>
					<div className='card-description'>{description}</div>
				</>
			)}

			{layout === 'layout_5' && (
				<div className='card-details'>
					<img src={dark_icon.filename} alt='' />
					<div>
						{show_card_count && (
							<div className='card-count'>
								{(index + 1).toString().padStart(2, '0')} {layout !== 'layout_5' ? `/ ${count.toString().padStart(2, '0')}` : ''}
							</div>
						)}
						<h3 className='card-title content'>{title}</h3>
						<div className='card-description'>{description}</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default CarouselCard
