import React from 'react'

import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProfileCard = ({ blok, onExpandBio, bioExpanded, dimProfile }) => {
    const { biography, social_media } = blok

	return (
		<div className={`col-xl-${blok.width.xlarge} col-lg-${blok.width.large} col-md-${blok.width.medium} col-sm-${blok.width.small} col-${blok.width.xsmall}`}>
			<div {...storyblokEditable(blok)} className={`profile-card ${dimProfile && !bioExpanded ? 'dim' : ''} ${biography ? 'has-biography' : ''}`}  onClick={() => biography ? onExpandBio(blok._uid) : undefined}>
				<div className='profile-img-container'>
					<img src={blok.photo !== '' ? blok.photo.filename + '/m/0x800': ''} alt={blok.fullname} />
				</div>
				<div className='profile-details'>
					<div className='profile-name'>
						<h4 className='mb-2'>
							{blok.fullname}
						</h4>
						<span className='label c-gray-700' >
							{blok.title}
						</span>
					</div>
				</div>
				<div className='profile-footer'>
					<div className='social-media'>
						{social_media.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
						))}
					</div>
					{blok.biography && (
						<div className='bio'>
							<span className='bio-title'>
								<span style={{ paddingRight: 7 }}>BIO</span><FontAwesomeIcon className={`bio-close-icon ${bioExpanded ? 'expanded' : ''}`} icon={`fas fa-plus`} />
							</span>
						</div>
					)}
				</div>

				{bioExpanded && blok.biography && (
					<>
						<p className='biography'>{blok.biography}</p>
					</>
				)}
			</div>
		</div>
	)
}

export default ProfileCard
