import './ETPNotificationBanner.scss';
import infoGreen from '../../icons/infoGreen.svg';
import infoBlack from '../../icons/infoBlack.svg';
import { storyblokEditable } from '@storyblok/react';
import { useEffect, useState } from 'react';
import { clearCookies, cookieNames, getCookie } from '../../utils';
import ETPLink from '../ETPLink';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

function ETPNotificationBanner({ blok }) {
  const { link, text, actionText, type, theme } = blok;
  const [productsUnavailable, setProductsUnavailable] = useState(false);
  const { data } = useQuery(query);
  const { pathname } = useLocation();

  useEffect(() => {
    const legalNotices = data?.LegalnoticeItems?.items;
    const investorType = getCookie(cookieNames.INVESTOR_TYPE);
    const location = getCookie(cookieNames.LOCATION);

    if (legalNotices && investorType && location) {
      const legalNotice = legalNotices.find((o) => o.slug === `${investorType}-${location}`);
      setProductsUnavailable(!legalNotice);
    }
  }, [data]);

  function handleClick() {
    clearCookies();
    window.location.replace(pathname);
  }

  const classes = `notification-banner
    ${theme === 'dark' ? ' notification-banner-dark black' : ' notification-banner-light white'}
    ${(type === 'country' && !productsUnavailable) ? ' hidden' : ''}`;

  return (
    <div{...storyblokEditable(blok)} className={classes}>
      <div className='notification-banner-text'>
        <img src={theme === 'dark' ? infoGreen : infoBlack} alt='info image'></img>
        <p>{text}</p>
      </div>
      <ETPLink target={link.target} onClick={handleClick}>{actionText}</ETPLink>
    </div>
  );
}

export default ETPNotificationBanner;

const query = gql`
  query {
    LegalnoticeItems(per_page: 100) {
      items {
        full_slug
        slug
        name
        uuid
      }
      total
    }
  }
`;