import { Dropdown } from '@fluentui/react';

function ETPPillOptions({ options, onChange, selectedKey, placeholder }) {

  return (
    <div className={'etp-pill-options'}>
      <Dropdown
        options={options}
        onChange={onChange}
        selectedKey={selectedKey}
        placeholder={placeholder}
      />
      <div className={'etp-pill-options-buttons'}>
        {options.map(option => {
          const isActive = selectedKey===option.key
          return (
            <button
              className={`button button--tag${isActive ? ' is-active' : ''}`}
              key={option.text}
              onClick={() => onChange(undefined, {key:option.key})}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default ETPPillOptions;