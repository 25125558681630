import { StoryblokComponent } from '@storyblok/react';
import { Scrollbar } from '../Scrollbar';

function ETPTimeline({ blok }) {
  const { title, subtitle, text, timecards } = blok;

  return (
    <div className='widget black'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <p className='label'>{title}</p>
            <h2>{subtitle}</h2>
            <p>{text}</p>
          </div>
          <div className='col-lg-6'>
            <Scrollbar>
              <div className='etp-timecards'>
                {timecards?.map(blok => <StoryblokComponent blok={blok} id={blok._uid} key={blok._uid}/>)}
              </div>
            </Scrollbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPTimeline;