import React, { useEffect, useMemo, useState } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import './ETPFooter.scss';
import chevronDown from '../../icons/chevronDown.svg';
import { Dropdown } from '@fluentui/react';
import { gql, useQuery } from '@apollo/client';
import { RichText } from '../RichText';
import { cookieNames, etpLink, getCookie, otherOption, setCookie, setLanguage } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';

export function ETPFooter({ blok, language, slug }) {
  const navigate = useNavigate();
  const { logo, copyright, social, column1, column2, column3, column4, registredText, infoText, location_text, language_text, investor_type_text } = blok;

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedCountry, setSelectedCountry] = useState(getCookie(cookieNames.LOCATION));
  const [selectedInvestorType, setSelectedInvestorType] = useState(getCookie(cookieNames.INVESTOR_TYPE));

  const { data } = useQuery(query, { variables: { language: language !== 'en' ? `${language}/*` : undefined } });
  const { CountryItems, InvestortypeItems, LanguageItems } = data || {};

  useEffect(() => {
    setSelectedLanguage(LanguageItems?.items?.find((item) => item?.content?.code === language)?.content);
  }, [LanguageItems, language]);

  const [countries, investorTypes] = useMemo(() => {
    return [
      CountryItems?.items?.map(e => {
        return { key: e.slug, text: e.name };
      }) || [],
      InvestortypeItems?.items?.map(e => {
        return { key: e.slug, text: e.name };
      }) || []
    ];
  }, [CountryItems, InvestortypeItems]);

  function handleSelectLanguage(e, option) {
    setSelectedLanguage(option);
    setLanguage(option.key)
    let route = etpLink(`${slug}`)
    window.location = '/' + [option.key, route].filter((f) => f !== '' && f !== 'en' && f != '/').join('/')
  }

  function handleSelectCountry(e, option) {
    setSelectedCountry(option.key);
    setCookie(cookieNames.LOCATION, option.key);
    if (['germany', 'austria'].includes(option.key)) {
      changeLang('de');
    } else if (['france'].includes(option.key)) {
      changeLang('fr');
    } else {
      changeLang('en');
    }
  }

  function changeLang(lang) {
    let route = etpLink(`${slug}/`)
    window.location = '/' + [lang, route].filter((f) => f !== '' && f !== 'en' && f != '/').join('/');
  }

  function handleSelectInvestorType(e, option) {
    setSelectedInvestorType(option.key);
    setCookie(cookieNames.INVESTOR_TYPE, option.key);
    window?.location.reload();
  }

  return (
    <div {...storyblokEditable(blok)} className='black etp-footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='etp-footer-logo'>
              <div className='d-flex align-items-end'>
                {logo.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} language={language} />)}
              </div>
              <div className='d-flex align-items-end'><small>{copyright}</small></div>
            </div>
          </div>
          <div className='col-md-4 etp-footer-socials d-flex justify-content-md-end'>
            {social.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} language={language} />)}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-4'>
            <div className='etp-footer-options'>
              <div className='etp-footer-option'>
                <label className={`etp-footer-option-label`} htmlFor={`language`}>{language_text}</label>
                <Dropdown
                  onChange={handleSelectLanguage}
                  placeholder={language_text}
                  options={LanguageItems?.items?.map(({ content }) => ({ text: content.language, key: content.code }))}
                  //                  options={LanguageItems?.items
                  //                    ?.map(({ content }) => ({ text: content.language, key: content.code }))
                  //                    ?.filter(content => content.key === 'en')}
                  selectedKey={selectedLanguage?.code}
                  onRenderCaretDown={() => <CaretDown />}
                />
              </div>
              <div className='etp-footer-option'>
                <label className={`etp-footer-option-label`} htmlFor={`language`}>{location_text}</label>
                <Dropdown
                  onChange={handleSelectCountry}
                  placeholder={location_text}
                  options={[...countries, otherOption]}
                  selectedKey={selectedCountry}
                  onRenderCaretDown={() => <CaretDown />}
                />
              </div>
              <div className='etp-footer-option'>
                <label className={`etp-footer-option-label`} htmlFor={`language`}>{investor_type_text}</label>
                <Dropdown
                  onChange={handleSelectInvestorType}
                  placeholder={investor_type_text}
                  options={investorTypes}
                  selectedKey={selectedInvestorType}
                  onRenderCaretDown={() => <CaretDown />}
                />
              </div>
            </div>
          </div>
          <div className='col-sm-4 col-lg-3'>
            <div className='etp-footer-link-column'>
              {column1.map((blok) => (
                <Link
                  to={etpLink(blok.link.cached_url.replace(/\/$/, ''))}
                  target={blok.link.target}
                  className='etp-footer-link'
                  key={blok._uid}
                >
                  {blok.text}
                </Link>
              ))}
            </div>
            <div className='etp-footer-link-column'>
              {column2.map((blok) => (
                <Link className='etp-footer-link' key={blok._uid} to={etpLink(blok.link.cached_url.replace(/\/$/, ''))}>
                  {blok.text}
                </Link>
              ))}
            </div>
          </div>
          <div className='col-sm-4 col-lg-3'>
            <div className='etp-footer-link-column'>
              {column3.map((blok) => (
                <Link className='etp-footer-link' key={blok._uid} to={etpLink(blok.link.cached_url.replace(/\/$/, ''))}>
                  {blok.text}
                </Link>
              ))}
            </div>
          </div>
          <div className='col-sm-4 col-lg-2 etp-footer-link-column'>
            {column4.map((blok) => (
              <Link className='etp-footer-link' key={blok._uid} to={etpLink(blok.link.cached_url.replace(/\/$/, ''))}>
                {blok.text}
              </Link>
            ))}
          </div>
        </div>
        <div className='row'>
          <small>{registredText}</small>
          <p className='etp-footer-info-text'>
            <RichText blok={infoText} inline />
          </p>
        </div>
      </div>
    </div>
  );
}

function CaretDown() {
  return <img
    className={`etp-footer-dropdown-chevron`}
    width='16px'
    height='16px'
    src={chevronDown}
    alt='chevron down'
  />;
}

const query = gql`
  {
    CountryItems(sort_by: "name:asc") {
      items {
        uuid
        name
        slug
      }
      total
    }
    InvestortypeItems {
      items {
        uuid
        name
        slug
      }
      total
    }
    LanguageItems(sort_by: "name:asc") {
      items {
        content {
          language
          language_short
          code
          icon {
            filename
          }
        }
        uuid
        full_slug
      }
    }
  }
`;