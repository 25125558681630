import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { KnowledgeByteContent } from '../../KnowledgeBytes'
import ArticleContent from './ArticleContent'

export const StoryArticle = ({ blok }) => {
	const location = useLocation()
    
	const { title: pageTitle, image, metatags = {}, article_type, drop_suffix } = blok
	const { title, description, og_title, og_description, og_image } = metatags
    const { filename: articleImage } = image || {}
    
    // const isKnowledgeByte = isKnowledgeByte
	return (
		<>
			<Helmet>
				<title>{og_title || title || pageTitle || 'Home'}{drop_suffix ? '' : ' - CoinShares'}</title>
				<meta name='description' content={og_description || description || title || pageTitle} />
				<meta property='og:title' content={og_title || title || pageTitle || 'Home'} />
				<meta property='og:type' content='article' />
				<meta property='og:url' content={window.location.origin + location.pathname} />
				<meta property='og:description' content={og_description || description || title || pageTitle} />
				<meta property='og:image' content={(articleImage + '/m/') || (og_image + '/m/') || '/images/announcements.png'} />
				<link rel='canonical' href={window.location.origin + location.pathname} />
				<link rel="alternate" href={`${window.location.origin}${resolvePath(location.pathname)}`} hreflang={'en-gb'} />
        <link rel="alternate" href={`${window.location.origin}/de${resolvePath(location.pathname)}`} hreflang={'de-DE'} />
        <link rel="alternate" href={`${window.location.origin}/fr${resolvePath(location.pathname)}`} hreflang={'fr-FR'} />
        <link rel="alternate" href={`${window.location.origin}/sv${resolvePath(location.pathname)}`} hreflang={'sv-SE'} />
				<link rel="alternate" href={`${window.location.origin}${resolvePath(location.pathname)}`} hreflang={'x-default'} />
			</Helmet>

			{article_type.slug === 'knowledge-bytes' && <KnowledgeByteContent blok={blok}/>}
            {article_type.slug !== 'knowledge-bytes' && <ArticleContent blok={blok} />}
		</>
	)
}

export default StoryArticle

function resolvePath(location) {
	let path = '';
	if  (location === '/en/' || location === '/en' || location === '/fr/' || location === '/fr' 
	  || location === '/de/' || location === '/de' || location === '/sv/' || location === '/sv') {
	  path = '';
	} else if (location.startsWith('/en/') || location.startsWith('/fr/') || location.startsWith('/de/') || location.startsWith('/sv/')) {
	  path = `/${location.slice(4, location.length)}`;
	} else {
	  path = location;
	}
	return path;
}