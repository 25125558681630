import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import forwardBlack from '../../icons/forwardBlack.svg'
import logAction from '../../actions'

export const Card = ({ blok, card, showDescription, showIcon }) => {
	const { title, icon, iconHover, description, header, link_title, external, amount, currency, link } = card
    const location = useLocation()

	const { ref, inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	})

	const classes = useMemo(() => {
		const classList = ['card']

		if (description) {
			classList.push('no-hover')
		}

		if (external) {
			classList.push('external')
		}

		if (inView) {
			classList.push('animate-ease-up')
		}

		return classList.join(' ')
	}, [inView, description, external])

	return (
		<>
			{!external && (
				// replace leading slash with empty space if present
				<Link ref={ref} className={classes} to={'/' + link.replace(/^\//g, '') || '/'} onClick={() => logAction('navigation', { from: location.pathname, to: '/' + link.replace(/^\//g, '') || '/' })}>
					{/* {icon && (
						<div className='card-icon'>
							<img src={icon} alt={title} />
						</div>
					)} */}
					<div className='card-content'>
						<div className='card-title'>
							<h4>{title}</h4>
							<img src={forwardBlack} alt='' className='icon-foward' />
						</div>
						{description && <div className='card-description'>{description}</div>}
						<div className='card-stat'>
							<div className='card-amount-title'>{header}</div>
							<div className='card-amount'>
								<span className='text-bold'>{amount}</span>
								<span className='card-amount-unit'>{currency}</span>
							</div>
						</div>
						{/* {!description && <div className='more-info' />} */}
						{/* {description && (
							<div className='card-forward'>
								<span />
								<img src={ChevronLarge} alt='forward' />
							</div>
						)} */}
					</div>
				</Link>
			)}

			{external && (
				<a ref={ref} className={classes} href={link} target='_blank' rel='noreferrer'>
					{/* {icon && !link_title && (
						<div className='card-icon'>
							<img src={icon} alt={title} />
						</div>
					)} */}
					<div className={`card-content ${link_title ? 'external' : ''}`}>
						<div className='card-title'>
							<h4>{title}</h4>
						</div>
						{description && <div className='card-description'>{description}</div>}
						{header && <div className='card-amount-title'>{header}</div>}
						{amount && (
							<div className='card-amount'>
								<span className='text-bold'>{amount}</span>
								<span className='card-amount-unit'>{currency}</span>
							</div>
						)}
						{link_title && (
							<>
								<div className='card-icon'>
									<img src={icon} alt={title} />
								</div>
								<div className='card-icon-hover'>
									<img src={iconHover} alt={title} />
								</div>
							</>
						)}
						{/* {!description && <div className='more-info'>{link_title}</div>} */}
						{/* {description && (
							<div className='card-forward'>
								<span>{link_long_title}</span>
								<img src={ChevronLarge} alt='forward' />
							</div>
						)} */}
					</div>
				</a>
			)}
		</>
	)
}

export default Card
