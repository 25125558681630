import React, { useState, useRef } from 'react'

import Scrollbars from './Scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Scrollbar = ({ children }) => {
	// const [dragging, setDragging] = useState(false)
	const [showScrollbar, setShowScrollbar] = useState(false)
	// const scrollbarThumb = useRef()
    // const scrollbarTrack = useRef()
	const scrollbarView = useRef()

	// 	// if (scrollbarView && scrollbarView.current) {
	// 	requestAnimationFrame(() => {
	// 		if (scrollbarView && scrollbarView.current) {
	// 			const element = scrollbarView.current

	// 			const scrollRatio = element.clientWidth / element.scrollWidth

	// 			setShowScrollbar(element.scrollWidth > element.clientWidth)
	// 			setThumbSize(Math.ceil(scrollRatio * element.clientWidth))
	// 			setScrollLeft(scrollRatio * element.scrollLeft)
	// 		}
	// 	})
	// 	// }

	// 	// window.addEventListener('resize', updateScrollbar)

	// 	// return () => {
	// 	// 	window.removeEventListener('resize', updateScrollbar)
	// 	// }
	// }, [scrollbarView])

	return (
		<Scrollbars
			className={`scrollbar ${showScrollbar ? 'show' : 'hide'}`}
			autoHeightMax={'auto'}
			onUpdate={(values) => {
				// console.log('onUpdate', values)
				// const scrollRatio = values.clientWidth / values.scrollWidth
				setShowScrollbar(values.scrollWidth > values.clientWidth)
				// setThumbSize(Math.ceil(scrollRatio * values.clientWidth))
				// setScrollLeft(scrollRatio * values.scrollLeft)
			}}
			// onScroll={(ev) => {
			// 	const element = ev.srcElement

			// 	requestAnimationFrame(() => {
			// 		const scrollRatio = element.clientWidth / element.scrollWidth

			// 		setThumbSize(Math.ceil(scrollRatio * element.clientWidth))
			// 		setScrollLeft(scrollRatio * element.scrollLeft)
			// 	})
			// }}
			height={'auto'}
			renderView={(props) => <div {...props} className='scrollbar-view' />}
			renderTrackHorizontal={({ props }) => <div className='scrollbar-track' {...props} />}
			renderThumbHorizontal={({ style, props }) => (
				<div className='scrollbar-thumb' {...props} style={{ ...style }}>
					<FontAwesomeIcon icon={'fas fa-chevron-left'} />
					<FontAwesomeIcon icon={'fas fa-chevron-right'} />
				</div>
			)}
		>
			<div ref={scrollbarView} className='scrollbar-view-container'>
				{children}
			</div>

			<div className='swipe-right'>
				<div className='swipe-icon'>
					<img src='/images/ChevronSwipe.png' alt='' />
					<img src='/images/ChevronSwipe.png' alt='' />
					<img src='/images/ChevronSwipe.png' alt='' />
				</div>
				<span>Swipe</span>
			</div>
		</Scrollbars>
	)
}

export default Scrollbar
