import React, { useMemo } from 'react'
import { RichText } from '../RichText'
import { storyblokEditable } from '@storyblok/react'

export const ComplexTable = ({ blok }) => {
	const { rows, number_columns, title } = blok

	const thead = rows.filter((o) => o.is_header_row === true)
	const tbody = rows.filter((o) => !o.is_header_row)

	const columns = useMemo(() => {
		const returnColumns = []

		for (let index = 0; index < number_columns; index++) {
			returnColumns.push(number_columns)
		}

		return returnColumns
	}, [number_columns])

	return (
		<>
            {title && <h5>{title}</h5>}
			<table className='complex-table' {...storyblokEditable(blok)}>
				<thead>
					{thead.map((row) => (
						<tr key={row._uid}>
							{columns.map((c, i) => (
								<th key={i}>
									<RichText blok={row[`cell_${i + 1}`]} key={blok._uid} inline={true} />
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{tbody.map((row) => (
						<tr key={row._uid}>
							{columns.map((c, i) => (
								<td key={i}>
									<RichText blok={row[`cell_${i + 1}`]} key={blok._uid} inline={true} />
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

export default ComplexTable
