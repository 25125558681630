import { StoryblokComponent } from "@storyblok/react";

function ETPFeaturedStatements({ blok }) {
  const { featured_statements, stacked } = blok;

  return (
    <div className={`etp-featured-statements${stacked ? ' etp-featured-statements-stacked' : ''}`}>
      {featured_statements?.map((fs, index) => (
        <StoryblokComponent blok={fs} id={blok._uid} key={index} number={index + 1}/>
      ))}
    </div>
  )
}

export default ETPFeaturedStatements;