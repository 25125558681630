import React, { useMemo } from 'react'
import CSLogo from '../../../icons/CS Logo.svg'
import TwitterLogo from '../../../icons/Twitter.svg'
import { LinkItUrl, LinkItEmail, LinkItTwitter } from 'react-linkify-it'

export const Tweet = ({ tweet }) => {
	const { key, text, created } = tweet

	const displayDate = useMemo(() => {
		const newDate = new Date(created)
		
        return `${newDate.toLocaleDateString(undefined, { month: 'long' })} ${newDate.getDate()}`
		
	}, [created])

	return (
		<div className='tweet'>
			<div className='tweet-header'>
				<div className='tweet-logo'>
					<img src={CSLogo} alt='logo' />
				</div>

				<a className='tweet-author' target='_blank' href={`https://twitter.com/CoinSharesCo/status/${key}`} rel='noreferrer'>
					<span>CoinShares</span>
					<span className='tweet-author-subtext'>
						<span>@CoinShares</span>
						<span>{displayDate}</span>
					</span>
				</a>
				<div className='twitter-logo'>
					<img src={TwitterLogo} alt='twitter' />
				</div>
			</div>
			<LinkItUrl>
				<LinkItEmail>
					<LinkItTwitter>
						<div className='text'>{text}</div>
					</LinkItTwitter>
				</LinkItEmail>
			</LinkItUrl>
		</div>
	)
}

export default Tweet
