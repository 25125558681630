import React from 'react'

import { storyblokEditable } from '@storyblok/react'

export const NewsLetterForm = ({ blok }) => {
	const { background = 'transparent' } = blok

	return (
		<div className={background.toLowerCase()}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div style={{ padding: 120, background: 'rgba(18, 36, 68, 0.8)', border: '3px solid #FFFFFF' }}>
								<div className='row'>
									<div className='col-12'>
										<div className='d-flex flex-column' style={{ gap: 25 }}>
											<h5>Monthly Newsletter</h5>
											<h3 className='content'>Sign up to our monthly newsletter to receive our research and insight content in your inbox.</h3>
											{/* <RichText inline={true} blok={description} /> */}
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-6 col-md-12'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 25,
											}}
										>
											<div className='form'>
												<div className='form-input'>
													<label htmlFor='firstname'>FIRST NAME*</label>
													<input type='text' name='firstname' />
												</div>

												<div className='form-input'>
													<label htmlFor='email'>email*</label>
													<input type='text' name='phone' />
												</div>

												<button className='button' style={{ marginTop: 35, alignSelf: 'flex-start' }}>
													Sign up now
												</button>
											</div>
										</div>
									</div>
									<div className='col-lg-6 col-md-12'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 25,
											}}
										>
											<div className='form'>
												<div className='form-input'>
													<label htmlFor='lastname'>last name*</label>
													<input type='text' name='lastname' />
												</div>
												<div className='form-input'>
													<label htmlFor='country'>country*</label>
													<input type='text' name='country' />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewsLetterForm
