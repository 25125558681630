import { Button, Menu, MenuList, MenuPopover, MenuTrigger, } from '@fluentui/react-components';
import { storyblokEditable } from '@storyblok/react';
import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import chevronDown from '../../icons/chevronDown.svg';
import linkArrow from '../../icons/linkArrow.svg';
import './MenuNavigation.scss';
import { etpLink, makeURLFromRoot } from '../../utils';
import { Link } from 'react-router-dom';

export const MenuNavigation = ({ blok }) => {
  const { text } = blok;
  const ref = useRef();
  useOnClickOutside(ref, closeMenu);

  const [open, setOpen] = useState(false);

  function toggleMenu() {
    setOpen(!open);
  }

  function closeMenu() {
    setOpen(false);
  }

  return (
    <>
      <Menu open={open}>
        <MenuTrigger disableButtonEnhancement>
          <div className='navigation-item navigate'>
            <Button onClick={toggleMenu}>
            <span
              style={{ gap: '0.5rem' }}
              className={`d-flex align-items-center justify-content-center`}
            >
              {text}
              <img height='20px' width='11px' src={chevronDown} alt='chevron down'/>
            </span>
            </Button>
          </div>
        </MenuTrigger>
        <MenuPopover ref={ref} className={`menu-container`} style={{ gap: '1rem' }}>
          <MenuList {...storyblokEditable(blok)}>
            {blok.items.map((blok) => {
              if (blok.type === 'header') {
                return <span className={`menu-header`} key={blok._uid}>{blok.text}</span>;
              }
              if (blok.type === 'separator') {
                return <span className={`menu-separator`} key={blok._uid}></span>;
              }
              const { cached_url } = blok.link;
              return (
                <Link
                  className={`menu-item d-flex justify-space-between align-items-center`}
                  style={{ gap: '2rem' }}
                  to={makeURLFromRoot(etpLink(cached_url.replace(/\/$/, '')))}
                  key={blok._uid}
                >
                  <span>{blok.text}</span>
                  <img width='16px' height='16px' src={linkArrow} alt='>'/>
                </Link>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );
};
