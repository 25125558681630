import React, { useEffect, useState } from 'react'
import { Scrollbar, Tweet } from '../.'
import { storyblokEditable } from '@storyblok/react'

export const TwitterFeed = ({ blok }) => {
	const { anchor_key, background, title, description } = blok

	const [tweets, setTweets] = useState([])

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URI}/Widgets?ApiKey=${process.env.REACT_APP_API_KEY}&names=TWITTERFEED`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
		}).then((value) => {
			value.json().then((value) => {
				const widget = value.find((val) => val.key === 'TWITTERFEED')
                const tweets = widget.sections.map(o => {
                    return {
                        key: o.key,
                        text: o.meta.find(ot => ot.key === 'text')?.value,
                        created: o.meta.find(ot => ot.key === 'created')?.value,
                    }
                })

                setTweets(tweets)
			})
		})
	}, [])

	return (
		<div className={background.toLowerCase()} id={anchor_key}>
			<div {...storyblokEditable(blok)} className='twitter-feed'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-md-12 twitter-feed-title'>
							<h5 className='label'>{title}</h5>
							<span>{description}</span>
						</div>

						<div className='col-lg-8 col-md-12'>
							<Scrollbar>
								<div className='twitter-feed-container'>
									{tweets.map((tweet) => (
										<Tweet key={tweet.key} tweet={tweet} />
									))}
								</div>
							</Scrollbar>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TwitterFeed
