
import { StoryblokComponent } from '@storyblok/react';

function ETPQuote({blok}) {
  const {text, button} = blok;
  return (
    <div className={'quote-container'}>
      <div className={'quote etp-quote'}>
        <p>
          {text}
        </p>
        {button?.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)}
      </div>
    </div>
  );
}

export default ETPQuote;