import React from 'react'

import { storyblokEditable } from '@storyblok/react'
import { RichText } from '../RichText'

export const IndexForm = ({ blok }) => {
	const { background, title, description, header } = blok

	return (
		<div className={background.toLowerCase()}>
			<div {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div style={{ padding: 120, background: 'rgba(18, 36, 68, 0.8)', border: '3px solid #FFFFFF' }}>
								<div className='row'>
									<div className='col-12'>
										<div className='d-flex flex-column' style={{ gap: 25 }}>
											<h5>{title}</h5>
											<h3>{header}</h3>
											<RichText inline={true} blok={description} />
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-6 col-md-12'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 25,
											}}
										>
											<div className='form'>
												<div className='form-input'>
													<label htmlFor='firstname'>FIRST NAME*</label>
													<input type='text' name='firstname' />
												</div>
												<div className='form-input'>
													<label htmlFor='lastname'>last name*</label>
													<input type='text' name='lastname' />
												</div>
												<div className='form-input'>
													<label htmlFor='email'>email*</label>
													<input type='text' name='phone' />
												</div>

												<button className='button' style={{ marginTop: 35, alignSelf: 'flex-start' }}>
													Submit
												</button>
											</div>
										</div>
									</div>
									<div className='col-lg-6 col-md-12'>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 25,
											}}
										>
											<div className='form'>
												<div className='form-input'>
													<label htmlFor='phone'>phone</label>
													<input type='text' name='phone' />
												</div>
												<div className='form-input'>
													<label htmlFor='message'>message</label>
													<textarea rows={7} type='text' name='message' />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexForm
