import React from 'react'

import { storyblokEditable } from '@storyblok/react'

export const Tab = ({ blok, selected, onSelectedKeyChanged }) => {
	const { title } = blok
	return (
		<div {...storyblokEditable(blok)} onClick={() => onSelectedKeyChanged(blok)} className={`tab ${selected ? 'selected': ''}`}>
			<h5>{title}</h5>
		</div>
	)
}

export default Tab
