import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ETPPaginationControls({ page, pages, onClick }) {
  return (
    <div className={'etp-pagination-controls'}>
      <button
        className={'etp-pagination-controls-chevron'}
        disabled={page === 0 || !pages}
        onClick={() => onClick(page - 1)}
        aria-label={'previous page'}
      >
        <FontAwesomeIcon icon={'chevron-left'}/>
      </button>
      {pages && generatePages(page + 1, pages).map((num, i) => (
        num === 'ellipsis' ?
          <div className={'etp-pagination-controls-ellipsis'} key={num + i}>...</div> :
          <PageOption key={num} page={num} active={num - 1 === page} onClick={() => onClick(num - 1)}/>
      ))}
      <button
        className={'etp-pagination-controls-chevron'}
        disabled={page + 1 === pages || !pages}
        onClick={() => onClick(page + 1)}
        aria-label={'next page'}
      >
        <FontAwesomeIcon icon={'chevron-right'}/>
      </button>
    </div>
  );
}

function generatePages(page, pages) {
  if (pages <= 7) {
    return generateRange({to: pages});
  }

  if (page <= 4) {
    return [
      ...generateRange({to: 5}),
      "ellipsis",
      pages
    ];
  }

  if ((pages - page) <= 3) {
    return [
      1,
      "ellipsis",
      ...generateRange({from: pages - 4, to: pages}),
    ];
  }

  return [
    1,
    "ellipsis",
    ...generateRange({from: page - 1, to: page + 1}),
    "ellipsis",
    pages
  ];
}

export default ETPPaginationControls;

function generateRange({ from = 1, to }) {
  return Array.from(
    { length: (to + 1 - from) },
    (value, index) => from + index
  );
}


function PageOption({ page, active, onClick }) {
  return (
    <button
      active={active}
      onClick={onClick}
      aria-label={`page ${page}`}
      disabled={active}
      className={'etp-pagination-controls-page-btn'}
    >
      {page}
    </button>
  );
}