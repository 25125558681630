function ETPDisclaimer({blok}) {
  const {title, text, style} = blok;

  const splitText = text.split('\n');
  return (
    <div className={'row etp-top-border ' + 'etp_disclaimer_'+style}>
      <div className={'col-md-4'}>
        <h3 className="title">{title}</h3>
      </div>
      <div className={'col-md-8'}>
        {splitText.length > 1 ? (
          <ul>{splitText.map((line, i) => <li className={'mb-2'} key={i}>{line}</li>)}</ul>
        ) : (
          <p>{text}</p>
        )}
      </div>
    </div>
  );
}

export default ETPDisclaimer;