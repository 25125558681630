import { useEffect, useState } from 'react';
import { fetchExchangeTradedProducts } from '../../queries';
import ETPBigNumber from '../ETPBigNumber';

function ETPHowStakingWorks({ blok, isin }) {
  const { title, text, text_one, info_text_one, text_two, info_text_two } = blok;
  const [fee, setFee] = useState();
  const [stakingReward, setStakingReward] = useState();

  useEffect(() => {
    fetchExchangeTradedProducts([isin])
      .then(([product]) => {
        setFee(product.fee);
        setStakingReward(product.stakingReward);
      });
  }, [isin]);

  console.log(stakingReward);

  const [stakingPercentage, stakingPerAnnum] = stakingReward?.split(' ') ?? [];
  const [feePerAnnum, feePercentage] = fee?.split(' ')?.reverse() ?? [];

  return (
    <div className={'widget gray'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-5'}><h2>{title}</h2></div>
          <div className={'col-lg-7'}>{text}</div>
        </div>

        <div className={'row etp-staking-numbers'}>
          <div className={'col-lg-5'}>
            {stakingReward && (
              <ETPBigNumber
                blok={{
                  primary_text: stakingPercentage,
                  secondary_text: stakingPerAnnum,
                  info_text: text_one,
                  info_tooltip_text: info_text_one
                }}
              />
            )}
          </div>
          <div className={'col-lg-7'}>
            {fee && (
              <ETPBigNumber
                blok={{
                  primary_text: feePercentage,
                  secondary_text: feePerAnnum,
                  info_text: text_two,
                  info_tooltip_text: info_text_two
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPHowStakingWorks;