import React, { useMemo, useState } from 'react'
import { Dropdown, DirectionalHint } from '@fluentui/react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

export const InvestmentPortfolio = ({ blok }) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState('all')
	const [selectedBlok, setSelectedBlok] = useState(undefined)
	const maxItems = 7

	const { anchor_key, portfolio_cards, statistic_title, statistic_value } = blok

	const categories = useMemo(() => {
		return portfolio_cards.flatMap((pc) => pc.category).filter((val, index, array) => array.findIndex((item) => item.id === val.id) === index)
	}, [portfolio_cards])

	const profileCards = useMemo(() => {
		let filteredCards = [...blok.portfolio_cards]

		if (selectedCategory !== 'all') {
			filteredCards = filteredCards.filter((card) => card.category.findIndex((cat) => cat.id.toString() === selectedCategory) > -1)
		}

		return filteredCards
	}, [blok.portfolio_cards, selectedCategory])

	return (
		<div className='gray' id={anchor_key}>
			<div {...storyblokEditable(blok)}>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<div className='investment-portfolio-info'>
								<h5 className="label">{blok.title}</h5>
								<div>
									<h2 className='content'>{blok.header}</h2>
									<p style={{ paddingRight: 60 }}>{blok.description}</p>
								</div>
							</div>
						</div>
						<div className='col-12 investment-portfolio'>
							<div className='investment-portfolio-categories-dropdown'>
								<Dropdown
                                    calloutProps={{
                                        directionalHint: DirectionalHint.bottomLeftEdge,
                                        directionalHintFixed: true
                                    }}
									className='tab-dropdown'
									options={[
										{ key: 'all', text: 'All Investment Types' },
										...categories.map((o) => {
											return {
												key: o.id.toString(),
												text: o.content.title,
											}
										}),
									]}
									styles={{
										dropdownItemSelected: {
											background: 'white',
											color: '#122444',
										},
									}}
									selectedKey={selectedCategory}
									onChange={(ev, option) => setSelectedCategory(option.key)}
								/>
							</div>
							<div className='investment-portfolio-categories'>
								<button className={`button button--tag ${selectedCategory === 'all' ? 'is-active' : ''}`} onClick={() => setSelectedCategory('all')}>
									ALL
								</button>
								{categories.map((cat) => (
									<button key={cat.id} className={`button button--tag ${selectedCategory === cat.id.toString() ? 'is-active' : ''}`} onClick={() => setSelectedCategory(cat.id.toString())}>
										{cat.content.title}
									</button>
								))}
							</div>
							<div className='portfolio-cards'>
								{profileCards.map((blok, index) => {
									if (index > maxItems && !isExpanded && portfolio_cards.length > maxItems) {
										return <React.Fragment key={blok._uid} />
									} else if (index === maxItems && !isExpanded && portfolio_cards.length > maxItems) {
										return (
											<div key={blok._uid} className='show-more-portfolio'>
												<span>
													Viewing {maxItems} out of {portfolio_cards.length} items
												</span>

												<button onClick={() => setIsExpanded((expanded) => !expanded)}>
													Load more
												</button>
											</div>
										)
									} else return <StoryblokComponent blok={blok} key={blok._uid} onSelectBlok={(blok) => setSelectedBlok(blok)} />
								})}
							</div>

							<div className='investment-portfolio-investment'>
								<div className='investment-portfolio-header'>{statistic_title}</div>
								<h3 className='investment-portfolio-amount'>{statistic_value}</h3>
								{/*  <span style={{fontWeight: 300}}>{suffix}</span> */}
							</div>

							{selectedBlok !== undefined && (
								<div className='modal'>
									<div className='modal-popup'>
										<div className='modal-header'>
											<img className='portfolio-img' src={selectedBlok.logo.filename} alt={selectedBlok.logo.alt} />
											<div className='modal-close' onClick={() => setSelectedBlok(undefined)} />
										</div>
										<div className='modal-body'>
											<h4 className='article'>{selectedBlok.popup_header}</h4>
											<p>{selectedBlok.popup_description}</p>
											<a className='portfolio-link' href={selectedBlok.popup_link.cached_url}>
												{selectedBlok.popup_link.cached_url}
											</a>
										</div>

										<div className='modal-footer'>
											{selectedBlok.category.map((cat) => (
												<span key={cat.uuid} className='tag'>
													{cat.content.title}
												</span>
											))}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InvestmentPortfolio
