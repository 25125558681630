import React from 'react'

import { storyblokEditable } from '@storyblok/react'

export const Exchange = ({ blok, exchange }) => {
	const { title, country, logo } = exchange

	return (
		<div {...storyblokEditable(blok)} className='exchange'>
			<div className='exchange-logo'>
				<img src={logo.filename} alt={title} style={{ maxWidth: '100%'}}/>
			</div>
			<div className='exchange-title'>{title}</div>
			<div className='exchange-country'>{country}</div>
		</div>
	)
}

export default Exchange
