import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { etpLink } from '../../utils';

function ETPButton({ blok, disabled }) {
  const { link, text, large, solid, onClick, outside_etp } = blok;

  function handleClick(event) {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  }

  if (onClick) {
    return (
      <button onClick={handleClick} className={getClassName(large, solid)} disabled={disabled}>
        <div className={'etp-button-text'}>{text}</div>
        <div className={'etp-button-chevron'}><FontAwesomeIcon icon={'chevron-right'} /></div>
      </button>
    );
  }

  if (outside_etp) {
    const { origin } = window.location;

    return (
      <>
        {link.linktype === 'story' && (<a
          href={`${origin.replace('etp.', '')}${link.cached_url.charAt(0) !== '/' ? '/' : ''}${link.cached_url}`}
          className={getClassName(large, solid)}
          target={link.target}
        >
          <div className={'etp-button-text'}>{text}</div>
          <div className={'etp-button-chevron'}><FontAwesomeIcon icon={'chevron-right'} /></div>
        </a>)}
        {link?.linktype === 'url' && (<a
          href={link.cached_url} target='_blank' rel="noreferrer" className={getClassName(large, solid)}
        >
          <div className={'etp-button-text'}>{text}</div>
          <div className={'etp-button-chevron'}><FontAwesomeIcon icon={'chevron-right'} /></div>
        </a>)}
      </>
    );
  }

  return (
    <Link to={etpLink(link.cached_url.replace(/\/$/, ''))} className={getClassName(large, solid)} target={link.target}>
      <div className={'etp-button-text'}>{text}</div>
      <div className={'etp-button-chevron'}><FontAwesomeIcon icon={'chevron-right'} /></div>
    </Link>
  );
}

export default ETPButton;

function getClassName(large, solid) {
  const classes = ['etp-button'];

  if (large) {
    classes.push('etp-button-large');
  }

  if (solid) {
    classes.push('etp-button-solid');
  }

  return classes.join(' ');
}