import {gql} from "@apollo/client";
import {apiWidgetUrl, etpNewSlug} from "./constants";
import axios from "axios";

export const knowledgeCategoryQuery = gql`
  query ($startsWith: String) {
    KnowledgecategoryItems(starts_with: $startsWith, sort_by: "name:asc") {
      items {
        content {
          name
          subcategories {
            name
            uuid
          }
        }
        uuid
        full_slug
        slug
      }
    }
  }
`;

export const articlesQuery = ({
  selectedCategory,
  uuids,
  language,
  page = 1,
  perPage = 100,
  filterQuery = "{}",
  sortBy = "sort_by_date:desc",
} = {}) => {
  const languageDirectory =
    language === undefined || language === "en" ? "" : `${language}/`;

  const articlesPath = `starts_with: "${
    selectedCategory ?? languageDirectory + etpNewSlug + "/knowledge/"
  }"`;
  const qs = uuids ? `by_uuids_ordered: "${uuids.join(",")}"` : articlesPath;

  console.log("qs", qs);
  return gql`
    query {
      ArticleItems (
        ${qs},
        per_page: ${perPage},
        page: ${page},
        sort_by: "${sortBy}",
        filter_query: ${filterQuery}
      ) {
        items {
          content {
            title
            image {
              filename
            }
            primary_topic {
              content
            }
            additional_topics {
              content
            }
            author {
              name
            }
          }
          uuid
          full_slug
          first_published_at
        }
        total
      }
    }
  `;
};

/**
 * Fetches the data from the data warehouse for a given set of product isins
 * @param {string[]} isins
 * @returns {Promise<Response>}
 */
export function fetchExchangeTradedProducts(isins) {
  return fetch(
    `${apiWidgetUrl}&names=${isins
      .map((isin) => `ETP_LISTINFO_${isin}`)
      .join(",")}`
  )
    .then((res) => res.json())
    .then((res) =>
      res.map((product) =>
        product.sections[0].meta.reduce((acc, curr) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {})
      )
    );
}

export async function fetchBrokerMapping({
  broker,
  country,
  isin,
  investorType,
}) {
  let url = `${process.env.REACT_APP_API_URI}/Brokers?ApiKey=${process.env.REACT_APP_API_KEY}`;
  if (broker) {
    url += `&broker=${broker}`;
  }
  if (country) {
    url += `&country=${country}`;
  }
  if (isin) {
    url += `&isin=${isin}`;
  }
  if (investorType) {
    url += `&investorType=${investorType}`;
  }
  const {data} = await axios(url);
  return data;
}
