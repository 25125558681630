import { StoryblokComponent } from '@storyblok/react';

function ETPReserveDetails({ blok }) {
  const { text, logos, button } = blok;

  return (
    <div className='widget gray'>
      <div className='container etp-reserve-details'>
        <div className='row'>
          <div className='col-md-7 etp-reserve-details-text'>
            <h5>{text}</h5>
            {button?.map(blok => <StoryblokComponent blok={blok} key={blok._uid}/>)}
          </div>
          <div className='col-md-5'>
            <div className='etp-reserve-details-logos'>
              {logos?.map(logo => <img src={logo.filename} alt={logo.title}/>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPReserveDetails;