import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import languages from '../../constants/languages.json';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useStoryblok } from '../../hooks/useStoryblok';
import { gql, useQuery } from '@apollo/client';
import { SET_KNOWLEDGE_BYTES, SET_LOOKUP_TERMS, useGlobalState } from '../../store';
import { etpNewSlug } from '../../constants';
import { getLanguage, isETPSubdomain, setLanguage } from '../../utils';

function Global({ blok }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalState();
  const { banner, header, summary, footer, popup } = blok;

  useEffect(() => {
//    if (isETPSubdomain()) {
//      const path = location.pathname.substring(1).split('/');
//      const pathLanguage = findLanguage(path);
//      if (pathLanguage) {
//        setLanguage('en');
//        const pathWithoutLanguage = location.pathname.split('/').filter(x => x && x !== pathLanguage).join('/');
//        navigate(pathWithoutLanguage);
//      }
//    }
//  }, [location]);
//
//  useEffect(() => {
    const storedLanguage = getLanguage();
    const path = location.pathname.substring(1).split('/');
    const pathLanguage = findLanguage(path);
    const searchParams = new URLSearchParams(location.search);

    if (storedLanguage && storedLanguage !== 'en' && !pathLanguage) {
      navigate(`${storedLanguage}${location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`);
    }
  }, [location]);

  const [slug, language] = useMemo(() => {
    const path = location.pathname.substring(1).split('/');
    const pathLanguage = findLanguage(path);
    const slug = buildSlug(path, !!pathLanguage);
    const language = pathLanguage ?? getLanguage() ?? 'en';
    setLanguage(language);

    return [slug || 'home', language];
  }, [location]);

  function findLanguage(path) {
    const firstSubdirectory = path[0];
    return languages.find(l => l[firstSubdirectory]) && firstSubdirectory;
  }

  function buildSlug(path, hasLanguage) {
    let slug = [...path];

    if (hasLanguage) {
      slug = slug.slice(1);
    }

    if (isETPSubdomain()) {
      slug = [etpNewSlug, ...slug];
    }

    return slug.join('/');
  }

  const version = window.location.search.includes('_storyblok') ? 'draft' : 'published';

  const story = useStoryblok(slug, {
    version: version,
    fallback_lang: 'en',
    language: language,
    resolve_relations: 'portfolio_card.category,article.author,article.article_type,knowledge_bytes.topics'
  }, {});

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    } else {
      const elementToScroll = document.getElementById(location.hash.replace('#', ''));
      if (!elementToScroll) return;
      window.scrollTo({ top: elementToScroll.offsetTop, behavior: 'smooth' });
    }
  }, [story, location.hash]);

  const { data } = useQuery(query(language));

  useEffect(() => {
    if (data && data.DatasourceEntries && data.KnowledgeBytes) {
      dispatch({
        type: SET_LOOKUP_TERMS,
        payload: data.DatasourceEntries.items.flatMap((o) =>
          (o.dimensionValue !== null ? o.dimensionValue : o.value).split(',').map((val) => {
            return {
              key: o.name,
              value: val.trim(),
            };
          })
        ),
      });
      if (data.KnowledgeBytes) {
        const knowledgeBytes = data.KnowledgeBytes.items.flatMap((item) => {
          const itemTopicTitles = [
            item.content.primary_topic?.content?.title,
            ...(item.content.additional_topics?.map((o) => o.content.title) || [])
          ].filter((o) => o);

          let tags = '';
          if (itemTopicTitles.length === 1) tags = itemTopicTitles[0];
          else if (itemTopicTitles.length > 1) tags = `${itemTopicTitles[0]} + ${itemTopicTitles.length - 1} More`;

          return {
            uuid: item.uuid,
            full_slug: item.full_slug,
            ...item.content,
            tags: tags === '' ? [] : [tags],
          };
        });

        dispatch({ type: SET_KNOWLEDGE_BYTES, payload: knowledgeBytes });
      }
    }
  }, [data, dispatch]);

  if (!story || !story.content) {
    return <div></div>;
  }

  const isFixed = story.content.component === 'article';

  const classes =
    `global theme-${story.content.theme ? story.content.theme : 'black'}${slug === 'home' ? ' is-home' : ''}${isETPSubdomain() ? ' etp-global' : ''}`;

  return (
    <>
      {banner?.map((blok) => (<StoryblokComponent blok={blok} key={blok._uid}/>))}
      {summary.map((blok) => (<StoryblokComponent blok={blok} key={blok._uid}/>))}
      <div{...storyblokEditable(blok)} className={classes}>
        {header.map((blok) => (
          <StoryblokComponent
            blok={blok}
            key={blok._uid}
            slug={slug}
            language={language}
            footer={footer}
            fixed={isFixed}
          />
        ))}
        {!(location.pathname.includes('ui-kit-pages/layout') || location.pathname.includes('ui-kit-pages/etp-layout')) &&
          <StoryblokComponent
            blok={{ ...story.content, name: story.name, first_published_at: story.first_published_at }}
            slug={slug}
            language={language}
          />
        }
        {footer.map((blok) => (
          <StoryblokComponent blok={blok} key={blok._uid} language={language} slug={slug}/>
        ))}
        {version === 'published' && popup?.map((blok) => (
          <StoryblokComponent blok={blok} key={blok._uid} language={language} slug={slug}/>
        ))}
      </div>
    </>
  );
}

export default Global;

const query = (language) => gql`
  {
    KnowledgeBytes: ArticleItems(
    ${language !== 'en' ? `starts_with: "${language}/*",` : ''}
    per_page: 100
    by_slugs: "knowledge-bytes/*"
  sort_by: "sort_by_date:desc"
  ) {
  items {
  content {
  key
  title
  body
  description
  is_question_card
  additional_topics {
  content
  }
  media_type
  }
  uuid
  full_slug
  }
  total
  }
  DatasourceEntries(
  datasource: "lookup-terms"
  dimension: "${language}"
  per_page: 1000
  ) {
  items {
  name
  dimensionValue
  value
  }
  }
  }
`;

