import { useEffect, useState } from 'react';
import { apiWidgetUrl, etpNewSlug, etpSlug } from '../../constants';
import { format } from 'd3';
import { formatDate } from '../../utils';
import { gql, useQuery } from '@apollo/client';

function ETPLeadingTheWay({ blok }) {
  const { title, text_one_title, text_one_text, text_two, text_three } = blok;
  const [currentAUM, setCurrentAUM] = useState();

  const { data } = useQuery(query);

  useEffect(() => {
    fetch(`${apiWidgetUrl}&names=ETP_AUM`)
      .then(res => res.json())
      .then(res => setCurrentAUM(res[0].sections[0].meta));
  }, []);

  const currentAUMNumber = format('$.2s')(currentAUM?.find(x => x.key === 'MarketCap')?.value)?.replace('G', 'B');
  const peakAUMDate = currentAUM?.find(x => x.key === 'Date')?.value;

  return (
    <div className='widget white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'><h2>{title}</h2></div>
        </div>
        <div className='row'>
          <div className='col-12 etp-leading-the-way-facts'>
            <div className='etp-top-border etp-leading-the-way-fact'>
              <h2>{text_one_title}</h2>
              <p>{text_one_text}</p>
            </div>
            <div className='etp-top-border etp-leading-the-way-fact'>
              <h2>{data?.ExchangetradedproductItems?.total}</h2>
              <p>{text_two}</p>
            </div>
            <div className='etp-top-border etp-leading-the-way-fact'>
              <h2>{currentAUMNumber}</h2>
              <p>{text_three} - {formatDate(peakAUMDate)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ETPLeadingTheWay;

const query = gql`
  {
    ExchangetradedproductItems(starts_with: "${etpNewSlug}/") {
      total
    }
  }
`;