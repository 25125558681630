import { StoryblokComponent } from "@storyblok/react";

function ETPOffer({ blok }) {
  const { logo, bullet_text, button } = blok;

  return (
    <div className={'etp-offer'}>
      <img src={logo.filename} alt={logo.alt} height={"25px"}/>
      <ul className={'etp-offer-list'}>{bullet_text.split('\n').map(t => <li>{t}</li>)}</ul>
      <div className='etp-offer-link'>
        {button.map(blok => <StoryblokComponent blok={blok} id={button._uid}/>)}
      </div>
    </div>
  );
}

export default ETPOffer;