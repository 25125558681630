import React, { useMemo } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { useQuery, gql } from '@apollo/client'
import FeatureStatement from './FeatureStatement'

export const FeatureStatements = ({ blok, language }) => {
	const query = gql`
	{
		FeaturestatementItems${language !== 'en' ? `(starts_with: "${language}/*")` : ''} {
			items {
                id
                uuid
				content {
					component
					description
					title
				}
				full_slug
			}
		}
	}
`
	const { anchor_key, title, feature_statements } = blok

	const { /*loading, error,*/ data } = useQuery(query)

	const { FeaturestatementItems } = data || {}
	const featureStatements = useMemo(() => {
		if (FeaturestatementItems) {
			return FeaturestatementItems.items.flatMap((item) => {
				return { id: item.id, uuid: item.uuid, full_slug: item.full_slug, ...item.content }
			})
		}

		return []
	}, [FeaturestatementItems])

	return (
		<div className='white' id={anchor_key}>
			<div id='feature-statements' {...storyblokEditable(blok)} className='widget'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<h2>{title}</h2>
						</div>
					</div>
					<div className='row feature-statements'>
						{featureStatements.length > 0 &&
							feature_statements.map((feature_statement, index) => {
								const { title, description, uuid } = featureStatements.find((fs) => fs.uuid === feature_statement)
								return (
									<div className='col-12 col-sm-6 col-lg-4' key={uuid}>
										<FeatureStatement feature={{ index, title, description }} />
									</div>
								)
							})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FeatureStatements
