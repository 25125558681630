import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const TwitterShare = (props) => {
	const text = `&text=${props.text || document.title}`
	const url = `&url=${props.url || ''}`
	const shareUrl = encodeURI(`https://twitter.com/intent/tweet/?${url}${text}`)

	return (
		<a
			href={shareUrl}
			target='popup'
			style={props.style}
			className={props.className}
            title={props.title}
			onClick={() => {
				window.open(shareUrl, 'popup', 'width=480,height=240')
				return false
			}}
		>
			<FontAwesomeIcon icon={'fab fa-twitter'} />
		</a>
	)
}

export default TwitterShare
